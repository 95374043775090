import axios from 'axios';
import { message } from 'antd';
import Swal from 'sweetalert2';
import { customSwal } from '../theme';

import * as types from '../constants/actionTypes';
import { returnErrors } from './errorAction';
import { checkAuth } from './authAction';

export const fetchUserResults = (
  id,
  filters,
  course,
  history
) => async dispatch => {
  try {
    const res = await axios.post(`/api/users/${id}/results`, {
      id,
      filters,
      course
    });

    const { newSurveys = [], registrationDate = '', sessions = [] } = res.data;

    return dispatch({
      type: types.FETCH_USER_RESULTS_SUCCESS,
      payload: { newSurveys, registrationDate, sessions }
    });
  } catch (err) {
    if (err.response && err.response.status === 403) {
      history.goBack();

      message.error(
        `Error!, This trainer didn't give an access permission to their data`
      );
    }
    if (err.response && err.response.status === 404) {
      history.goBack();
      return message.error(`Error!, trainer data not found`);
    }
    return history.push('/500err');
  }
};

export const fetchLocalLeads = () => async dispatch => {
  try {
    const res = await axios.get('/api/local-leads');
    const { data } = res;

    dispatch({
      type: types.FETCH_LOCAL_LEADS,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchLocalLeadTrainersGroup = id => async dispatch => {
  try {
    const { data } = await axios.get(`/api/local-lead/${id}/group`);
    dispatch({
      type: types.FETCH_LOCAL_LEAD_TRAINERS_GROUP,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchStatsData = userType => async dispatch => {
  try {
    const res = await axios.post('/api/all/dashboard', { userType });
    const { data } = res;

    dispatch({
      type: types.FETCH_STATS,
      payload: data.stats
    });
  } catch (error) {
    console.log(error);
  }
};

export const addTrainerToGroup = ({
  email,
  name,
  history
}) => async dispatch => {
  try {
    dispatch({
      type: types.LOADING_TRUE,
      payload: 'addTrainerLoading'
    });

    const res = await axios.post('/api/users/local-leads/group', {
      email,
      name
    });

    dispatch({
      type: types.ADD_TRAINER_TO_GROUP_SUCCESS,
      payload: res.data
    });

    dispatch({
      type: types.LOADING_FALSE,
      payload: 'addTrainerLoading'
    });

    Swal.fire({
      title: 'Done!',
      text: res.data.alreadyInGroup
        ? `The trainer is already part of your group.`
        : `Trainer added to the your group. We've emailed the trainer to inform him/ her about this update.`,
      type: 'info',
      confirmButtonText: 'Ok',
      ...customSwal
    }).then(() => {
      history.push('/trainers');
    });
  } catch (error) {
    dispatch({
      type: types.ADD_TRAINER_TO_GROUP_FAIL,
      payload: error.response.data
    });

    dispatch({
      type: types.LOADING_FALSE,
      payload: 'addTrainerLoading'
    });
    Swal.fire({
      title: 'Error!',
      text: error?.response?.data?.error,
      type: 'error',
      confirmButtonText: 'Ok',
      ...customSwal
    }).then(() => {
      history.push('/trainers');
    });
  }
};

export const checkUserByEmail = (email, history) => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'forgetPasswordLoading'
  });

  axios
    .get(`/api/users/forget-password/?email=${email}`)
    .then(res => {
      dispatch({
        type: types.CHECK_USER_BY_EMAIL_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: types.LOADING_FALSE,
        payload: 'forgetPasswordLoading'
      });
    })
    .then(() => {
      Swal.fire({
        title: 'Password reset email sent!',
        text:
          'We just sent a message to the email you provided with a link to reset your password. Please check your inbox and follow the instructions in the email.',
        type: 'success',
        confirmButtonText: 'Ok',
        ...customSwal
      }).then(() => {
        history.push('/login');
      });
    })
    .catch(() => {
      history.push('/404err');

      dispatch({
        type: types.LOADING_FALSE,
        payload: 'forgetPasswordLoading'
      });
    });
};

export const resetPassword = (resetPasswordData, history) => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'resetPasswordLoading'
  });

  axios
    .post('/api/users/reset-password', resetPasswordData)
    .then(res => {
      dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: types.LOADING_FALSE,
        payload: 'resetPasswordLoading'
      });
    })
    .then(() => {
      message.success(`Done! Password reset successfully`);
      history.push('/login');
    })
    .catch(err => {
      dispatch(
        returnErrors(
          err?.response?.data,
          err?.response?.status,
          'RESET_PASSWORD_FAIL'
        )
      );
      dispatch({
        type: types.LOADING_FALSE,
        payload: 'resetPasswordLoading'
      });

      dispatch({
        type: types.RESET_PASSWORD_FAIL
      });
    });
};

export const updateUserInfo = (data, updateState) => async dispatch => {
  dispatch({
    type: types.LOADING_TRUE,
    payload: 'updateUserLoading'
  });

  axios
    .patch('/api/users', data)
    .then(() => {
      updateState({ visible: false });

      dispatch({
        type: types.LOADING_FALSE,
        payload: 'updateUserLoading'
      });
      dispatch(checkAuth());
    })
    .then(() => message.success('Updated successfully'))
    .catch(err => {
      const error =
        err.response && err.response.data && err.response.data.error;
      message.error(error || 'Something went wrong');

      dispatch({
        type: types.LOADING_FALSE,
        payload: 'updateUserLoading'
      });
    });
};

export const fetchManagersNames = history => async dispatch => {
  axios
    .get('/api/users/managers')
    .then(res => {
      dispatch({
        type: types.FETCH_ALL_MANAGERS,
        payload: res.data
      });
    })
    .catch(() => {
      history.push('/404err');
    });
};

export const removeTrainerFromGroupAction = (
  managerId,
  history
) => async dispatch => {
  axios
    .delete(`/api/users/remove-trainer/${managerId}`)
    .then(() => {
      dispatch({
        type: types.LOADING_FALSE,
        payload: 'updateUserLoading'
      });
      dispatch(fetchManagersNames(history));
    })
    .catch(() => {
      history.push('/404err');
    });
};
