import React from 'react';
import { Progress } from 'antd';
import { Container, Content } from './style';
import theme from '../../../theme';
import { H1, P } from '../Typography';

const CircleDiagram = ({ totalScore, currentScore, progressScore }) => {
  const _progressScore = Math.round(progressScore);
  const _currentScore = Math.round(currentScore);
  return (
    <Container progressScore={_progressScore}>
      <Progress
        success={{
          percent: ((_currentScore - _progressScore) / totalScore) * 100,
          strokeColor: 'red'
        }}
        type="circle"
        strokeColor={
          _progressScore ? theme.colors.secondary : theme.colors.lightSecondary
        }
        percent={(_currentScore / totalScore) * 100}
        strokeWidth={10}
        showInfo={false}
        strokeLinecap={'round'}
        width={130}
        trailColor={theme.colors.lightSecondary}
      />
      <Content>
        <H1 mb="5px" color="secondary">
          {_currentScore}
        </H1>
        <P small color="darkGray">
          / {totalScore} spaces
        </P>
        <P small color="darkGray">
          left
        </P>
      </Content>
    </Container>
  );
};

export default CircleDiagram;
