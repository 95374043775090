import { useHistory } from 'react-router-dom';
import axios from 'axios';
import download from 'downloadjs';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import Layout from '../../Layouts';
import { DASHBOARD_URL } from '../../../constants/navigationRoutes';
import { selectRole } from '../../../actions/authAction';
import { userRoles } from '../../../constants';

const TrainerThankYou = ({ userRolesProp, selectRole }) => {
  const history = useHistory();
  const downloadCertificate = () => {
    const fileName = `module-ttt-${Date.now()}.pdf`;

    axios
      .post(
        `/api/certificate/ttt`,
        {},
        {
          responseType: 'blob'
        }
      )
      .then(res => {
        const content = res.headers['content-type'];
        download(res.data, fileName, content);
      });
  };

  const onGoToDashboard = async () => {
    await selectRole(userRolesProp.find(e => e !== userRoles.participant));
    history.push(DASHBOARD_URL);
  };

  return (
    <Layout>
      <Row>
        <Col w={[4, 12, 8]} mb="7">
          <T.H1>Thank you!</T.H1>
        </Col>
        <Col w={[4, 12, 8]} mb="7">
          <T.P>
            Thank you for your hard work! Please find below a link to download
            your official trainer certificate for this course.
          </T.P>
        </Col>
        <Col w={[4, 12, 8]} mb="7">
          <T.Link
            color="secondary"
            underline={false}
            onClick={downloadCertificate}
          >
            Download Certificate
          </T.Link>
        </Col>
        <Col w={[4, 12, 8]} mb="7">
          <T.P>
            You are now able to set up training sessions for this course. If you
            have any questions on how to use this tool make sure to check out
            the Trainer Advise page.
          </T.P>
        </Col>
        <Row>
          <Col w={[4, 12, 4]}>
            <Button
              type="primary"
              label="Go to my dashboard"
              mb={3}
              height="53px"
              onClick={onGoToDashboard}
            />
          </Col>
        </Row>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  userRolesProp: state.auth.userRoles
});

export default connect(mapStateToProps, {
  selectRole
})(TrainerThankYou);
