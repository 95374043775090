import _userRoles from './userTypes';
import _organisations from './organisations';
import _errMsgs from './errors';
export * from './sessionData';

export const errMsgs = _errMsgs;
export const userRoles = _userRoles;
export const organisations = _organisations;
export const readableSurveysNamePairs = {
  'pre-course': 'Pre-course',
  post: 'Post',
  '3-months-follow-up': '3 months follow up',
  '6-months-follow-up': '6 months follow up',
  'post-train-the-trainer': 'Post Train the Trainer',
  'post-trainer-upskill': 'Post Train Upskill'
};

export const surveysHaveBehavQuestions = [
  'pre-course',
  '3-months-follow-up',
  '6-months-follow-up'
];

/**
 * an object with { "session-type" : "Session Name"}
 */
export const readableSessionNamePairs = {
  '1': 'Session 1',
  '2': 'Session 2',
  '3': 'Session 3',
  'special-2-days': '2-day Intensive',
  'train-trainers-s1': 'Train Trainers Session 1',
  'train-trainers-s2': 'Train Trainers Session 2',
  'train-trainers-event': 'Train Trainers Day Event'
};

export const localAuthoritiesBasic = [
  'Blaenau Gwent',
  'Caerphilly',
  'Monmouthshire',
  'Newport',
  'Torfaen'
];

export const localAuthoritiesWithOtherOption = [
  'Blaenau Gwent',
  'Caerphilly',
  'Monmouthshire',
  'Newport',
  'Torfaen',
  'Other, please specify'
];

export const modules = {
  MODULE_1: '1',
  MODULE_2: '2',
  MODULE_3: '3',
  TRAIN_THE_TRAINER: 'Train the Trainer',
  TRAINER_UPSKILL: 'Trainer Upskill'
};

export const moduleOptions = Object.values(modules).map(module => ({
  value: module,
  label: module
}));

export const courses = {
  C5: 'C5',
  YOUNG_PEOPLE: 'YOUNG_PEOPLE'
};

export const coursesOptions = [
  {
    value: courses.C5,
    label: 'Connect 5'
  },
  {
    value: courses.YOUNG_PEOPLE,
    label: 'Connect 5 Young People'
  }
];

export const surveyTypesNew = {
  PRE_COURSE: 'pre-course',
  POST: 'post',
  POST_TRAIN_THE_TRAINER: 'post-train-the-trainer',
  POST_TRAINER_UPSKILL: 'post-trainer-upskill',
  FOLLOW_UP_3: '3-months-follow-up',
  FOLLOW_UP_6: '6-months-follow-up'
};

export const externalLinks = {
  NHS_FUTURE_LEARNS:
    'https://future.nhs.uk/system/login?nextURL=%2Fconnect%2Eti%2FNEConnect5MentalWellbeing%2Fgrouphome',
  EXPLAIN_TOOL_VIDEO:
    'https://yallacooperative.notion.site/Gwent-Connect-5-Tutorials-da79805ab46a456680c89aefcb3494ec',
  MECC_CONTACT_EMAIL: 'help@ics.org',
  WALES_NHS_EMAIL: 'sarah.hughes24@wales.nhs.uk',
  PRIVACY_POLICY: 'TBC',
  TERMS_OF_USE: 'TBC',
  HELP_EMAIL: 'help@ics.org',
  FEEDBACK_FORM: 'https://notionforms.io/forms/connect-5-live-app-feedback'
};

export const upgradeTttSteps = {
  signupAsTtt: 'signupAsTtt',
  watchTttVideo: 'watchTttVideo'
};

export const extUrls = {
  meloC5: 'https://www.melo.cymru/gwent-connect-5-workforce-training-programme/'
};
