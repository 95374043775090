import { fields, createSchema, validate as _validate } from '..';

const {
  email,
  arrayOfString,
  requiredPositiveNumber,
  sessionType,
  partnerTrainer,
  address,
  onlineDetails,
  optionalText,
  coursesTypes,
  dates
} = fields;

const createSessionSchema = createSchema({
  dates: dates,
  sessionType,
  course: coursesTypes,
  modules: arrayOfString,
  capacity: requiredPositiveNumber,
  partnerTrainer,
  address,
  online: onlineDetails,
  extraInfo: optionalText,
  contactEmail: email
});

const validate = data =>
  _validate(createSessionSchema, {
    ...data,
    dates: data?.dates?.map(date => ({
      ...date,
      clonedStartTime: date.startTime
    }))
  });

export default validate;
