import React, { useState } from 'react';
import { Modal } from 'antd';

import { SubDetailsContent, InfoHeader } from './SessionDetails.Style';

import * as T from '../../common/Typography';

export default ({ type }) => {
  const [modalVisible, setModalVisible] = useState(false);
  // toggle modal visibility
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleCancel = e => {
    setModalVisible(false);
  };

  return (
    <div>
      <T.P mb="4" style={{ maxWidth: '800px' }}>
        {
          (type = 'registrationList'
            ? `Here you can view who has registered for your session. You can also manually add and remove attendees by editing the list below. Please make sure you do a roll call at the start of your session and update this list. Please note that you will not be able to remove email addresses of participants who have already completed their post-session evaluation for this session.`
            : `Here you can manage your list of course participants to share relevant
        session infos with them.`)
        }
      </T.P>
      {/* Removing this until we have a new manual */}
      {/* <InfoHeader to="#" onClick={toggleModal}>
        Click here for more instructions
      </InfoHeader> */}
      <Modal
        title="Managing Email Lists"
        visible={modalVisible}
        onOk={toggleModal}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={800}
      >
        <ul style={{ marginLeft: '2rem' }}>
          <li>
            <span
              style={{
                fontWeight: '700',
                fontSize: '16px',
                textDecoration: 'underline',
                marginBottom: '0.75rem'
              }}
            >
              Adding emails:
            </span>
            <SubDetailsContent
              style={{
                fontSize: '16px',
                marginBottom: '0.75rem',
                marginTop: '0.75rem'
              }}
            >
              You can either copy an existing list of emails or input each
              address manually.{' '}
              <strong>
                To copy email lists from other sources you will need to use the
                latest versions of Google Chrome/Mozilla Firefox!
              </strong>
              <br></br>
              When copying a list of emails externally, they would need to be
              separated by commas/semicolons. If you wanted to copy a list of
              emails from another session you can click the copy icon above the
              input field. When choosing to input emails manually - clicking
              your mouse or pressing enter will store each email address.
            </SubDetailsContent>
            <SubDetailsContent
              style={{
                fontSize: '16px',
                marginBottom: '0.75rem'
              }}
            >
              Clicking the update button will store the updated list of emails.
            </SubDetailsContent>
          </li>
          <li>
            <span
              style={{
                fontWeight: '700',
                fontSize: '16px',
                textDecoration: 'underline',
                marginBottom: '0.75rem'
              }}
            >
              Removing emails:
            </span>
            <SubDetailsContent
              style={{
                fontSize: '16px',
                marginBottom: '0.75rem',
                marginTop: '0.75rem'
              }}
            >
              You can click the x icon next to each email address. This will
              remove participants from the list. Then click the update button to
              store the updated list.
            </SubDetailsContent>
          </li>
          <li>
            <span
              style={{
                fontWeight: '700',
                fontSize: '16px',
                textDecoration: 'underline',
                marginBottom: '0.75rem'
              }}
            >
              Deleting all emails:
            </span>
            <SubDetailsContent
              style={{
                fontSize: '16px',
                marginBottom: '0.75rem',
                marginTop: '0.75rem'
              }}
            >
              You can click the trash icon to delete a whole list of emails.
            </SubDetailsContent>
            <SubDetailsContent
              style={{
                fontSize: '16px',
                marginBottom: '0.75rem'
              }}
            >
              Then click the update button to store the updated list.
            </SubDetailsContent>
          </li>
        </ul>
      </Modal>
    </div>
  );
};
