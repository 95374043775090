import { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import SessionList from '../../common/List/SessionList';
import CompletedSessionList from '../../common/List/CompletedSessionList';

import Layout from '../../Layouts';

const ParticipantDashboard = ({ sessions, fetchSessions }) => {
  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 12, 10]}>
          <T.H1>My course</T.H1>
        </Col>
      </Row>
      <Row mb={7}>
        <Col w={[4, 12, 10]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            Upcoming sessions
          </T.P>
          <SessionList
            dataList={sessions?.filter(ses =>
              moment(
                ses.dates?.sort(
                  (a, b) => new Date(b.sessionDate) - new Date(a.sessionDate)
                )[0]?.sessionDate
              ).isSameOrAfter()
            )}
            isParticipant
          />
        </Col>
      </Row>
      <Row mb={7}>
        <Col w={[4, 12, 10]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            Completed modules
          </T.P>
          <CompletedSessionList
            dataList={sessions?.filter(ses =>
              moment(
                ses.dates?.sort(
                  (a, b) => new Date(b.sessionDate) - new Date(a.sessionDate)
                )[0]?.sessionDate
              ).isBefore()
            )}
            isParticipant
            linkText="View"
          />
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantDashboard);
