/* eslint-disable no-case-declarations */
import {
  FETCH_SURVEY_DATA,
  FETCH_SURVEY_DATA_FAIL,
  SURVEY_SUBMISSION_SUCCESS,
  SURVEY_SUBMISSION_FAIL,
  GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS,
  GET_PARTICIPANT_SURVEY_RESPONSES_FAIL,
  RESET_SURVEY
} from '../constants/actionTypes';

const initialState = {
  surveyData: null,
  uniqueGroups: null,
  loaded: false,
  msg: {},
  errors: {},
  answeredSameModule: false,
  redirectTo: false,
  showSurvey: false,
  contentMsg: '',
  httpError: ''
};

const createGroupsArray = obj => [...new Set(obj.map(e => e.group.text))];

const fetchedSurveyData = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SURVEY_DATA:
      const groups = createGroupsArray(payload.questionsForSurvey);
      return {
        ...state,
        surveyData: payload,
        uniqueGroups: groups,
        uniqueGroupsAll: groups,
        loaded: true,
        httpError: null
      };

    case FETCH_SURVEY_DATA_FAIL:
      return {
        ...state,
        surveyData: null,
        uniqueGroups: null,
        uniqueGroupsAll: null,
        loaded: true,
        httpError: payload
      };

    case SURVEY_SUBMISSION_SUCCESS:
      return {
        ...state
      };
    case SURVEY_SUBMISSION_FAIL:
      return {
        ...state,
        errors: payload
      };
    case GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS:
      // payload.participant tells if PIN is included in participant table -> demographic data exists
      // payload.preSurveyResponses{ answeredSameModule, redirectTo, showSurvey}
      //  tells if user filled in the prev surveys

      const {
        answeredSameModule,
        redirectTo,
        showSurvey,
        contentMsg
      } = payload.preSurveyResponses;

      const createdGroups = createGroupsArray(
        state.surveyData.questionsForSurvey
      );

      return {
        ...state,
        answeredSameModule,
        redirectTo,
        showSurvey,
        contentMsg,
        uniqueGroups: createdGroups
      };
    case GET_PARTICIPANT_SURVEY_RESPONSES_FAIL:
      return {
        ...state,
        uniqueGroups: createGroupsArray(state.surveyData.questionsForSurvey)
      };
    case RESET_SURVEY:
      return initialState;

    default:
      return state;
  }
};

export default fetchedSurveyData;
