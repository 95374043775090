import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.lightPrimary};
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const RemoveButton = styled(AntButton)`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`;
