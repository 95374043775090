import { useState } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import { useMediaQuery } from 'react-responsive';

import { colors, shadows, breakpoints } from '../../../theme';

import { checkBrowserWidth } from '../../../actions/checkBrowserWidth';
import Menu from '../Menu';
import Icon from '../Icon';

const sharedStyles = css`
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: ${shadows.primary};
  color: ${colors.white};
  z-index: 1000;
`;

const StyledHeader = styled.div`
  ${sharedStyles};
  top: 0;
  background-color: ${colors.primary};
  color: ${colors.offWhite};
  height: ${({ theme }) => theme.layoutConstants.headerHeightM};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

// TODO: replace with common Icon component when its created
export const OpenIconDiv = styled.div`
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  padding-right: 20px;
`;
// TODO: replace with common Icon component when its created
export const MenuIcon = styled(Icon)`
  color: '#F7F7F9';
  padding: 10px;
  cursor: pointer;
  :hover {
    color: ${colors.lightPrimary};
    outline: none;
  }
`;

const Header = ({
  label,
  type = 'home',
  userRole,
  isMobile,
  login,
  ...props
}) => {
  const [toggleShow, setToggleShow] = useState(false);

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  const onClick = () => {
    setToggleShow(toggleShow => !toggleShow);
  };

  if (isDesktop || toggleShow) {
    return (
      <Menu toggleShow setToggleShow={setToggleShow} isDesktop={isDesktop} />
    );
  }

  return (
    <>
      <StyledHeader type={type} {...props}>
        <OpenIconDiv onClick={onClick}>
          <Icon icon="menu" width="24px" height="24px" />
        </OpenIconDiv>
      </StyledHeader>

      <Menu
        toggleShow={toggleShow}
        setToggleShow={setToggleShow}
        isDesktop={isDesktop}
        login={login}
      />
    </>
  );
};

const mapStateToProps = state => ({
  width: state.checkBrowserWidth.width,
  isMobile: state.checkBrowserWidth.isMobile,
  isDeskTop: state.checkBrowserWidth.isDeskTop
});

export default connect(mapStateToProps, { checkBrowserWidth })(Header);
