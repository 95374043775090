import { TimePicker as AntTimePicker } from 'antd';
import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import InfoPopUp from '../../InfoPopup';
import moment from 'moment';

const TimePicker = ({
  onTimeChange,
  name,
  label,
  color,
  required,
  error,
  popOver,
  value,
  ...props
}) => {
  return (
    <S.Field error={error} {...props}>
      {label && (
        <CS.Label htmlFor={label}>
          <T.P color={color} m="0" mb="2" mr="2" weight="700">
            {required && '*'} {label}
          </T.P>
          {popOver && (
            <InfoPopUp
              text={popOver?.text}
              title={popOver?.title}
              h="16px"
              w="16px"
              mb="2"
            />
          )}
        </CS.Label>
      )}
      <AntTimePicker
        format="HH:mm"
        id="TimePicker"
        onChange={(val, timeString) => onTimeChange(timeString, name)}
        name={name}
        value={value ? moment(value, 'HH:mm') : undefined}
      />
      {error && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </S.Field>
  );
};

export default TimePicker;
