import styled from 'styled-components';
import Header from '../../common/Header';

import IEMessage from '../../common/IEMessage';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  max-width: 1440px;
`;

const Content = styled.div`
  margin-top: ${({ theme }) => theme.layoutConstants.headerHeightM};
  margin-bottom: ${({ theme }) => theme.layoutConstants.headerHeightM};

  padding: 50px 6.6% 120px 6.6%;
  padding: ${({ theme: { layoutConstants } }) =>
    `${layoutConstants.topMarginM} ${layoutConstants.rightMarginM} ${layoutConstants.bottomMarginM} ${layoutConstants.leftMarginM}`};

  @media ${({ theme }) => theme.breakpoints.laptop} {
    margin-top: ${({ theme }) => theme.layoutConstants.headerHeight};

    padding: ${({ theme: { layoutConstants } }) =>
      `${layoutConstants.topMargin} ${layoutConstants.rightMargin} ${layoutConstants.bottomMargin} ${layoutConstants.leftMargin}`};
  }

  width: 100%;
`;

const General = ({ children, login }) => {
  return (
    <Wrapper>
      <Header login={login} />
      <Content>
        <IEMessage />
        {children}
      </Content>
    </Wrapper>
  );
};

export default General;
