import { Progress as AntProgress } from 'antd';

import * as S from './style';
import * as T from '../Typography';
import { colors } from '../../../theme';

const Progress = ({
  readyForSubmission,
  completionRate,
  section,
  uniqueGroups
}) => {
  const steps = [...uniqueGroups];
  if (section === 'enterPIN') {
    steps.unshift('enterPIN');
  }

  return (
    <S.FooterDiv colorChange={readyForSubmission}>
      <S.ProgressWrapper>
        <T.P weight={700} color="white">
          Your progress
        </T.P>
        <AntProgress
          percent={completionRate}
          strokeColor={`${colors.white}`}
          trailColor="red"
        />
      </S.ProgressWrapper>
      <S.StepProgress>
        <T.P weight={700} color="white">
          Step {steps.indexOf(section) + (steps.includes('enterPIN') ? 1 : 2)}/
          {uniqueGroups.length + (steps.includes('enterPIN') ? 0 : 1)}
        </T.P>
      </S.StepProgress>
    </S.FooterDiv>
  );
};

export default Progress;
