import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Wrapper,
  LogoContainer,
  Logo,
  DescriptionContainer,
  Headline,
  Paragraph,
  ButtonContainer,
  ButtonLink,
  ButtonDiv
} from './LandingPage.style';

// ROUTES
import {
  LOGIN_URL,
  PARTICIPANT_LOGIN
} from '../../../constants/navigationRoutes';

import Connect5Logo from '../../../assets/connect-5-white.svg';

import Button from '../../common/Button';
import { extUrls } from '../../../constants';

class LandingPage extends Component {
  render() {
    const { isAuthenticated, role } = this.props;

    if (isAuthenticated) {
      switch (role) {
        case 'admin':
        case 'localLead':
        case 'trainer':
          return <Redirect to="/dashboard" />;
        case 'participant':
          return <Redirect to="/participant-dashboard" />;
        default:
          break;
      }
    }

    return (
      <Wrapper>
        <LogoContainer>
          <Logo src={Connect5Logo} alt="logo" />
        </LogoContainer>
        <DescriptionContainer>
          <Headline>Welcome to the Gwent Connect 5</Headline>
          <Paragraph style={{ textAlign: 'center' }}>
            Welcome to the App of the mental wellbeing training programme Gwent
            Connect 5
          </Paragraph>
        </DescriptionContainer>
        <ButtonContainer>
          <ButtonLink to={`${LOGIN_URL}`}>
            <ButtonDiv>
              <Button
                label="I'm a Trainer"
                width="100%"
                height="100%"
                type="primary"
              />
            </ButtonDiv>
          </ButtonLink>
          <ButtonLink to={`${PARTICIPANT_LOGIN}`}>
            <ButtonDiv>
              <Button
                label="I'm a Course Participant"
                width="100%"
                height="100%"
                type="primary"
              />
            </ButtonDiv>
          </ButtonLink>
          <ButtonDiv>
            <a target="_blank" rel="noopener noreferrer" href={extUrls.meloC5}>
              <Button
                label="I want to find out more"
                width="100%"
                height="100%"
                type="primary"
              />
            </a>
          </ButtonDiv>
        </ButtonContainer>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.role
  };
};

export default connect(mapStateToProps)(LandingPage);
