import moment from 'moment';

export const filterCountableAnswers = ({
  answer,
  otherAnswer,
  question,
  questionsForSurvey
}) => {
  if (!answer) {
    return false;
  }

  const _question = questionsForSurvey?.find(q => q._id === question);

  if (_question.isRequired === false) return false;

  if (
    answer &&
    !Array.isArray(answer) &&
    typeof answer === 'object' &&
    !moment.isMoment(answer)
  ) {
    return _question?.questionsList?.length === Object.values(answer).length;
  }

  if (typeof answer === 'string') {
    const isFollowupQuestionRequired =
      _question?.followUpQuestion?.ifAnswerIsOneOf.includes(answer) &&
      _question?.followUpQuestion?.isRequired;

    if (
      answer?.toLowerCase()?.includes('please specify') ||
      isFollowupQuestionRequired
    ) {
      return otherAnswer;
    }
  }

  return !!answer;
};
