import React from 'react';
import { colors } from '../../../theme';
import * as S from './style';
import * as T from '../Typography';
import Button from '../Button';
import { Row, Col } from '../Grid';

const maskStyle = { backgroundColor: colors.secondary, opacity: '0.9' };

const Modal = ({
  visible,
  closeOnOK = true,
  parentFunc,
  children,
  setModalOpen,
  type,
  title,
  text,
  loading,
  withoutCancel,
  confirmText
}) => {
  const handleOk = action => {
    parentFunc && parentFunc && parentFunc(action);
    closeOnOK && setModalOpen && setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen && setModalOpen(false);
  };

  if (type === 'confirm') {
    return (
      <S.Modal
        visible={visible}
        onOk={handleOk}
        onCancel={withoutCancel ? undefined : handleCancel}
        footer={[]}
        maskStyle={maskStyle}
      >
        <Row mb={4}>
          <Col w={[4, 12, 12]}>
            <T.P weight={700} mb="2">
              {title || 'Are you sure?'}
            </T.P>
            <T.P>{text}</T.P>
          </Col>
        </Row>

        <Row>
          {!withoutCancel && (
            <Col w={[4, 12, 6]}>
              <Button type="primary" onClick={handleCancel} label="Back" />
            </Col>
          )}
          <Col w={[4, 12, 6]}>
            <Button
              type="secondary"
              onClick={() => handleOk('confirm')}
              label={confirmText || 'Confirm'}
              loading={loading}
            />
          </Col>
        </Row>
      </S.Modal>
    );
  }

  return (
    <S.Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
      maskStyle={maskStyle}
    >
      {loading ? <p>Loading</p> : children}
    </S.Modal>
  );
};

export default Modal;
