import { connect } from 'react-redux';

import FilterResults from '../FilterResults';

const TrainerFeedbackOverall = ({ role, hiddenFields, defaultFilters }) => {
  return (
    <FilterResults
      role={role}
      defaultFilters={defaultFilters}
      hiddenFields={hiddenFields}
      exportText={{
        active: 'Export Filtered Trainer Feedback',
        inactive: 'Export Trainer Feedback'
      }}
      hideApplyFiltersButton
      target={'trainerFeedback'}
      isOpenedByDefault
      isOpened={false}
    />
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(TrainerFeedbackOverall);
