import styled from 'styled-components';
import * as CS from '../style';
import { Input as AntdInput } from 'antd';

const InputCommonStyle = ({ theme, error, disabled }) => `
border: ${theme.borders.inputs};
border-color: ${error ? theme.colors.error : theme.colors.midPrimary};
border-radius: ${theme.borders.radius};

width: 100%;
line-height: 24px;

color: ${theme.colors.gray};
font-size: 16px;

cursor: ${disabled && 'not-allowed'};
background: ${theme.colors.offWhite};

:focus {
  outline: none;
  border-color: ${theme.colors.primary} !important;
}

`;

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacings[3]};
  ${InputCommonStyle};
  ${CS.placeholderStyle};
`;

export const InfoWrapper = styled.button`
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  margin-left: ${({ theme }) => theme.spacings[2]};
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
`;

const decideEyePosition = (hasLabel, error) => {
  if (hasLabel) return '58%';
  return '33%';
};

export const ShowPassword = styled.button`
  position: absolute;
  right: 20px;
  top: ${({ hasLabel, error }) => decideEyePosition(hasLabel, error)};
  background: none;
  border: none;
  cursor: pointer;
`;
