import axios from 'axios';

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CHECK_UNIQUE_EMAIL_UNIQUE,
  CHECK_UNIQUE_EMAIL_UNUNIQUE,
  USER_AUTHENTICATED,
  USER_UNAUTHENTICATED,
  ADD_TRAINER_TO_GROUP_SUCCESS,
  CHECK_UNIQUE_EMAIL_ERROR,
  LOGOUT,
  LOADING_TRUE,
  LOADING_FALSE,
  SIGNUP_FAIL,
  PARTICIPANT_SIGNUP_FAIL,
  PARTICIPANT_SIGNUP_SUCCESS,
  UPDATE_MY_ACCOUNT_SUCCESS,
  UPDATE_MY_ACCOUNT_FAIL,
  SELECT_ROLE_SUCCESS,
  SELECT_ROLE_FAIL,
  SIGNUP_TTT_SUCCESS,
  SIGNUP_TTT_FAIL,
  RESET_ROLE_SUCCESS
} from '../constants/actionTypes';
import { returnErrors } from './errorAction';

import { upgradeTttSteps } from './../constants';
import * as R from './../constants/navigationRoutes';

// Login User
export const loginUser = ({
  email,
  password,
  history,
  loginRole
}) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'loginLoading'
    });

    const { data } = await axios.post('/api/login', {
      email,
      password,
      loginRole
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data
    });

    dispatch({
      type: LOADING_FALSE,
      payload: 'loginLoading'
    });
    return data;
  } catch (err) {
    dispatch(
      returnErrors(
        err?.response?.data?.error || err.message,
        err?.response?.status,
        'LOGIN_FAIL'
      )
    );
    dispatch({
      type: LOADING_FALSE,
      payload: 'loginLoading'
    });
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// send a request to register new trainer
export const signUpTrainer = trainerData => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'signupLoading'
    });
    const res = await axios.post('/api/trainers', trainerData);
    const { data } = res;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    dispatch({
      type: SIGNUP_FAIL,
      payload: error
    });
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'signupLoading'
    });
  }
};

export const signUpParticipant = participantData => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'signupLoading'
    });
    const res = await axios.post('/api/participant', participantData);
    const { data } = res;
    dispatch({
      type: PARTICIPANT_SIGNUP_SUCCESS,
      payload: data
    });
    return { data };
  } catch (error) {
    dispatch({
      type: PARTICIPANT_SIGNUP_FAIL,
      payload: error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'signupLoading'
    });
  }
};

// check if the email is unique or not
export const checkUniqeEmail = email => async dispatch => {
  try {
    const res = await axios.get(`/api/users/?email=${email}`);
    const { data } = res;
    if (data.isUnique) {
      dispatch({
        type: CHECK_UNIQUE_EMAIL_UNIQUE,
        payload: data
      });
    } else {
      dispatch({
        type: CHECK_UNIQUE_EMAIL_UNUNIQUE,
        payload: data
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_TRAINER_TO_GROUP_SUCCESS,
      payload: error.response.data.error
    });
    dispatch({
      type: CHECK_UNIQUE_EMAIL_ERROR,
      payload: error.response.data.error
    });
  }
};

// check if user is logged in or not and get the user info
export const checkAuth = () => async dispatch => {
  try {
    const res = await axios.get(`/api/users/auth`);
    const { data } = res;

    dispatch({
      type: USER_AUTHENTICATED,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: USER_UNAUTHENTICATED
    });
  }
};

export const logout = history => async dispatch => {
  try {
    await axios.post('/api/logout');
    dispatch({
      type: LOGOUT
    });
    history.push(R.LOGIN_URL);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('err', error);
  }
};
export const updateMyAccount = data => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'updateMyAccount'
    });
    await axios.patch('/api/my-account', data);

    dispatch({
      type: UPDATE_MY_ACCOUNT_SUCCESS,
      payload: data
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'updateMyAccount'
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MY_ACCOUNT_FAIL,
      payload: error
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'updateMyAccount'
    });
  }
};

export const selectRole = role => async dispatch => {
  try {
    const { data } = await axios.post('/api/select-role', { role });

    if (role === null) {
      return dispatch({
        type: RESET_ROLE_SUCCESS,
        payload: data
      });
    }
    dispatch({
      type: SELECT_ROLE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: SELECT_ROLE_FAIL,
      payload: error
    });
  }
};

export const signUpTttTrainer = ({
  role,
  localAuthorities,
  history,
  step,
  course
}) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'signUpTttTrainer'
    });

    const { data } = await axios.patch('/api/upgrade-to-ttt', {
      role,
      localAuthorities,
      step
    });

    const pushToLinks = {
      [upgradeTttSteps.signupAsTtt]: R.CERTIFIED_TRAINER,
      [upgradeTttSteps.watchTttVideo]: R.DASHBOARD_URL
    };

    dispatch({
      type: SIGNUP_TTT_SUCCESS,
      payload: data
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'signUpTttTrainer'
    });

    history.push(pushToLinks[step], { course });
  } catch (error) {
    dispatch({
      type: SIGNUP_TTT_FAIL,
      payload: error
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'signUpTttTrainer'
    });
  }
};
