import { useHistory } from 'react-router-dom';
import download from 'downloadjs';
import axios from 'axios';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';

import { HOW_IT_WORKS } from '../../../constants/navigationRoutes';
import { courses } from '../../../constants';

const CertifiedTrainer = () => {
  const history = useHistory();

  const downloadCertificate = () => {
    const fileName = `module-ttt-${Date.now()}.pdf`;

    axios
      .post(
        `/api/certificate/ttt`,
        {},
        {
          responseType: 'blob'
        }
      )
      .then(res => {
        const content = res.headers['content-type'];
        download(res.data, fileName, content);
      });
  };

  const course = history.location.state?.course;
  const courseName =
    course === courses.C5 ? 'Connect 5' : 'Connect 5 Young People';

  return (
    <Layout>
      <Row mb="4">
        <Col w={[4, 8, 8]} display="block">
          <T.H1 mb="6" width="100%">
            You are now a certified trainer!
          </T.H1>
        </Col>
      </Row>
      <Row mb="5">
        <Col w={[4, 8, 8]}>
          <T.P mr="1">
            Thank you for your hard work already to become a {courseName}{' '}
            trainer! Please find below a link to download your official{' '}
            {courseName} trainer certificate.
          </T.P>
        </Col>
      </Row>

      {
        // Fetch certificate link
      }
      <Row mb="8">
        <Col w={[4, 8, 8]} display="block">
          <T.Link
            color="secondary"
            underline={false}
            onClick={downloadCertificate}
          >
            Download certificate
          </T.Link>
        </Col>
      </Row>

      <Row mb="6">
        <Col w={[4, 8, 8]}>
          <T.P mr="1">
            On the next pages we will provide you some tips and useful
            information on being a trainer and how to use this tool.
          </T.P>
        </Col>
      </Row>
      <Row mt={7}>
        <Col w={[4, 4, 4]}>
          <Button
            onClick={() => history.push(HOW_IT_WORKS)}
            type="primary"
            label="Let's get started!"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default CertifiedTrainer;
