import { modules } from './../constants';
import {
  GwentMod1InCourseWorkbook,
  GwentMod1ParticipantGuide,
  GwentMod2InCourseWorkbook,
  GwentMod2ParticipantGuide,
  GwentMod3InCourseWorkbook,
  GwentMod3ParticipantGuide,
  GwentModTtTPreparing,
  GwentModTtTTrainerMicroTeachPeerFeedbackForm,
  GwentModTtTCourseWorkbook,
  GwentYPMod1InCourseWorkbook,
  GwentYPMod1ParticipantGuide,
  GwentYPMod2InCourseWorkbook,
  GwentYPMod2ParticipantGuide,
  GwentYPMod3InCourseWorkbook,
  GwentYPMod3ParticipantGuide,
  YPV222TrainerInCourseWorkbook,
  YPV222TrainerHandbookAddendaCombinedMod123
} from '../assets/materials';

export const MATERIALS = {
  C5: {
    [modules.MODULE_1]: {
      PRE: [
        {
          displayName: 'Module 1 In-course Workbook',
          link: GwentMod1InCourseWorkbook
        }
      ],
      POST: [
        {
          displayName: 'Module 1 Participant Notes Participant Guide',
          link: GwentMod1ParticipantGuide
        }
      ]
    },
    [modules.MODULE_2]: {
      PRE: [
        {
          displayName: 'Module 2 In-course Workbook',
          link: GwentMod2InCourseWorkbook
        }
      ],
      POST: [
        {
          displayName: 'Module 2 Participant Notes Participant Guide',
          link: GwentMod2ParticipantGuide
        }
      ]
    },
    [modules.MODULE_3]: {
      PRE: [
        {
          displayName: 'Module 3 In-course Workbook',
          link: GwentMod3InCourseWorkbook
        }
      ],
      POST: [
        {
          displayName: 'Module 3 Participant Notes Participant Guide',
          link: GwentMod3ParticipantGuide
        }
      ]
    },
    [modules.TRAIN_THE_TRAINER]: {
      PRE: [
        {
          displayName: 'Preparing for the Connect 5 Training 4 Trainers course',
          link: GwentModTtTPreparing
        },
        {
          displayName: 'T4T Connect 5 Trainer Micro Teach Peer Feedback Form',
          link: GwentModTtTTrainerMicroTeachPeerFeedbackForm
        },
        {
          displayName: 'T4T Connect 5 Course Workbook',
          link: GwentModTtTCourseWorkbook
        }
      ]
    }
  },
  YOUNG_PEOPLE: {
    [modules.MODULE_1]: {
      PRE: [
        {
          displayName: 'Young People Module 1 In-course Workbook',
          link: GwentYPMod1InCourseWorkbook
        }
      ],
      POST: [
        {
          displayName:
            'Young People Module 1 Participant Notes Participant Guide',
          link: GwentYPMod1ParticipantGuide
        }
      ]
    },
    [modules.MODULE_2]: {
      PRE: [
        {
          displayName: 'Young People Module 2 In-course Workbook',
          link: GwentYPMod2InCourseWorkbook
        }
      ],
      POST: [
        {
          displayName:
            'Young People Module 2 Participant Notes Participant Guide',
          link: GwentYPMod2ParticipantGuide
        }
      ]
    },
    [modules.MODULE_3]: {
      PRE: [
        {
          displayName: 'Young People Module 3 In-course Workbook',
          link: GwentYPMod3InCourseWorkbook
        }
      ],
      POST: [
        {
          displayName:
            'Young People Module 3 Participant Notes Participant Guide',
          link: GwentYPMod3ParticipantGuide
        }
      ]
    },
    [modules.TRAINER_UPSKILL]: {
      PRE: [
        {
          displayName: 'Young People Trainer In-Course Workbook',
          link: YPV222TrainerInCourseWorkbook
        },
        {
          displayName: 'Young People Trainer Handbook',
          link: YPV222TrainerHandbookAddendaCombinedMod123
        }
      ]
    }
  }
};
