import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// COMMON COMPONENTS
import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';

import { REGISTER_URL } from '../../../../constants/navigationRoutes';

// STYLE
import {
  EmailInfo,
  InfoTitle,
  SessionInfoTitle,
  List
} from './InviteAndPromote.style';

class InviteEmails extends Component {
  render() {
    const { sessionDetails, emailInfo } = this.props;
    const { shortId } = sessionDetails;
    const {
      date: emailDate,

      trainers,
      trainer,
      sessionType,
      address,
      recipients,
      extraInformation
    } = emailInfo;
    let startTimes = sessionDetails?.dates
      ?.map(e => e?.startTime)
      ?.filter(e => e !== null);
    let endTimes = sessionDetails?.dates
      ?.map(e => e?.endTime)
      ?.filter(e => e !== null);
    let fullAddress = '';
    let datesArr = sessionDetails?.dates?.filter(e => e.sessionDate !== null);

    if (address) {
      const { postcode, addressLine1, addressLine2 } = address;
      if (postcode || addressLine1 || addressLine2) {
        fullAddress = [addressLine1, addressLine2, postcode]
          .filter(item => !!item)
          .join(', ');
      }
    }

    return (
      <Row inner ml="2">
        <Col inner w={[4, 12, 8]}>
          <EmailInfo>
            <InfoTitle>
              Date:
              <span style={{ marginLeft: '.5rem', color: '#4f4f4f' }}>
                {moment(emailDate).format('DD/MM/YYYY')}
              </span>
            </InfoTitle>

            <InfoTitle>Message:</InfoTitle>
            <p>Dear course participants,</p>

            <p>
              {trainer} has invited you to register for an upcoming Gwent
              Connect 5 training session.
            </p>
            <List>
              <li>
                {' '}
                <T.P color="gray" inline>
                  <SessionInfoTitle> Session Date(s):</SessionInfoTitle>{' '}
                  {datesArr?.length &&
                    datesArr?.map((date, i) => {
                      if (i === datesArr?.length - 1) {
                        return moment(date.sessionDate).format('DD/MM/YYYY');
                      } else {
                        return (
                          <>
                            {moment(date.sessionDate).format('DD/MM')}
                            ,&nbsp;
                          </>
                        );
                      }
                    })}{' '}
                </T.P>
              </li>
              <li>
                <SessionInfoTitle>Session Type:</SessionInfoTitle> {sessionType}
              </li>
              <li>
                <SessionInfoTitle> Location:</SessionInfoTitle>{' '}
                {fullAddress || 'TBC'}
              </li>
              <li>
                {' '}
                <T.P color="gray">
                  <SessionInfoTitle>Time:</SessionInfoTitle>

                  {!startTimes?.length
                    ? 'N/A'
                    : startTimes.length === 1 ||
                      (startTimes.every(val => val === startTimes[0]) &&
                        endTimes.every(val => val === endTimes[0]))
                    ? `${startTimes[0]} - ${endTimes[0]}`
                    : 'Times vary'}
                </T.P>
              </li>
              <li>
                <SessionInfoTitle>Trainer(s):</SessionInfoTitle> {trainers}
              </li>
            </List>
            <p>To confirm your attendance please click this link: </p>
            <p>
              {window.location.host}
              {REGISTER_URL.replace(':id', shortId)}
            </p>
            {extraInformation && <p>{extraInformation}</p>}

            <br />
            <p>Sincerely,</p>

            <p>your Gwent Connect 5 team.</p>

            <div>
              <InfoTitle>Sent to</InfoTitle>
              <List>
                {recipients &&
                  recipients.map(email => <li key={email}>{email}</li>)}
              </List>
            </div>
          </EmailInfo>
        </Col>
      </Row>
    );
  }
}

export default connect(null, {})(InviteEmails);
