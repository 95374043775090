import { useState } from 'react';

import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import Icon from '../../Icon';
import InfoPopUp from '../../InfoPopup';

const BasicInput = ({
  type = 'text',
  name,
  placeholder = 'Type here...',
  label,
  error,
  value,
  handleChange,
  helper,
  color = 'darkGray',
  w, // width
  disabled,
  autoComplete,
  required,
  popOver,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const decideColor = () => {
    if (error) return 'error';
    return color;
  };
  const onChange = e => {
    handleChange(e.target.value, name, e);
  };

  const Component = type === 'password' ? S.Input : S.Input;
  return (
    <>
      <CS.Field w={w} disabled={disabled} {...props}>
        {label && (
          <CS.Label htmlFor={label}>
            <T.P color={color} m="0" mr="2" mb="2" weight="700">
              {required && '*'} {label}
            </T.P>
            {popOver && (
              <InfoPopUp
                text={popOver?.text}
                title={popOver?.title}
                h="16px"
                w="16px"
                mb="2"
              />
            )}
          </CS.Label>
        )}
        {type === 'password' && (
          <S.ShowPassword
            error={error}
            id="toggle-password"
            type="button"
            hasLabel={label}
            aria-label="Show password as plain text. Warning: this will display your password on the screen."
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon
              icon={showPassword ? 'eye' : 'eyeCross'}
              width="20"
              height="20"
            />
          </S.ShowPassword>
        )}
        <Component
          type={showPassword ? 'text' : type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          color={decideColor()}
          disabled={disabled}
          autoComplete={autoComplete || 'on'}
          error={error}
          {...props}
        />
        {helper && (
          <T.P color={color} mt="2">
            {helper}
          </T.P>
        )}
      </CS.Field>
      {error && (
        <T.P color="error" m="0" mt="1" mb="2">
          {error}
        </T.P>
      )}
    </>
  );
};

export default BasicInput;
