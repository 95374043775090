import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import useSearchParams from './../../../hooks/useSearchParams';

import { BasicInput } from '../../common/Inputs';

import { loginUser } from '../../../actions/authAction';

import { clearErrors } from '../../../actions/errorAction';

import Layout from './../../Layouts';
import * as T from './../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';

import * as S from './style';

// ROUTES
import {
  FORGET_PASSWORD,
  SIGN_UP_URL,
  PARTICIPANT_SIGN_UP_URL,
  REGISTER_URL,
  SURVEY_URL,
  PARTICIPANT_DASHBOARD,
  DASHBOARD_URL
} from '../../../constants/navigationRoutes';
import { extUrls, modules as modulesData, userRoles } from '../../../constants';

import validate from '../../../validation/schemas/login';

const cleanEmail = email => email.toLowerCase().trim();

const Login = ({
  loginUser: loginUserAction,
  clearErrors: clearErrorsAction,
  httpError,
  loginLoading,
  withoutLayout,
  header,
  extraInfo,
  onSignClick,
  modules,
  isPre,
  loginRole: _loginRole
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(loginLoading);
  const [errors, setErrors] = useState({});
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const history = useHistory();

  const searchParams = useSearchParams();

  const loginRole = searchParams.params.loginRole || _loginRole;

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        password
      });
      setErrors({});
      clearErrorsAction();
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrors(error.inner);
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  useEffect(() => {
    setErrors({});
    clearErrorsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    const data = await loginUserAction({ email, password, loginRole });
    setLoading(false);

    if (!data) return;

    if (searchParams.params.source === 'survey') {
      history.push(
        SURVEY_URL.replace(':id', searchParams.params.shortId).replace(
          ':type',
          searchParams.params.surveyType
        )
      );
    } else if (searchParams.params.source === 'registration') {
      history.push(REGISTER_URL.replace(':id', searchParams.params.shortId));
    } else if (data.role === userRoles.participant) {
      history.push(PARTICIPANT_DASHBOARD);
    } else {
      history.push(DASHBOARD_URL);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitAttempt(true);

    const isValid = validateForm();

    if (isValid) {
      handleLogin();
    }
  };

  const Content = (
    <>
      {' '}
      <T.H1 ml={2}>{header || 'Log in to your account'}</T.H1>
      {loginRole === userRoles.participant && (
        <Row mt={5}>
          <Col w={[4, 8, 8]}>
            <T.P>Not yet signed up for the Gwent Connect 5 course?</T.P>
            <T.Link external to={extUrls.meloC5} ml={1}>
              Find out more here
            </T.Link>
          </Col>
        </Row>
      )}
      <Row mt={7}>
        <Col w={[4, 12, 6]}>
          <S.Wrapper onSubmit={handleSubmit}>
            <Row>
              {extraInfo}
              <BasicInput
                placeholder="Email"
                name="email"
                type="text"
                value={email}
                handleChange={setEmail}
                error={errors.email}
                mb={4}
              />
              <BasicInput
                placeholder="Password"
                name="password"
                type="password"
                value={password}
                handleChange={setPassword}
                error={errors.password}
              />
              <Col w={[4, 12, 12]} mt={4}>
                <T.Link
                  to={FORGET_PASSWORD}
                  underline={false}
                  color="gray"
                  mt={1}
                >
                  Forget password?
                </T.Link>
              </Col>

              {httpError?.msg && typeof httpError?.msg === 'string' && (
                <Col w={[4, 12, 12]}>
                  <T.P mt="4" color="error">
                    {httpError?.msg}
                  </T.P>
                </Col>
              )}
              <Button
                onClick={() => {}}
                loading={loading}
                type="primary"
                label="Log in"
                mt={4}
                ml={2}
              />

              <Col w={[4, 12, 12]}>
                <T.P mt="6">
                  Don't have an account?{' '}
                  <T.Link
                    to={{
                      pathname:
                        loginRole === userRoles.participant
                          ? PARTICIPANT_SIGN_UP_URL
                          : SIGN_UP_URL,
                      search: searchParams.toString()
                    }}
                    color="secondary"
                  >
                    Sign up
                  </T.Link>
                </T.P>
              </Col>
            </Row>
          </S.Wrapper>
        </Col>
      </Row>
    </>
  );

  if (withoutLayout) {
    return <div style={{ marginBottom: '100px' }}>{Content}</div>;
  }
  return <Layout login>{Content}</Layout>;
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  httpError: state.error,
  loginLoading: state.loading.loginLoading
});

export default connect(mapStateToProps, { loginUser, clearErrors })(Login);
