import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Dropdown } from '../Inputs';
import { Row, Col } from '../Grid';

import { connect } from 'react-redux';
import {
  setFilters as setFiltersAction,
  resetFilters as resetFiltersAction
} from '../../../actions/filters';

import ExportButton from '../ExportButton';

import Button from '../Button';
import { getUsedFilters } from '../../../helpers';

import { localAuthoritiesWithOtherOption } from '../../../constants';

import {
  fetchLocalLeads as fetchLocalLeadsAction,
  fetchLocalLeadTrainersGroup
} from '../../../actions/users';
import { fetchAllTrainers as fetchAllTrainersAction } from '../../../actions/trainerAction';

import { Form, Label } from './FilterResults.style';
import { userRoles } from './../../../constants';

class FilterResults extends Component {
  state = {
    showFilter: this.props.isOpenedByDefault,
    isOpened: this.props.isOpened
  };

  componentDidMount() {
    this.updateFilters();
  }

  componentDidUpdate(prevProps) {
    const { id, role } = this.props;
    if (role !== prevProps.role || id !== prevProps.id) {
      this.updateFilters();
    }
  }

  updateFilters = () => {
    const {
      fetchLocalLeads,
      fetchAllTrainers,
      fetchLocalLeadTrainersGroup: fetchLocalLeadTrainersGroupAction,
      setFilters,
      resetFilters
    } = this.props;
    const { id, role, defaultFilters } = this.props;

    if (role === 'localLead') {
      fetchLocalLeadTrainersGroupAction(id);
    } else {
      fetchAllTrainers(this.props.history);
      fetchLocalLeads();
    }
    resetFilters();
    if (defaultFilters) {
      Object.entries(defaultFilters).forEach(([key, value]) => {
        setFilters(value, key);
      });
    }
  };

  clearAllFilter = () => {
    const {
      resetFilters,
      defaultFilters,
      setFilters,
      handleFilteredData = () => {}
    } = this.props;

    resetFilters();
    if (defaultFilters) {
      Object.entries(defaultFilters).forEach(([key, value]) => {
        setFilters(value, key);
      });
    }

    handleFilteredData(defaultFilters);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.getData();
  };

  getData = () => {
    const {
      sessionType,
      localAuthority,
      localLead,
      trainer,
      organisations,
      isOnline,
      sessionId
    } = this.props;

    const filteredData = {
      sessionType,
      localAuthority,
      localLead,
      trainer,
      organisations,
      isOnline,
      sessionId
    };

    const { handleFilteredData = () => {} } = this.props;
    handleFilteredData(getUsedFilters(filteredData));
  };

  isFilterActive = () => {
    const { showFilter, isOpened } = this.state;
    this.setState({ showFilter: !showFilter, isOpened: !isOpened });
  };

  render() {
    const { showFilter, isOpened } = this.state;

    const {
      localLeadsList,
      trainers,
      localLeadTrainersGroup,
      role,
      hiddenFields = [],
      setFilters,
      // filters
      localLead,
      trainer,
      localAuthority,
      organisations,
      isOnline,
      sessionId,
      exportText: { active, inactive },
      hideApplyFiltersButton,
      target,
      match
    } = this.props;
    const { course } = match.params;

    const filters = {
      localAuthority,
      localLead,
      trainer,
      sessionId,
      isOnline,
      course
    };

    const areFiltersUsed =
      Object.values(
        getUsedFilters({
          localAuthority,
          isOnline
        })
      )?.filter(e => !!e)?.length || false;
    let isOnlineValue = isOnline ? 'Online/remote' : 'Face to Face';
    if (isOnline === null) {
      isOnlineValue = undefined;
    }

    const trainersDropdownOptions = [];
    const localLeadsDropdownOptions = [];
    if (role === userRoles.admin) {
      localLeadsList.forEach(tra =>
        trainersDropdownOptions.push({
          label: tra.name,
          value: tra._id
        })
      );

      trainers.forEach(tra =>
        trainersDropdownOptions.push({
          label: tra.name,
          value: tra._id
        })
      );
    }

    if (role === userRoles.localLead) {
      localLeadTrainersGroup.forEach(tra =>
        trainersDropdownOptions.push({
          label: tra.name,
          value: tra._id
        })
      );
    }

    localLeadsList.forEach(tra =>
      localLeadsDropdownOptions.push({
        label: tra.name,
        value: tra._id
      })
    );

    return (
      <>
        <Row mb={5} mt={4} inner>
          <Col w={[4, 6, 4]}>
            <Button
              onClick={this.isFilterActive}
              type="filter"
              label="Filter results"
              icon="chevron"
              color="primary"
              bgColor="midPrimary"
              isOpenToggle={isOpened}
            />
          </Col>
          <Col w={[4, 6, 4]}>
            {showFilter && (
              <Button
                onClick={this.clearAllFilter}
                type="filter"
                label="Clear filters"
                icon="cross"
                color="secondary"
                bgColor="midSecondary"
              />
            )}
          </Col>
        </Row>

        {showFilter && (
          <Form onSubmit={this.handleSubmit}>
            {role === 'admin' && !hiddenFields.includes('localLead') && (
              <>
                <Label htmlFor="localLead">Local lead(s):</Label>
                <Dropdown
                  multi
                  id="localLead"
                  placeholder="Select as many as you like"
                  handleChange={values => setFilters(values, 'localLead')}
                  value={localLead}
                  options={localLeadsDropdownOptions}
                  m={{ mb: 3 }}
                />
              </>
            )}

            {role !== 'trainer' && !hiddenFields.includes('trainer') && (
              <>
                <Dropdown
                  multi
                  id="trainer"
                  placeholder="Select as many as you like"
                  handleChange={values => setFilters(values, 'trainer')}
                  value={trainer}
                  label="Trainer(s):"
                  m={{ mb: 3 }}
                  options={trainersDropdownOptions}
                />
              </>
            )}

            {!hiddenFields.includes('localAuthority') && (
              <>
                <Dropdown
                  id="localAuthority"
                  label="Local authorities:"
                  placeholder="Select as many as you like"
                  handleChange={values => setFilters(values, 'localAuthority')}
                  multi
                  m={{ mb: 3 }}
                  value={localAuthority}
                  options={localAuthoritiesWithOtherOption.map(auth => ({
                    label: auth,
                    value: auth
                  }))}
                />
              </>
            )}

            {/* {!hiddenFields.includes('organisations') && (
              <>
                <Label htmlFor="organisations">Organisations</Label>
                <Dropdown
                  id="organisations"
                  placeholder="Select as many as you like"
                  multi
                  groupedOptions
                  value={organisations}
                  options={organisationsOptions}
                  handleChange={values => setFilters(values, 'organisations')}
                />

       
              </>
            )} */}

            {!hiddenFields.includes('isOnline') && (
              <>
                <Dropdown
                  label={'Session delivery'}
                  id="isOnline"
                  placeholder="Select"
                  value={isOnlineValue}
                  options={['Online/remote', 'Face to Face'].map(v => ({
                    label: v,
                    value: v
                  }))}
                  handleChange={values => {
                    setFilters(values === 'Online/remote', 'isOnline');
                  }}
                  m={{ mb: 3 }}
                />
              </>
            )}
            {hideApplyFiltersButton || (
              <Row mt={5} inner>
                <Col w={[4, 6, 4]}>
                  <Button
                    onClick={this.handleSubmit}
                    type="primary"
                    label="Apply filters"
                    height="50px"
                  />
                </Col>
              </Row>
            )}
          </Form>
        )}
        <Row mt={5} mb={7} inner>
          <Col w={[4, 6, 4]}>
            <ExportButton
              filters={filters}
              type="secondary"
              text={areFiltersUsed ? active : inactive}
              height="50px"
              target={target}
              exportType="responses"
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    sessionType,
    localLead,
    trainer,
    localAuthority,
    organisations,
    isOnline,
    sessionId
  } = state.filters;

  return {
    id: state.auth.userId,
    currentUser: state.auth,
    localLeadTrainersGroup: state.fetchedData.localLeadGroup,
    localLeadsList: state.fetchedData.localLeadsList,
    trainers: state.trainers.trainers,
    sessionType,
    localLead,
    trainer,
    localAuthority,
    organisations,
    isOnline,
    sessionId
  };
};

export default connect(mapStateToProps, {
  fetchLocalLeadTrainersGroup,
  fetchLocalLeads: fetchLocalLeadsAction,
  fetchAllTrainers: fetchAllTrainersAction,
  setFilters: setFiltersAction,
  resetFilters: resetFiltersAction
})(withRouter(FilterResults));
