import React, { useState, useEffect } from 'react';

import { coursesOptions } from '../../../constants';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import Layout from '../../Layouts';
import { Dropdown } from '../../common/Inputs';
import * as R from '../../../constants/navigationRoutes';

import useSearchParams from './../../../hooks/useSearchParams';

const ChooseCourse = ({ role, match, history }) => {
  const [course, setCourse] = useState();
  const searchParams = useSearchParams();

  const { trainerName, target } = searchParams.params;

  const onChange = _course => {
    setCourse(_course);
    searchParams.set('course', _course);

    history.push({
      pathname: R.TRAINER_RESULTS_URL,
      search: searchParams.toString()
    });
  };

  const _trainerName =
    trainerName && trainerName[0].toUpperCase() + trainerName.slice(1);

  const titles = {
    all: 'All results',
    me: 'Your results',
    myGroup: "My group's results",
    localLeadGroup: `${_trainerName} group's results`,
    trainer: `${_trainerName}'s results`
  };

  const title = titles[target];
  return (
    <Layout>
      <>
        <T.H1 mb={7}>{title}</T.H1>
        {!course && (
          <>
            <Row mb={4}>
              <Col w={[4, 8, 4]}>
                <T.P weight={700}>
                  Please select the course you would like to view
                </T.P>
              </Col>
            </Row>
            <Row mb={4}>
              <Col w={[4, 8, 4]}>
                <Dropdown
                  selected={course}
                  placeholder="Select..."
                  required
                  options={coursesOptions}
                  name="course"
                  handleChange={value => onChange(value)}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    </Layout>
  );
};

export default ChooseCourse;
