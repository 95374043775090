// NEED TO REFACTOR TO USE THE REDUX BITS CREATED

import React, { useState } from 'react';
import { connect } from 'react-redux';
import useCsvDownload from '../../../hooks/useCsvDownload';
import { CSVLink } from 'react-csv';
import Modal from '../modal';
import Button from '../Button';
import { DatePicker } from 'antd';
import * as S from './style';
import * as T from '../Typography';
import { exportDataAction } from '../../../actions/exportDataAction';

const { RangePicker } = DatePicker;

const generateFileName = ({ exportType, target = '' }) => {
  let result = exportType;
  if (target) {
    const targetFormatted = target.replace(/([A-Z])/g, ' $1');
    const targetResults =
      targetFormatted.charAt(0).toUpperCase() + targetFormatted.slice(1);
    result = `${exportType} - ${targetResults}`;
  }
  result += ` - ${new Date().toISOString()}.csv`;

  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const explainingTexts = {
  responses:
    'Select a date range to filter responses by their submission date. Only responses for sessions that took place within the chosen date range will be included in the export.',
  sessions:
    'Select a date range to filter sessions by their occurrence date. Only sessions that took place within the chosen date range will be included in the export.',
  trainers:
    'Select a date range to filter trainers by their registration date. Only trainers who registered within the chosen date range will be included in the export.'
};

const ExportButton = ({
  exportData,
  filters,
  target,
  exportType = 'responses',
  type,
  text,
  disableRangeSelection,
  ...props
}) => {
  const fileName = generateFileName({ exportType, target });
  const [modalOpen, setModalOpen] = useState(false);
  const [datesString, setDatesString] = useState([]);
  const [dates, setDates] = useState([]);

  const onDone = () => {
    setModalOpen(false);
    setDates([]);
  };

  const [data, handleClick, buttonRef] = useCsvDownload({
    exportType,
    filters,
    target,
    onDone
  });

  const onClickExport = () => {
    if (disableRangeSelection) {
      return handleClick();
    }
    setDates([]);
    setDatesString([]);
    setModalOpen(true);
  };

  const explainingText = explainingTexts[exportType];
  return (
    <>
      <CSVLink
        data={data.csvData}
        asyncOnClick
        id="csvLink"
        style={{ display: 'none' }}
        ref={buttonRef}
        headers={data.csvHeaders}
        filename={fileName}
      />

      <Button
        label={text || 'Export CSV'}
        onClick={onClickExport}
        style={{ display: 'block' }}
        {...props}
        type={type || 'primary'}
        loading={data.loading}
        disabled={data.loading || props?.disabled}
      />

      <Modal visible={modalOpen} setModalOpen={setModalOpen}>
        <S.ModalContent>
          <T.H2>Select date range</T.H2>

          {explainingText && <T.P mb={3}>{explainingText}</T.P>}

          <RangePicker
            size="large"
            onChange={(dates, dateStrings) => {
              setDatesString(dateStrings);
              setDates(dates);
            }}
            value={dates}
          />
          <Button
            type="primary"
            onClick={() => handleClick(datesString)}
            label="Export"
            loading={data.loading}
            disabled={!dates.length}
          />
        </S.ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  csvData: state.exportData.data,
  userId: state.auth.userId
});

export default connect(mapStateToProps, { exportData: exportDataAction })(
  ExportButton
);
