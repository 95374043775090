import { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import ParticipantSignUp from './ParticipantSignUp';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';

import { signUpTrainer } from '../../../actions/authAction';
import { useHistory } from 'react-router-dom';

import Layout from '../../Layouts';
import Button from '../../common/Button';

import { BasicInput, Dropdown, Checkbox } from '../../common/Inputs';

import { Wrapper, ContentWrapper, Form } from './SignUp.style';
import IEMessage from '../../common/IEMessage';
import validate from '../../../validation/schemas/signup';
import { DASHBOARD_URL } from '../../../constants/navigationRoutes';
import {
  userRoles,
  localAuthoritiesBasic as localAuthoritiesOptions,
  organisations as organisationsOptions
} from '../../../constants';

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const initialState = {
  name: '',
  email: '',
  password: '',
  localAuthorities: '',
  acceptLocalLeadAccess: '',
  isManager: false,
  organisation: { value: '', category: '' },
  organisationOther: '',
  submitAttempt: false,
  validationErrors: {},
  showOrgOther: false
};

const cleanEmail = email => email.toLowerCase().trim();

const SignUp = ({
  isAuthenticated,
  loading,
  signUpTrainer: signUpTrainerActionCreator,
  httpError
}) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const history = useHistory();

  const {
    name,
    email,
    password,
    isManager,
    organisation,
    localAuthorities,
    acceptLocalLeadAccess,
    submitAttempt,
    validationErrors,
    organisationOther,
    showOrgOther
  } = state;

  useEffect(() => {
    if (isAuthenticated) {
      return history.push('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const validateForm = () => {
    try {
      validate({
        role: isManager ? userRoles.localLead : userRoles.trainer,
        email: cleanEmail(email),
        password,
        name,
        organisation,
        localAuthorities,
        showOrgOther,
        organisationOther
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, password, organisation, localAuthorities]);

  const handleSubmit = e => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const handleSignup = async e => {
    setState({ loading: true });

    const data = await signUpTrainerActionCreator({
      name,
      email: cleanEmail(email),
      password,
      role: isManager ? userRoles.localLead : userRoles.trainer,
      organisation: {
        ...organisation,
        value: showOrgOther ? organisationOther : organisation.value
      },
      localAuthorities
    });
    if (data) {
      history.push(DASHBOARD_URL);
    }
  };

  useEffect(() => {
    setState({ showOrgOther: organisation.value.includes('specify') });
  }, [organisation.value]);

  return (
    <Layout>
      <Wrapper className="sign-up">
        <IEMessage />
        <Row>
          <Col w={[4, 12, 8]}>
            <T.H1 mb="7">Create a trainer account</T.H1>
            <ContentWrapper>
              <T.P weight={400} mb="6">
                <T.P weight={600} d="inline">
                  Important Notice:
                </T.P>{' '}
                Only Gwent Connect 5 staff members (trainers, local leads etc.)
                are supposed to sign up here. If you are participating in the
                course, you can create an account when you register for a
                session and complete your pre-course evaluation.
              </T.P>
              <Form onSubmit={handleSubmit} className="signup-form">
                <BasicInput
                  value={name}
                  handleChange={value => setState({ name: value })}
                  mb={4}
                  label="Name"
                  placeholder="Type your name here"
                  required
                  error={validationErrors.name}
                />
                <BasicInput
                  value={email}
                  handleChange={value => setState({ email: value })}
                  label="Email"
                  placeholder="Type your email here"
                  mb={4}
                  required
                  error={
                    validationErrors.email ||
                    (httpError?.response?.status === 409 &&
                      httpError?.response?.data?.error)
                  }
                />

                <Dropdown
                  selected={organisation.value}
                  m={{ mb: 4 }}
                  label="Where do you work"
                  placeholder="Select"
                  required
                  groupedOptions
                  options={organisationsOptions}
                  handleChange={(value, option, b) =>
                    setState({
                      organisation: {
                        value,
                        category: option?.key?.split('_')[0]
                      }
                    })
                  }
                />
                {showOrgOther && (
                  <BasicInput
                    value={organisationOther}
                    autoFocus
                    handleChange={value =>
                      setState({ organisationOther: value })
                    }
                    placeholder="Type organisation here..."
                    mb={4}
                    error={
                      validationErrors.organisation ||
                      validationErrors.organisationOther
                    }
                  />
                )}

                <BasicInput
                  value={password}
                  label="Create password"
                  required
                  placeholder="Password"
                  mb={4}
                  type="password"
                  handleChange={value => setState({ password: value })}
                  error={validationErrors.password}
                />

                <Dropdown
                  m={{ mb: 4 }}
                  label="In which local authorities do you expect to deliver Gwent Connect 5 training?"
                  required
                  placeholder="Select as many as you like or add new"
                  options={localAuthoritiesOptions.map(i => ({
                    label: i,
                    value: i
                  }))}
                  addNew
                  multi
                  selected={localAuthorities}
                  handleChange={values =>
                    setState({ localAuthorities: values })
                  }
                  error={validationErrors.localAuthorities}
                />

                <Checkbox
                  checked={isManager}
                  handleChange={value => setState({ isManager: value })}
                  mb={6}
                  mt={6}
                  label="I'm managing groups of trainers"
                  helper={{
                    title: '',
                    text: `In case you are responsible for helping manage other trainers (e.g. organising their sessions) please select this option.`
                  }}
                />

                <Checkbox
                  checked={acceptLocalLeadAccess}
                  handleChange={value =>
                    setState({ acceptLocalLeadAccess: value })
                  }
                  mb={3}
                  label="By signing up I confirm that my local lead can access
                    individual profile data such as name, email and
                    organisation as well as session results collected via the
                    app after each session which I was assigned to as trainer"
                />
                <Row>
                  <Col w={[4, 12, 8]}>
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      label="Sign Up"
                      loading={loading}
                      disabled={!acceptLocalLeadAccess}
                      mb={4}
                      mt={4}
                    />
                  </Col>
                </Row>
                <T.P>
                  Already have an account?{' '}
                  <T.Link to="/login" color="secondary">
                    Log in
                  </T.Link>
                </T.P>
              </Form>
            </ContentWrapper>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  loading: state.loading.signupLoading
});

export { ParticipantSignUp };

export default connect(mapStateToProps, {
  signUpTrainer
})(SignUp);
