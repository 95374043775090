import styled from 'styled-components';

export const ModalContent = styled.div`
  input {
    min-height: 20px;
  }

  .ant-calendar-picker {
    width: 100%;
    margin-bottom: 20px;
  }
`;
