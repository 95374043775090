export const meetingPlatforms = [
  { label: 'Zoom', value: 'zoom' },
  { label: 'Teams', value: 'teams' },
  { label: 'Google Meet', value: 'google' },
  { label: 'Other', value: 'other' }
];

export const sessionRemoteOrFace2FaceTypes = [
  { label: 'Remote / online', value: 'online' },
  { label: 'Face to Face', value: 'faceToFace' }
];
