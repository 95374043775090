const Cross = ({ width, height, color, bold, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.51029 2.55294L25.4447 25.4873"
      stroke={color || 'currentColor'}
      strokeWidth={bold ? '3' : '1.32678'}
      strokeLinecap="round"
    />
    <path
      d="M25.4449 2.55448L2.51046 25.4889"
      stroke={color || 'currentColor'}
      strokeWidth={bold ? '3' : '1.32678'}
      strokeLinecap="round"
    />
  </svg>
);

export default Cross;
