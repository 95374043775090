import {
  REMOVE_REGISTRATION_SUCCESS,
  REMOVE_REGISTRATION_FAIL,
  REMOVE_REGISTRATION_LOADING,
  REMOVE_REGISTRATION_RESET
} from '../constants/actionTypes';

const initState = {
  loading: false,
  success: false,
  error: null
};

const participantRemoveRegistrationReducer = (state = initState, action) => {
  switch (action.type) {
    case REMOVE_REGISTRATION_LOADING:
      return {
        ...state,
        loading: true
      };
    case REMOVE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null
      };
    case REMOVE_REGISTRATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case REMOVE_REGISTRATION_RESET:
      return initState;
    default:
      return state;
  }
};
export default participantRemoveRegistrationReducer;
