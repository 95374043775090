import Typography from './Typography';
import Layout from './Layout';
import Menu from './Menu';
import CustomCollapse from '././../../common/Collapse/Collapse.stories';
import { Collapse as AntdCollapse } from 'antd';
import Icon from './Icons';
import Button from './Button';
import CopyLink from './CopyLink';
import GridExample from './../../common/Grid/Grid.stories';
import ModalExample from './../../common/modal/Modal.stories';
import InputExample from '../../common/Inputs/Inputs.stories';
import TableExample from '../../common/Table/Table.stories';
import ProgressExample from '../../common/Progress/Progress.stories';
import ToggleExample from '../../common/Toggle/Toggle.stories';
import InfoPopUpExample from '../../common/InfoPopup/InfoPopup.stories';
import InfoCardExample from '../../common/InfoCard/InfoCard.stories';
import CircleDiagram from '../../common/CircleDiagram';
import IconWithTextButton from '../../common/IconWithTextButton';
const { Panel } = AntdCollapse;

const StoryBook = () => {
  return (
    <>
      <AntdCollapse defaultActiveKey={[]}>
        <Panel header="Typography" key="1">
          <Typography />
        </Panel>
        <Panel header="Layouts" key="2">
          <Layout />
        </Panel>
        <Panel header="Menu" key="3">
          <div style={{ height: '1500px' }}>
            {' '}
            <Menu />{' '}
          </div>
        </Panel>
        <Panel header="Accordions / Collapse" key="accordions">
          <CustomCollapse />
        </Panel>
        <Panel header="Icons" key="4">
          <Icon />
        </Panel>
        <Panel header="Buttons" key="5">
          <Button />
        </Panel>
        <Panel header="Grid" key="6">
          <GridExample />
        </Panel>
        <Panel header="Modal" key="modal">
          <ModalExample />
        </Panel>
        <Panel header="inputs" key="7">
          <InputExample />
        </Panel>
        <Panel header="Update Link" key="8">
          <CopyLink />
        </Panel>

        <Panel header="Tables" key="tables">
          <TableExample />
        </Panel>
        <Panel header="Progress Example" key="progress">
          <ProgressExample />
        </Panel>
        <Panel header="Toggle" key="toggle">
          <ToggleExample />
        </Panel>
        <Panel header="InfoPopUp Example" key="infoPopup">
          <InfoPopUpExample />
        </Panel>
        <Panel header="InfoCard Example" key="infoCard">
          <InfoCardExample />
        </Panel>
        <Panel header="IconWithTextButton" key="iconButton">
          <IconWithTextButton label="click here" />
        </Panel>
        <Panel header="CircleDiagram Example" key="CircleDiagram">
          <CircleDiagram totalScore="100" currentScore="20" progressScore="3" />
        </Panel>
      </AntdCollapse>
    </>
  );
};

export default StoryBook;
