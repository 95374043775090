import axios from 'axios';
import { message } from 'antd';
import { checkAuth } from './authAction';

import {
  FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS,
  FETCH_PARTICIPANT_RESPONSES_SUCCESS,
  LOADING_START,
  REMOVE_REGISTRATION_SUCCESS,
  REMOVE_REGISTRATION_FAIL,
  REMOVE_REGISTRATION_LOADING,
  REMOVE_REGISTRATION_RESET
} from '../constants/actionTypes';

export const fetchParticipantSessionDetails = sessionId => async dispatch => {
  dispatch({
    type: LOADING_START
  });
  axios
    .get(`/api/participant/session-details/${sessionId}`)
    .then(({ data }) => {
      return dispatch({
        type: FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS,
        payload: data
      });
    })
    .catch(() => message.error('Error! something went wrong'));
};

export const fetchParticipantResponses = () => async dispatch => {
  dispatch({
    type: LOADING_START
  });
  axios
    .get(`/api/participant/responses`)
    .then(({ data }) => {
      return dispatch({
        type: FETCH_PARTICIPANT_RESPONSES_SUCCESS,
        payload: data
      });
    })
    .catch(() => message.error('Error! something went wrong'));
};

export const removeRegistration = (
  sessionId,
  removalReason
) => async dispatch => {
  try {
    dispatch({
      type: REMOVE_REGISTRATION_LOADING
    });
    const { data } = await axios.delete(
      `/api/sessions/${sessionId}/registration`,
      {
        data: {
          removalReason
        }
      }
    );
    dispatch({
      type: REMOVE_REGISTRATION_SUCCESS,
      payload: data
    });
    dispatch(checkAuth());
  } catch (error) {
    dispatch({
      type: REMOVE_REGISTRATION_FAIL,
      payload: error?.response?.data?.error
    });
  }
};

export const resetRemoveRegistration = () => async dispatch => {
  dispatch({
    type: REMOVE_REGISTRATION_RESET
  });
};
