import { withTheme } from 'styled-components';
import { Col, Row } from '../Grid';
import * as T from '../Typography';

import { Wrapper } from './List.style';

const ParticipantList = ({ theme, dataList }) => {
  const { colors, spacings } = theme;
  return (
    <Wrapper mw="100%" mt="6">
      <Row
        style={{
          borderBottom:
            dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
        }}
      >
        <Col w={[1, 4, 4]}>
          <T.P weight={700} color="darkGray" mb="2">
            Name
          </T.P>
        </Col>
        <Col w={[1, 4, 4]}>
          <T.P weight={700} color="darkGray" mb="2">
            Organisation
          </T.P>
        </Col>
        <Col w={[1, 4, 4]}>
          <T.P weight={700} color="darkGray" mb="2">
            Registration Date
          </T.P>
        </Col>
      </Row>
      {dataList?.length > 0 ? (
        dataList.map(dataItem => (
          <Row
            key={dataItem._id}
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`
            }}
            ai="center"
          >
            <Col w={[1, 4, 4]}>
              <T.P color="gray" transform="capitalize">
                {dataItem.name || dataItem.email || 'N/A'}
              </T.P>
            </Col>
            <Col w={[1, 4, 4]}>
              <T.P color="gray" transform="capitalize">
                {dataItem.organisation || 'N/A'}
              </T.P>
            </Col>
            <Col w={[1, 4, 4]}>
              <T.P color="gray" transform="capitalize">
                {dataItem.registrationDate || 'N/A'}
              </T.P>
            </Col>
          </Row>
        ))
      ) : (
        <Row jc="center" mt="5">
          <Col w={[4, 12, 12]} jc="center">
            <T.P weight="700" color="gray" ta="center">
              No participants found
            </T.P>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default withTheme(ParticipantList);
