import styled from 'styled-components';
import { colors } from '../../../theme';

export const Form = styled.form``;

export const SubmitBtn = styled.div`
  margin: 0.5rem 0;
  @media (min-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const InputLabel = styled.label``;

export const Label = styled.label`
  width: 100%;
  font-size: 15px;
  margin-left: 0.5rem;
  color: ${colors.darkGray};
`;
