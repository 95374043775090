import { DatePicker as AntDatePicker } from 'antd';
import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import moment from 'moment';

const DatePicker = ({
  onDateChange,
  name,
  label,
  color,
  required,
  error,
  value,
  ...props
}) => {
  return (
    <S.Field error={error} {...props}>
      {label && (
        <CS.Label htmlFor={label}>
          <T.P color={color} m="0" mb="2" weight="700">
            {required && '*'} {label}
          </T.P>
        </CS.Label>
      )}
      <AntDatePicker
        id="DatePicker"
        onChange={val => onDateChange(val, name)}
        name={name}
        value={value ? moment(value) : undefined}
      />
      {error && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </S.Field>
  );
};

export default DatePicker;
