import styled from 'styled-components';
import { colors } from '../../../theme';

export const CreateSessionWrapper = styled.div``;

export const Warning = styled.h1`
  font-family: 'museo-sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  color: ${colors.error};
  align-self: flex-end;
  position: absolute;
  top: 100%;
`;

export const Form = styled.form`
  background: ${colors.midPrimary};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: 10px;
  @media (min-width: 678px) {
    max-width: 600px;
    padding-right: 18%;
  }
`;
