import { Collapse, Empty } from 'antd';
import * as S from './style';
import Icon from './../Icon';
import InfoPopUp from '../InfoPopup';

const { Panel } = Collapse;

const CustomCollapse = ({ items, isEmpty, onChange }) => {
  return (
    <S.Wrapper>
      <Collapse
        bordered={false}
        accordion
        expandIconPosition="right"
        className="custom-collapse"
        expandIcon={({ isActive }) => (
          <Icon
            icon="chevron"
            color="primary"
            direction={isActive ? 'down' : 'up'}
            width="20"
            height="20"
          />
        )}
        onChange={onChange}
      >
        {isEmpty ? (
          <Empty description="No Course Materials" />
        ) : (
          items.map(({ title, key, content, infoContent }) => (
            <Panel
              header={<div style={{ width: '90%' }}>{title}</div>}
              key={key || title}
              extra={
                infoContent ? (
                  <InfoPopUp
                    text={infoContent}
                    icon="info"
                    width="20"
                    height="20"
                    placement={'topRight'}
                  />
                ) : null
              }
            >
              {content}
            </Panel>
          ))
        )}
      </Collapse>
    </S.Wrapper>
  );
};

export default CustomCollapse;
