import axios from 'axios';
import { message } from 'antd';

import { EXPORT_DATA_SUCCESS } from '../constants/actionTypes';
import { getUsedFilters } from '../helpers';

export const exportDataAction = ({
  filters,
  target,
  ref,
  exportType
}) => async dispatch => {
  let link;

  if (exportType === 'responses') {
    link = '/api/export-csv/responses';
  }
  if (exportType === 'sessions') {
    link = '/api/export-csv/sessions';
  }
  if (exportType === 'trainers') {
    link = '/api/export-csv/trainers';
  }

  try {
    const { data } = await axios.post(link, {
      filters: getUsedFilters(filters),
      isExport: true,
      target
    });
    await dispatch({ type: EXPORT_DATA_SUCCESS, payload: data });
    ref.click();
  } catch (error) {
    message.error('Error! something went wrong');
  }
};
