import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div``;

export const Form = styled.form`
  box-sizing: border-box;
  border-radius: 12px;
`;
