import React from 'react';
import styled from 'styled-components';
import theme from './../../../theme';
import setMargin from '../../../helpers/setMargin';
import * as T from '../Typography';

// ICONS
import Cross from './icons/Cross';
import Bin from './icons/Bin';
import Calendar from './icons/Calendar';
import Chevron from './icons/Chevron';
import CircleTick from './icons/CircleTick';
import Copy from './icons/Copy';
import Edit from './icons/Edit';
import Info from './icons/Info';
import Question from './icons/Question';
import Time from './icons/Time';
import Menu from './icons/Menu';
import Eye from './icons/Eye';
import EyeCross from './icons/EyeCross';
import Warning from './icons/Warning';
import Open from './icons/open';
import Plus from './icons/Plus';
import Minus from './icons/Minus';

const Parent = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'flex-start'};
`;

const Icon = props => {
  const { icon, color, text, weight = 'bold' } = props;

  const IconMap = {
    cross: Cross,
    bin: Bin,
    calendar: Calendar,
    chevron: Chevron,
    circleTick: CircleTick,
    copy: Copy,
    edit: Edit,
    info: Info,
    question: Question,
    time: Time,
    menu: Menu,
    eye: Eye,
    eyeCross: EyeCross,
    warning: Warning,
    open: Open,
    plus: Plus,
    minus: Minus
  };

  if (!IconMap[icon]) {
    // eslint-disable-next-line no-console
    console.warn(`<Icon /> called with invalid icon prop "${icon}"`);
    return null;
  }

  const StyledIcon = IconMap[icon];

  return (
    <Parent {...props}>
      <StyledIcon
        {...props}
        color={theme.colors[color] || color || 'currentColor'}
      />
      {text && (
        <T.P weight={weight} ml="1" color={color}>
          {text}
        </T.P>
      )}
    </Parent>
  );
};

export default React.memo(Icon);
