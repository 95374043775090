import styled from 'styled-components';
import { colors } from '../../../theme';

export const Form = styled.form`
  background: ${colors.midPrimary};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: 10px;
  @media (min-width: 678px) {
    max-width: 550px;
    padding-right: 18%;
  }
`;
