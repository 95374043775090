import { useReducer, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import * as S from './SignUp.style';
import { Row, Col } from '../../common/Grid';
import { BasicInput } from '../../common/Inputs';
import Button from '../../common/Button';
import validate from '../../../validation/schemas/signup';
import useSearchParams from './../../../hooks/useSearchParams';

import { userRoles } from '../../../constants';
import {
  LOGIN_URL,
  REGISTER_URL,
  SURVEY_URL,
  PARTICIPANT_DASHBOARD
} from '../../../constants/navigationRoutes';
import { signUpParticipant } from '../../../actions/authAction';

const initialState = {
  email: '',
  password: '',
  name: '',
  submitAttempt: false,
  validationErrors: {}
};

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const cleanEmail = email => email.toLowerCase().trim();

const capitalize = string => {
  // capitalize each word in a string
  return string.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

const ParticipantSignUp = ({
  httpError,
  loading,
  signUpParticipant,
  withoutLayout,
  onLoginClick,
  course
}) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const { name, email, password, submitAttempt, validationErrors } = state;
  const history = useHistory();

  const searchParams = useSearchParams();
  searchParams.set('loginRole', userRoles.participant);

  const validateForm = () => {
    try {
      validate({
        role: userRoles.participant,
        email: cleanEmail(email),
        password,
        name
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, password]);

  const handleSignup = async e => {
    setState({ loading: true });

    const { error } = await signUpParticipant({
      email: cleanEmail(email),
      password,
      name: capitalize(name)
    });

    if (error) return;

    if (searchParams.params.source === 'survey') {
      history.push(
        SURVEY_URL.replace(':id', searchParams.params.shortId).replace(
          ':type',
          searchParams.params.surveyType
        )
      );
    } else if (searchParams.params.source === 'registration') {
      history.push(REGISTER_URL.replace(':id', searchParams.params.shortId));
    } else {
      history.push(PARTICIPANT_DASHBOARD);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  return (
    <Layout>
      <>
        <T.H1 mb="7">Please create an account</T.H1>
        <S.Wrapper className="sign-up">
          <Row>
            <Col w={[4, 12, 8]}>
              <S.ContentWrapper>
                <S.Form /* onSubmit={handleSubmit} */ className="signup-form">
                  <T.P weight={400} mb="5">
                    We require you to create an account so that we can link your
                    evaluation responses as you progress through Gwent Connect 5
                    courses.
                  </T.P>
                  <T.P weight={400} mb="5">
                    You will also be able to log in to track your progress
                    throughout the course, access course materials and receive
                    official certificates.
                  </T.P>
                  <T.P weight={700} mb="5">
                    Your responses to any evaluation will remain entirely
                    anonymous to trainers.
                  </T.P>
                  <Row inner>
                    <Col w={[4, 12, 10]}>
                      <BasicInput
                        value={name}
                        handleChange={value => setState({ name: value })}
                        placeholder="Full name"
                        mb={3}
                        required
                        error={validationErrors.name}
                      />

                      <BasicInput
                        value={email}
                        handleChange={value => setState({ email: value })}
                        placeholder="Email"
                        mb={3}
                        required
                        error={
                          validationErrors.email ||
                          (httpError?.response?.status === 409 &&
                            httpError?.response?.data?.error)
                        }
                      />

                      <BasicInput
                        value={password}
                        placeholder="Password"
                        mb={6}
                        type="password"
                        handleChange={value => setState({ password: value })}
                        error={validationErrors.password}
                      />
                    </Col>
                  </Row>
                  <Row inner mb={4}>
                    <Col w={[4, 12, 10]}>
                      <T.P small>
                        By creating an account I confirm that Aneurin Bevan
                        University Health Board (ABUHB) can access individual
                        profile data such as name, email address and
                        organisation as well as evaluation results collected via
                        the app
                      </T.P>
                    </Col>
                  </Row>
                  <Row inner>
                    <Col w={[4, 6, 6]}>
                      <Button
                        onClick={handleSubmit}
                        type="primary"
                        label="Create account"
                        loading={loading}
                      />
                    </Col>
                  </Row>
                  <T.P mt="6">
                    Already have an account?{' '}
                    {onLoginClick ? (
                      <T.Link onClick={onLoginClick} color="secondary">
                        Log in
                      </T.Link>
                    ) : (
                      <T.Link
                        to={{
                          pathname: LOGIN_URL,
                          search: searchParams.toString()
                        }}
                        color="secondary"
                      >
                        Log in
                      </T.Link>
                    )}
                  </T.P>{' '}
                </S.Form>
              </S.ContentWrapper>
            </Col>
          </Row>
        </S.Wrapper>
      </>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  loading: state.loading.signupLoading
});

export default connect(mapStateToProps, {
  signUpParticipant
})(ParticipantSignUp);
