import styled from 'styled-components';

export const Wrapper = styled.div`
  .custom-collapse {
    border-radius: 12px;
    overflow: hidden;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    background-color: ${({ theme }) => theme.colors.lightPrimary};
    display: flex;
    align-items: center;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    color: ${({ theme }) => theme.colors.primary};
    height: 100%;
    top: -50%;
    transform: translate(0, 100%);
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 16px;
    font-weight: 600;
    padding: ${({ theme }) => theme.spacings[4]};
    padding-left: ${({ theme }) => theme.spacings[6]};
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[6]}`};
  }

  .ant-collapse-borderless > .ant-collapse-item:last-child {
    border-bottom: none;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    margin-right: ${({ theme }) => theme.spacings[7]};
    align-items: center;
    display: flex;
  }
`;
