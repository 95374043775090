import React from 'react';

import EditEmail from '../../../common/EditEmail';
import sessionsDateFormat, {
  sessionsTimeFormat
} from '../../../../helpers/formatSessionDate';

const SendReminderEmails = ({
  sessionDetails,
  handleAddEmailsClick,
  name,
  drawerKey,
  onClose
}) => {
  const confirmedEmails = sessionDetails.participantsEmails.filter(
    item => item.status === 'confirmed'
  );

  return (
    <EditEmail
      participantsEmails={confirmedEmails}
      type="reminder"
      trainer={name}
      sessionDate={sessionsDateFormat(sessionDetails.dates)}
      sessionType={sessionDetails.type}
      address={sessionDetails.address}
      trainers={sessionDetails.trainers}
      startTime={sessionsTimeFormat(sessionDetails.dates)}
      endTime={sessionDetails.endTime}
      shortId={sessionDetails.shortId}
      sessionId={sessionDetails._id}
      course={sessionDetails.course}
      handleAddEmailsClick={handleAddEmailsClick}
      drawerKey={drawerKey}
      backCallback={onClose}
      modules={sessionDetails.modules}
    />
  );
};

export default SendReminderEmails;
