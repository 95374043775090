import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY,
  TRAINER_TTT_SIGN_UP,
  TRAINER_FILLED_TRAINER_SURVEY_SUCCESSFULLY
} from '../../../constants/navigationRoutes';
import moment from 'moment';

import { Alert } from 'antd';
import Swal from 'sweetalert2';
import { customSwal } from '../../../theme';

import { Redirect, useParams } from 'react-router-dom';
import Spin from '../../common/Spin';
import { surveyTypesNew, userRoles } from '../../../constants';
import * as R from '../../../constants/navigationRoutes';
import { filterCountableAnswers } from './utils';
import useSearchParams from './../../../hooks/useSearchParams';

// Styles
import Button from '../../common/Button';
import Progress from '../../common/Progress';
import Layout from '../../Layouts';
import {
  Container,
  SpinWrapper,
  SkipButtonsDiv,
  Form,
  SubmitBtn
} from './Survey.style';

// Actions
import {
  fetchSurveyData,
  submitSurvey,
  getParticipantByEmail,
  resetSurvey
} from '../../../actions/surveyAction';

// Components
import ConfirmSurvey from './ConfirmSurvey';

import SurveyQs from './SurveyQs';

import { useEffect } from 'react';

const Spinner = () => (
  <SpinWrapper>
    <Spin size="large" />
  </SpinWrapper>
);

class Survey extends Component {
  state = {
    disagreedToResearch: false,
    formState: {},
    section: 'confirmSurvey',
    completionRate: 0,
    maxNum: null,
    minNum: 0,
    showLoginPage: null
  };

  componentDidMount() {
    this.getData();
    const { type } = this.props.match.params;

    this.setState({ showLoginPage: type !== surveyTypesNew.PRE_COURSE });

    // info pop up

    if (this.props.userId) {
      if (this.props.role !== userRoles.participant) {
        return Swal.fire({
          title: 'Error!',
          text:
            'You are logged in as trainer, Log in or switch to your participant account to proceed',
          type: 'info',
          confirmButtonText: 'Ok',
          ...customSwal
        }).then(() => {
          this.props.history.push('/');
        });
      }
    }
  }

  componentWillUnmount() {
    resetSurvey();
  }

  async componentDidUpdate(prevPros, prevState) {
    const { id, type } = this.props.match.params;
    const { id: prevId, type: prevType } = prevPros.match.params;

    const sessionDataResolved =
      prevPros.surveyData.surveyData !== this.props.surveyData.surveyData &&
      this.props.surveyData.surveyData;

    if (sessionDataResolved) {
      await this.checkPINonBlur();
      this.submitPIN();
    }

    if (id !== prevId && type !== prevType) {
      this.getData();
    }
  }

  getData = () => {
    const { id, type } = this.props.match.params;
    const { userId } = this.props;

    const { fetchSurveyData: fetchSurveyDataAction } = this.props;
    if (userId) fetchSurveyDataAction({ id, type });
  };

  // enables user to change direction of sections (used by back and next buttons)
  sectionChange = ({ newSection, direction, index }) => {
    const { section } = this.state;
    const { surveyData } = this.props;
    const { uniqueGroups } = surveyData;

    if (typeof index === 'number')
      return this.setState({ section: uniqueGroups[index] });
    if (newSection) return this.setState({ section: newSection });

    const foundIndex =
      uniqueGroups && uniqueGroups.findIndex(e => e === section);

    return this.setState({
      section: uniqueGroups[foundIndex + direction] || 'confirmSurvey'
    });
  };

  // renders back and next button and calls section change and submit actions
  renderSkipButtons = (section, disabled, uniqueGroups) => {
    const { completionRate } = this.state;
    const readyForSubmission =
      completionRate === 100 &&
      section === uniqueGroups[uniqueGroups.length - 1];
    return (
      <SkipButtonsDiv>
        <Button
          label="Back"
          width="100px"
          height="50px"
          type="primary"
          onClick={() => {
            window.scrollTo(0, 0);
            this.sectionChange({ direction: -1 });
          }}
        />

        {!readyForSubmission && (
          <Button
            label="Next"
            width="100px"
            height="50px"
            type="primary"
            disabled={
              section === uniqueGroups[uniqueGroups.length - 1] || disabled
            }
            onClick={() => {
              window.scrollTo(0, 0);
              this.sectionChange({ direction: 1 });

              if (section === 'enterPIN') {
                this.submitPIN();
              }
            }}
          />
        )}
      </SkipButtonsDiv>
    );
  };

  // renders research confirmation popup at beginning of enterPIN section
  researchConfirm = () => this.setState({ disagreedToResearch: false });

  // validates PIN input on blur/focus
  checkPINonBlur = async () => {
    if (!this.props.userId) {
      return;
    }
    const {
      getParticipantByEmail: getParticipantByEmailAction,
      surveyData
    } = this.props;
    const { sessionId, surveyType } = surveyData.surveyData;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // check if participant has already filled out demographic section in previous surveys -> skip that part when submitting
    await getParticipantByEmailAction({ sessionId, surveyType, userTimeZone });
  };

  // submits and validates PIN request
  submitPIN = () => {
    if (!this.props.userId) {
      return;
    }

    const {
      answeredSameModule,
      redirectTo,
      showSurvey,
      contentMsg,
      history
    } = this.props;

    if (this.props.role !== userRoles.participant) return;
    // check if USER already submitted survey
    if (answeredSameModule) {
      Swal.fire({
        title: 'Error!',
        text: "you've already submitted this evaluation.",
        type: 'error',
        confirmButtonText: 'Ok',
        ...customSwal
      }).then(() => history.push('/participant-dashboard'));
    } else if (!showSurvey && contentMsg) {
      Swal.fire({
        title: 'Error!',
        text: contentMsg,
        type: 'error',
        confirmButtonText: 'Ok',
        ...customSwal
      }).then(() => history.push('/participant-dashboard'));
    } else if (redirectTo?.shortId) {
      Swal.fire({
        title: 'Sorry! you have to fill out another evaluation before this.',
        text:
          contentMsg ||
          'Before filling out this evaluation please submit the pre-course evaluation. Clicking OK will bring you to the right evaluation.',

        type: 'error',
        confirmButtonText: 'Ok',
        ...customSwal
      }).then(() => {
        history.push(`/survey/${redirectTo.shortId}/${redirectTo.surveyType}`);
        window.location.reload();
      });
    }
  };

  // SURVEY FUNCTIONS
  // function that will check if the div for the answer has been selected and if so add that answer to the formstate
  selectCheckedItem = (value, questionId) => {
    const { formState } = this.state;
    formState[questionId] = value;
    this.setState({ formState }, () => {
      this.trackAnswers();
    });
  };

  // function to track progress on survey
  trackAnswers = () => {
    const { formState } = this.state;
    const { surveyData } = this.props;
    const { surveyData: surveyDetails, uniqueGroups } = surveyData;

    if (formState && surveyDetails) {
      const questions = surveyDetails.questionsForSurvey.filter(question => {
        return uniqueGroups.includes(question.group.text);
      });

      let innerAnswers = 0;
      Object.values(formState).forEach(ans => {
        if (
          ans?.answer &&
          !Array.isArray(ans?.answer) &&
          typeof ans?.answer === 'object'
        ) {
          innerAnswers = innerAnswers + Object.values(ans.answer).length;
        }
      });

      const numberOfQs = questions.filter(q => q.isRequired !== false).length;
      const numberOfAs =
        // innerAnswers +
        Object.values(formState).filter(item =>
          filterCountableAnswers({
            ...item,
            questionsForSurvey: surveyDetails.questionsForSurvey
          })
        ).length;

      const rate = Math.ceil((numberOfAs / numberOfQs) * 100);

      this.setState({ completionRate: rate });
    } else {
      this.setState({ completionRate: 0 });
    }
  };

  // set maxNumber for beahviouralInsights1
  setMaxNumber = (code, number) => {
    if (code === 'People') {
      this.setState({ maxNum: number });
    }
  };

  // set minNumber the maxNumber can be
  setMinNumber = number => {
    const { minNum } = this.state;
    if (number > minNum) {
      this.setState({ minNum: number });
    }
  };

  // check for any changes to the survey inputs and add them to the formstate
  handleChange = e => {
    const { group, field, type } = e.target.dataset;
    const question = e.target.name;
    const { max } = e.target;

    const { formState } = this.state;
    // if any other type we assign the value to answer and put it in the state
    const answer = { answer: e.target.value, question };

    if (type === 'fromZeroToTen') {
      if (answer.answer === '' || answer.answer === null) {
        answer.answer = 0;
      } else {
        answer.answer = Math.min(Number(answer.answer), max);
      }
    }
    if (group === 'demographic') {
      answer.participantField = field;
    }
    this.setState({ formState: { ...formState, [question]: answer } }, () => {
      this.trackAnswers();
    });
  };

  handleStarChange = (answer, question, participantField) => {
    const { formState } = this.state;

    const fixedAnswer = {
      answer: answer,
      question,
      participantField
    };
    this.setState(
      { formState: { ...formState, [question]: fixedAnswer } },
      () => {
        this.trackAnswers();
      }
    );
  };

  handleDropdown = (answer, question, participantField) => {
    const { formState } = this.state;
    const answerObj = { answer, question, participantField };

    this.setState(
      { formState: { ...formState, [question]: answerObj } },
      () => {
        this.trackAnswers();
      }
    );
  };

  handleAntdDatePicker = (question, value, group, field) => {
    // const question = e.target.name;
    const { formState } = this.state;
    // if any other type we assign the value to answer and put it in the state
    const answer = { answer: value, question };
    if (group === 'demographic') {
      answer.participantField = field;
    }
    this.setState({ formState: { ...formState, [question]: answer } }, () => {
      this.trackAnswers();
    });
  };

  // handles case when user selects 'other'
  handleOther = e => {
    const question = e.target.name;
    const { formState } = this.state;
    const originalQuestion = formState[question];

    const prefix = !Array.isArray(originalQuestion.answer)
      ? originalQuestion.answer.split(' - ')[0]
      : originalQuestion.answer;
    const otherAnswer = `${prefix}: ${e.target.value}`;

    this.setState(
      {
        formState: {
          ...formState,
          [question]: { ...originalQuestion, otherAnswer }
        }
      },
      () => {
        this.trackAnswers();
      }
    );
  };

  // when participant submits form
  // this puts the required info into an object and sends to server
  handleSubmit = e => {
    e.preventDefault();
    const {
      surveyData,
      submitSurvey: submitSurveyAction,
      role,
      userRolesProp
    } = this.props;
    const { uniqueGroups } = surveyData;
    const { surveyType, sessionId, questionsForSurvey } = surveyData.surveyData;
    // these are the base of validation in the backend (checked against formstate to see if everything was getting answered correctly)
    const questionsForParticipant = questionsForSurvey.filter(question => {
      return uniqueGroups.includes(question.group.text);
    });

    const { formState, disagreedToResearch, completionRate } = this.state;

    let redirectToAfterSuccess = PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY;
    if (surveyType === surveyTypesNew.POST_TRAIN_THE_TRAINER) {
      if (userRolesProp?.find(e => e !== userRoles.participant)) {
        redirectToAfterSuccess = TRAINER_FILLED_TRAINER_SURVEY_SUCCESSFULLY;
      } else {
        redirectToAfterSuccess = TRAINER_TTT_SIGN_UP;
      }
    }

    const formSubmission = {
      sessionId,
      surveyType,
      formState,
      disagreedToResearch,
      // create array of question ids required
      questionIDList: questionsForParticipant
        .filter(q => q.isRequired !== false)
        .map(e => e._id.toString())
    };
    if (completionRate === 100) {
      submitSurveyAction({
        formSubmission,
        sessionId,
        role,
        history: this.props.history,
        redirectToAfterSuccess
      });
    }
  };

  render() {
    const {
      formState,
      section,
      completionRate,
      showLoginPage
      // currentQuestion
    } = this.state;

    const { surveyData, errors, userId, searchParams } = this.props;
    const { uniqueGroups } = surveyData;
    const loadingError = Object.keys(surveyData.msg).length > 0;

    const readyForSubmission =
      completionRate === 100 &&
      section === uniqueGroups[uniqueGroups.length - 1];

    const { surveyData: surveyDetails } = surveyData;
    const answeredQuestionsIds = Object.values(formState)
      .filter(item => {
        const question = surveyDetails?.questionsForSurvey?.find(
          q => q._id === item.question
        );
        if (
          item.answer &&
          !Array.isArray(item.answer) &&
          typeof item.answer === 'object' &&
          !moment.isMoment(item.answer)
        ) {
          return (
            question?.questionsList?.length ===
            Object.values(item.answer).length
          );
        }

        if (item.answer && Array.isArray(item.answer)) {
          return item.answer.length && !item.answer?.includes('please specify');
        }

        const isFollowupQuestionRequired =
          question?.followUpQuestion?.ifAnswerIsOneOf.includes(item.answer) &&
          question?.followUpQuestion?.isRequired;

        return (
          item.answer &&
          !(
            item.answer?.includes &&
            (item.answer?.toLowerCase().includes('please specify') ||
              isFollowupQuestionRequired) &&
            !item.otherAnswer
          )
        );
      })
      .map(({ question }) => question);

    const surveyGroups = [
      'follow up',
      'demographic',
      'Behavioural Insights',
      'Train the Trainer feedback',
      'about your trainer'
    ];

    if (!userId && showLoginPage !== null) {
      return (
        <Redirect
          to={{
            pathname: showLoginPage ? R.LOGIN_URL : R.PARTICIPANT_SIGN_UP_URL,
            search: searchParams.toString()
          }}
        />
      );
    }

    if (!surveyData.loaded) {
      return <Spinner />;
    }

    return (
      <Layout>
        <Container>
          {loadingError && (
            <Alert message={surveyData.msg} type="warning" showIcon />
          )}

          {surveyDetails && surveyDetails !== null && (
            <div>
              {section === 'confirmSurvey' && (
                <ConfirmSurvey
                  sessionDates={surveyData.surveyData.sessionDates}
                  trainerNames={surveyData.surveyData.trainerNames}
                  modules={surveyData.surveyData.modules}
                  course={surveyData.surveyData.course}
                  sessionType={surveyData.surveyData.sessionType}
                  sectionChange={this.sectionChange}
                  researchConfirm={this.researchConfirm}
                />
              )}

              <Form onSubmit={this.handleSubmit}>
                {/* survey questions start */}
                {surveyGroups.includes(section) &&
                  uniqueGroups.map(group => {
                    const questions =
                      surveyDetails &&
                      surveyDetails.questionsForSurvey.filter(
                        question => question.group.text === group
                      );
                    if (section === group) {
                      const unanswered = questions
                        .filter(
                          q =>
                            !answeredQuestionsIds.includes(q._id) &&
                            q.isRequired !== false
                        )
                        .map(q => q._id);

                      return (
                        <SurveyQs
                          key={group}
                          questions={questions}
                          onChange={this.handleChange}
                          handleStarChange={this.handleStarChange}
                          handleDropdown={this.handleDropdown}
                          handleOther={this.handleOther}
                          answers={formState}
                          answeredQuestionsIds={answeredQuestionsIds}
                          selectCheckedItem={this.selectCheckedItem}
                          errors={errors}
                          handleAntdDatePicker={this.handleAntdDatePicker}
                          renderSkipButtons={this.renderSkipButtons(
                            group,
                            unanswered.length > 0,
                            uniqueGroups,
                            completionRate
                          )}
                          completionRate={completionRate}
                          setMaxNumber={this.setMaxNumber}
                          // currentQuestion={currentQuestion}
                          // setCurrentQuestion={this.setCurrentQuestion}
                        />
                      );
                    }
                    return null;
                  })}
                {readyForSubmission && (
                  <SubmitBtn type="submit">Submit Feedback</SubmitBtn>
                )}
              </Form>
              {/* footer rendering */}
              {section !== 'confirmSurvey' && (
                <Progress
                  completionRate={completionRate}
                  readyForSubmission={readyForSubmission}
                  section={section}
                  uniqueGroups={uniqueGroups}
                />
              )}
            </div>
          )}
        </Container>
      </Layout>
    );
  }
}

const Waiter = props => {
  const searchParams = useSearchParams();
  const { id: shortId, type } = useParams();

  searchParams.set('source', 'survey');
  searchParams.set('loginRole', userRoles.participant);
  searchParams.set('shortId', shortId);
  searchParams.set('surveyType', type);

  useEffect(() => {
    if (props.httpError) {
      Swal.fire({
        title: 'Error!',
        text: props.httpError,
        type: 'error',
        confirmButtonText: 'Ok',
        ...customSwal
      });
      props.history.push('/participant-dashboard');
    } else {
      if (props.surveyDataLoaded) {
        Swal.fire({
          title: 'Important Information',
          text:
            'Welcome to the Gwent Connect 5 Programme. Before completing an evaluation form please make sure all the details related to your session are correct.',
          type: 'info',
          confirmButtonText: 'Ok',
          ...customSwal
        });
      }
    }
  }, [props.httpError, props.history, props.surveyDataLoaded]);
  if (!props.loaded) return <Spinner />;
  return <Survey {...props} searchParams={searchParams} />;
};

const mapStateToProps = state => {
  return {
    surveyData: state.survey,
    answeredSameModule: state.survey.answeredSameModule,
    redirectTo: state.survey.redirectTo,
    showSurvey: state.survey.showSurvey,
    contentMsg: state.survey.contentMsg,
    httpError: state.survey.httpError,
    surveyDataLoaded: state.survey.loaded,
    errors: state.survey.errors,
    role: state.auth.role,
    userId: state.auth.userId,
    email: state.auth.email,
    participantAuthForSurvey: state.auth.participantAuthForSurvey,
    isAuthenticated: state.auth.isAuthenticated,
    loaded: state.auth.loaded,
    userRolesProp: state.auth.userRoles
  };
};

export default connect(mapStateToProps, {
  fetchSurveyData,
  submitSurvey,
  getParticipantByEmail,
  resetSurvey
})(Waiter);
