import styled, { css } from 'styled-components';
import setMargin from '../../../../helpers/setMargin';
import { breakpointsMax } from '../../../../theme';

import { Select as AntdSelect } from 'antd';

const { Option: AntdOption } = AntdSelect;

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  -ms-word-break: break-all !important;
  word-break: break-word !important;

  // ANTD STYLING
  // STYLE OVERALL CONTAINER
  .ant-select,
  .ant-select-selection,
  .ant-select-selection--multiple {
    display: flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.borders.radius} !important;
    width: 100% !important;
    cursor: pointer;
    font-size: 16px;
    border-color: ${({ theme, open, color, focus, error }) => {
      if (error) return theme.colors.error;
      return open || focus
        ? theme.colors.blue
        : theme.colors[color] || theme.colors.midPrimary;
    }} !important;
    min-height: 56px;
  }

  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 34px !important;
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    width: 100%;
  }

  .ant-select-allow-clear
    .ant-select-selection--multiple
    .ant-select-selection__rendered,
  .ant-select-show-arrow
    .ant-select-selection--multiple
    .ant-select-selection__rendered {
    width: 100%;
  }
  // STYLE INPUT CONTAINER
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 0;
    padding: ${({ theme }) => theme.spacings[3]} !important;
    overflow-x: hidden;
    background: ${({ theme }) => theme.colors.gray2};
    height: 100% !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    padding: ${({ theme }) => theme.spacings[3]};
  }

  .ant-select-selection--single {
    height: 100%;
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    height: 100%;
    padding-left: 6px;
  }

  .ant-select-search--inline .ant-select-search__field {
    min-height: auto !important;
  }
  .ant-select-selection__choice {
    display: flex;
    border-radius: 8px;

    div {
      padding: 5px;
    }
  }

  // STYLE SELECTED ITEM IN INPUT FOR MULTIPLE SELECT
  .ant-select-multiple .ant-select-selection-item {
    height: 100%;
    padding: 0.1rem 0.4rem;
    font-size: 16px;
    word-wrap: break-word !important;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    height: 100%;
  }

  // STYLE ARROW ICON
  .ant-select-arrow,
  .ant-select-clear,
  .ant-select-selection-item-remove {
    color: ${({ theme }) => theme.colors.gray7};
    width: 20px;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 15px;
      transition: all ease 0.5s;
      transform: ${({ open, multi, search }) =>
        open && !multi && !search && 'rotate(-180deg)'};
    }
  }

  .ant-select-selection {
    line-height: 16px !important;
  }

  // STYLE SELECTED ITEM IN SINGLE DROPDOWN
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray9};

    // STYLE SELECTED ITEM IN MUTLI

    // SEEMS WEIRD BUT YOU NEED TO DO THIS TO FORCE THE WIDTH TO STAY CONTAINED
    width: 1px;
  }
  .ant-select-selection--multiple .ant-select-selection__clear,
  .ant-select-selection--multiple .ant-select-arrow {
    top: 50%;
  }
  .ant-select-selection__clear {
    margin-top: -27px;
    width: 20px;
    color: #dfdfdf;
  }
  @media ${breakpointsMax.tablet} {
    max-width: 90vw;
  }
`;

export const menuStyle = css``;

export const Answer = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const Option = styled(AntdOption)`
  font-size: 16px !important;
  font-weight: normal;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.gray2 : theme.colors.white};

  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.gray9};
  display: flex;
  align-items: center;
  white-space: normal;
`;
