import axios from 'axios';
import { message } from 'antd';
import Swal from 'sweetalert2';

import { customSwal } from '../theme';

import {
  ADD_SESSION_SUCCESS,
  ADD_SESSION_FAIL,
  GET_SESSION_DETAILS_BY_SHORT_ID,
  UPDATE_ATTENDEES_SUCCESS,
  LOADING_START,
  LOADING_END,
  EMAIL_SCHEDULE_SUCCESS,
  STORE_SESSION_DATA,
  LOADING_TRUE,
  LOADING_FALSE,
  CLEAR_SESSION_DATA,
  RESET_SESSION_STATE
} from '../constants/actionTypes';

import { fetchSessionDetails } from './groupSessionsAction';

export const createSessionAction = (sessionData, done) => dispatch => {
  dispatch({
    type: LOADING_START
  });

  axios
    .post('/api/add-session', sessionData)
    .then(res => {
      dispatch({
        type: ADD_SESSION_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: ADD_SESSION_FAIL,
        payload: err
      });
    });
};

export const storeInputData = data => async dispatch => {
  dispatch({
    type: STORE_SESSION_DATA,
    payload: data
  });
};

export const clearInputData = () => async dispatch => {
  dispatch({
    type: CLEAR_SESSION_DATA,
    payload: {}
  });
};

export const getSessionDetails = ({
  shortId,
  sessionRegistration,
  history
}) => dispatch => {
  axios
    .get(`/api/sessions/${sessionRegistration}?shortId=${shortId}`)
    .then(res =>
      dispatch({
        type: GET_SESSION_DETAILS_BY_SHORT_ID,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.response.status === 404) {
        history.push('/404err');
        return message.error(
          `Error!, The session You are looking for is unavailable`
        );
      }
      return history.push('/500err');
    });
};

export const updateSessionAttendeesList = ({
  sessionId,
  participantsEmails,
  status,
  handleCloseDrawer,
  history
}) => dispatch => {
  // start loading
  dispatch({
    type: LOADING_START
  });

  axios
    .patch(`/api/sessions/${sessionId}/attendeesList`, {
      participantsEmails,
      status
    })
    .then(res => {
      dispatch({
        type: UPDATE_ATTENDEES_SUCCESS,
        payload: res.data
      });
      Swal.fire({
        title: 'Success',
        text: 'Attendees list updated successfully',
        timer: 2000,
        confirmButtonText: 'Ok',
        ...customSwal
      }).then(handleCloseDrawer);

      return dispatch(fetchSessionDetails(sessionId, history));
    })
    .catch(error => {
      // end loading
      dispatch({
        type: LOADING_END
      });
      handleCloseDrawer();
      return message.error(`Error!, ${error?.response?.data?.error}`);
    });
};

export const sendEmailReminder = (
  { sessionId, type, ...emailData },
  handleCloseDrawer
) => dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'sendEmail'
  });

  axios
    .post(`/api/sessions/${sessionId}/emails?type=${type}`, {
      ...emailData,
      type
    })
    .then(res => {
      dispatch({
        type: UPDATE_ATTENDEES_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: LOADING_FALSE,
        payload: 'sendEmail'
      });

      handleCloseDrawer();
      return dispatch(fetchSessionDetails(sessionId));
    })
    .catch(error => {
      dispatch({
        type: LOADING_FALSE,
        payload: 'sendEmail'
      });

      return message.error(`Error!, ${error?.response?.data?.error}`);
    });
};

export const scheduleNewEmail = (emailData, cb, history) => dispatch => {
  // start loading
  dispatch({
    type: LOADING_TRUE,
    payload: 'sendEmail'
  });

  axios
    .post(`/api/sessions/${emailData.sessionId}/scheduled-emails`, emailData)
    .then(res => {
      dispatch({
        type: EMAIL_SCHEDULE_SUCCESS,
        payload: res.data
      });

      dispatch({
        type: LOADING_FALSE,
        payload: 'sendEmail'
      });

      Swal.fire({
        title: 'Success',
        text: 'Done! Email successfully scheduled',
        timer: 2000,
        confirmButtonText: 'Ok',
        ...customSwal
      });

      return dispatch(fetchSessionDetails(emailData.sessionId));
    })
    .catch(error => {
      // end loading
      dispatch({
        type: LOADING_FALSE,
        payload: 'sendEmail'
      });

      return message.error(`Error!, ${error?.response?.data?.error}`);
    });
};

export const cancelScheduledEmail = (
  { sessionId, scheduledEmailId },
  history
) => dispatch => {
  // start loading
  axios
    .delete(`/api/sessions/${sessionId}/scheduled-emails/${scheduledEmailId}`)
    .then(() => {
      Swal.fire({
        title: 'Success',
        text: 'Email successfully canceled',
        timer: 2000,
        confirmButtonText: 'Ok',
        ...customSwal
      });

      return dispatch(fetchSessionDetails(sessionId, history));
    })
    .catch(error => {
      // end loading
      dispatch({
        type: LOADING_END
      });

      return message.error(`Error!, ${error?.response?.data?.error}`);
    });
};

export const resetSessionState = () => async dispatch => {
  dispatch({
    type: RESET_SESSION_STATE,
    payload: {}
  });
};
