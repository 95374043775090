import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { fetchParticipantResponses } from '../../../actions/participantActions';

import * as T from '../../common/Typography';
import * as S from './style';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import { Dropdown } from '../../common/Inputs';
import Spin from '../../common/Spin';

import Layout from '../../Layouts';

import { MATERIALS } from '../../../constants/materials';
import {
  surveyTypesNew,
  courses,
  modules,
  coursesOptions
} from '../../../constants';
import { SURVEY_URL } from '../../../constants/navigationRoutes';

const ParticipantMaterials = ({
  sessions,
  responses,
  fetchSessions,
  fetchResponses,
  match
}) => {
  const { course } = match.params;
  const [loading, setLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(course);
  const [completedModules, setCompletedModules] = useState({
    C5: [],
    YOUNG_PEOPLE: []
  });

  const history = useHistory();

  useEffect(() => {
    fetchSessions();
    fetchResponses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sessions || sessions.length === 0) {
      setLoading(false);
      return null;
    }
    const c5Modules = [];
    const YPModules = [];
    const _courses = {};
    sessions.forEach(ses => {
      _courses[ses.course] = ses.course;
      ses.modules.forEach(mod => {
        if (ses.course === courses.C5) {
          c5Modules.push(mod);
        } else {
          YPModules.push(mod);
        }
      });
    });

    setCompletedModules({
      C5: c5Modules
        .filter((val, index, self) => self.indexOf(val) === index)
        .sort(),
      YOUNG_PEOPLE: YPModules.filter(
        (val, index, self) => self.indexOf(val) === index
      ).sort()
    });
    setLoading(false);
  }, [sessions]);

  const showPreMaterials = mod => {
    let filterFunc = () => true;
    if (mod === modules.TRAIN_THE_TRAINER || mod === modules.TRAINER_UPSKILL) {
      return true;
    } else {
      filterFunc = resp =>
        resp.surveyType === surveyTypesNew.PRE_COURSE &&
        resp.course === selectedCourse;
    }
    const preCourseCompleted = responses.filter(filterFunc);
    return preCourseCompleted?.length > 0;
  };

  const showPostMaterials = mod => {
    if (mod === modules.TRAIN_THE_TRAINER || mod === modules.TRAINER_UPSKILL) {
      return true;
    }

    let totalModules = [];
    responses
      .filter(resp => surveyTypesNew.PRE_COURSE !== resp.surveyType)
      .forEach(resp => {
        if (resp.course === selectedCourse) {
          resp.modules.map(
            module => Number(module) && totalModules.push(Number(module))
          );
        }
      });

    const highestModCompleted = Math.max(...totalModules);
    return highestModCompleted >= Number(mod);
  };

  const findSurvey = mod => {
    const session = sessions.filter(
      ses => ses.modules.includes(mod) && ses.course === selectedCourse
    );
    if (session?.length > 0) return session[0].shortId;

    return sessions[0].shortId;
  };

  const getCoursePostSurvey = mod => {
    if (mod === modules.TRAIN_THE_TRAINER)
      return surveyTypesNew.POST_TRAIN_THE_TRAINER;
    if (mod === modules.TRAINER_UPSKILL)
      return surveyTypesNew.POST_TRAINER_UPSKILL;
    return surveyTypesNew.POST;
  };

  let Content;

  if (selectedCourse) {
    const courseCompletedModules = completedModules[selectedCourse];
    Content = () => (
      <>
        {courseCompletedModules.length > 0 ? (
          courseCompletedModules.map(mod => (
            <Row>
              <Col w={[4, 8, 8]} mt={5}>
                <S.Wrapper>
                  <T.H4 weight={700} mb="4">
                    {mod === modules.TRAIN_THE_TRAINER ||
                    mod === modules.TRAINER_UPSKILL
                      ? mod
                      : `Module ${mod}`}
                  </T.H4>

                  {showPreMaterials(mod) ? (
                    <>
                      <T.P weight={700} mb={2}>
                        Pre-session materials
                      </T.P>
                      {MATERIALS[selectedCourse][mod] &&
                        MATERIALS[selectedCourse][mod]?.PRE?.map(material => (
                          <T.Link
                            external
                            to={material.link}
                            color="primary"
                            mb="2"
                          >
                            {material.displayName}
                          </T.Link>
                        ))}
                    </>
                  ) : (
                    <>
                      <T.P mb={4}>
                        To access these you first need to complete the
                        pre-course evaluation
                      </T.P>
                      <Col w={[4, 7, 7]} ml="-10px">
                        <Button
                          type="tertiary"
                          label="Complete pre-course evaluation"
                          onClick={() =>
                            history.push(
                              SURVEY_URL.replace(
                                ':id',
                                findSurvey(mod)
                              ).replace(':type', surveyTypesNew.PRE_COURSE)
                            )
                          }
                        />
                      </Col>
                    </>
                  )}
                  {showPostMaterials(mod) ? (
                    MATERIALS[selectedCourse][mod]?.POST ? (
                      <>
                        <T.P weight={700} mb={2} mt={4}>
                          Post-session materials
                        </T.P>
                        {MATERIALS[selectedCourse][mod]?.POST?.map(material => (
                          <T.Link
                            external
                            to={material.link}
                            color="primary"
                            mb="2"
                          >
                            {material.displayName}
                          </T.Link>
                        ))}
                      </>
                    ) : null
                  ) : (
                    <>
                      <T.P weight={700} mb={2} mt={4}>
                        Post-session materials
                      </T.P>
                      <T.P mb={4}>
                        To access these you first need to complete the
                        post-session evaluation
                      </T.P>
                      <Col w={[4, 7, 7]} ml="-10px">
                        <Button
                          type="tertiary"
                          label="Complete post-session evaluation"
                          onClick={() =>
                            history.push(
                              SURVEY_URL.replace(
                                ':id',
                                findSurvey(mod)
                              ).replace(':type', getCoursePostSurvey(mod))
                            )
                          }
                        />
                      </Col>
                    </>
                  )}
                </S.Wrapper>
              </Col>
            </Row>
          ))
        ) : (
          <Row>
            <Col w={[4, 8, 8]}>No materials to show</Col>
          </Row>
        )}
      </>
    );
  }

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>My course materials</T.H1>
        </Col>
      </Row>
      {loading ? <Spin /> : <Content />}
    </Layout>
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail,
  responses: state.participantResponses?.participantResponses
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions,
  fetchResponses: fetchParticipantResponses
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantMaterials);
