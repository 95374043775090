import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Icon } from 'antd';
import { deleteSessionAction } from '../../../../actions/groupSessionsAction';
import {
  SessionActionsWrapper,
  SessionAction,
  SessionEdit,
  SessionDelete,
  IconName
} from './SessionActions.Style';
import { customSwal } from '../../../../theme';

class SessionActions extends Component {
  deleteSession = _id => {
    const { deleteSessionAction: deleteSession, responses } = this.props;

    if (responses && responses.length > 0) {
      return Swal.fire({
        title: 'Cannot delete this session',
        text:
          'Sorry, this session has had evaluation responses, you have to delete registered attendees first (from "Manage Attendees" section).',
        ...customSwal
      });
    }

    Swal.fire({
      title: 'Are you sure that you want to delete this session?',
      text: "You won't be able to revert this!",
      confirmButtonText: 'Delete',
      showCancelButton: true,
      ...customSwal
    })
      .then(willDelete => {
        if (willDelete.value) {
          deleteSession(_id, this.props.history);
        }
      })
      .catch(() => {
        Swal.fire({
          title: 'Oops!',
          text: 'Something error in deleting this session',
          ...customSwal
        });
      });
  };

  render() {
    const { sessionDetails, isMobile } = this.props;
    const { _id } = sessionDetails;
    const { deleteSession } = this;
    return (
      <SessionActionsWrapper>
        <SessionAction>
          <SessionEdit to={`/session-edit/${_id}`}>
            <Icon
              type="edit"
              style={{ width: '2rem', height: '2rem', color: '#08c' }}
            />
            <IconName>Edit {!isMobile && 'Session'}</IconName>
          </SessionEdit>
        </SessionAction>

        <SessionAction>
          <SessionDelete onClick={() => deleteSession(_id, this.props.history)}>
            <Icon
              type="delete"
              style={{ width: '2rem', height: '2rem', color: 'red' }}
            />
            <IconName>Delete {!isMobile && 'Session'}</IconName>
          </SessionDelete>
        </SessionAction>
      </SessionActionsWrapper>
    );
  }
}

const mapStateToProps = state => ({
  msg: state.session.msg,
  role: state.auth.role,
  isMobile: state.checkBrowserWidth.isMobile,
  responses: state.sessions.sessionDetails.responses
});

export default connect(mapStateToProps, { deleteSessionAction })(
  SessionActions
);
