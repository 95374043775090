import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  SessionTopDetailsWrapper,
  StatHeaderRow
} from './SessionDetails.Style';

import { Row, Col } from '../../common/Grid';
import * as T from '../../common/Typography';

class SessionTopDetails extends Component {
  render() {
    const { sessionDetails } = this.props;
    const {
      dates,

      course,
      trainers,

      address,
      capacity,
      modules,
      remote,
      meetingPlatform,
      meetingLink,
      extraInfo
    } = sessionDetails;
    if (!sessionDetails) {
      return <div>loading</div>;
    }

    let fullAddress = '';

    if (address) {
      const { postcode, addressLine1, addressLine2 } = address;
      if (postcode || addressLine1 || addressLine2) {
        fullAddress = [addressLine1, addressLine2, postcode]
          .filter(item => !!item)
          .join(', ');
      }
    }

    return (
      <SessionTopDetailsWrapper>
        <StatHeaderRow mb="4">
          <Col w={[1.3, 4, 6]}>
            <T.P weight={700}>Date(s)</T.P>
          </Col>
          <Col w={[1.3, 4, 4]}>
            <T.P weight={700}>Module(s)</T.P>
          </Col>
          <Col w={[1.3, 4, 2]}>
            <T.P weight={700}>Capacity</T.P>
          </Col>
        </StatHeaderRow>
        <Row mb="5">
          <Col w={[1.3, 4, 6]}>
            {dates?.length !== 0 &&
              dates?.map(
                item =>
                  item?.sessionDate && (
                    <Row>
                      <T.P color="gray" weight="400">
                        {moment(item?.sessionDate).format('DD/MM')} -{' '}
                        {item?.startTime
                          ? `${item?.startTime || 'N/A'} to ${item?.endTime ||
                              'N/A'}`
                          : 'N/A'}
                      </T.P>
                    </Row>
                  )
              )}{' '}
          </Col>

          <Col w={[1.3, 4, 4]}>
            {modules && (
              <T.P color="gray" weight="400">
                {modules
                  .sort()
                  .join(', ')
                  .replace(/module /g, '')
                  .replace('Train the Trainer', 'Trainer') || 'N/A'}
              </T.P>
            )}
          </Col>
          <Col w={[1.3, 4, 2]}>
            <T.P color="gray" weight="400">
              {capacity}
            </T.P>
          </Col>
        </Row>
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Course:</T.P>
          </Col>
          <Col w={[5, 8, 9]}>
            <T.P transform="capitalize" color="gray" weight="400">
              {course
                .replace('C5', 'Connect 5')
                .replace('YOUNG_PEOPLE', 'Connect 5 Young People') || 'N/A'}
            </T.P>
          </Col>
        </Row>
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Trainer(s):</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P transform="capitalize" color="gray" weight="400">
              {trainers[0].name} {trainers[1] && `& ${trainers[1].name}`}
            </T.P>
          </Col>
        </Row>
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Location:</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P transform="capitalize" color="gray" weight="400">
              {remote ? 'Online' : fullAddress || 'TBC'}
            </T.P>
          </Col>
        </Row>
        {remote && (
          <Row mb="4">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>Meeting platform:</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <T.P transform="capitalize" color="gray" weight="400">
                {meetingPlatform || 'N/A'}
              </T.P>
            </Col>
          </Row>
        )}
        {remote && (
          <Row mb="4">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>Meeting link:</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <a
                href={meetingLink}
                target="_blank"
                rel="noreferrer"
                style={{
                  wordBreak: 'break-all'
                }}
              >
                <T.P
                  style={{ maxWidth: '100%' }}
                  color="secondary"
                  weight="600"
                >
                  {meetingLink || 'N/A'}
                </T.P>
              </a>
            </Col>
          </Row>
        )}
        {extraInfo && (
          <Row mb="4">
            <Col w={[2, 4, 3]}>
              <T.P weight={700}>Extra info:</T.P>
            </Col>
            <Col w={[2, 8, 9]}>
              <T.P style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                {extraInfo}
              </T.P>
            </Col>
          </Row>
        )}
      </SessionTopDetailsWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    isMobile: state.checkBrowserWidth.isMobile
  };
};

export default connect(mapStateToProps)(SessionTopDetails);
