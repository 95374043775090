import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import InfoPopUp from '../../InfoPopup';

const Textarea = ({
  type = 'textarea',
  name,
  placeholder = 'Type here...',
  label,
  error,
  value,
  handleChange,
  helper,
  color = 'darkGray',
  w, // width
  disabled,
  rows,
  m, // margins
  labelWeight,
  required,
  popOver,
  ...props
}) => {
  const decideColor = () => {
    if (error) return 'error';
    return color;
  };

  const onChange = e => {
    handleChange(e.target.value, name, e);
  };

  return (
    <CS.Field w={w} disabled={disabled} {...props}>
      {label && (
        <CS.Label htmlFor={label}>
          <T.P color={color} m="0" mr="2" mb="2" weight={labelWeight || '700'}>
            {required && '*'} {label}
          </T.P>
          {popOver && (
            <InfoPopUp
              text={popOver?.text}
              title={popOver?.title}
              h="16px"
              w="16px"
              mb="2"
            />
          )}
        </CS.Label>
      )}
      <S.TextArea
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange && onChange}
        color={decideColor()}
        disabled={disabled}
        rows={rows}
        error={error}
        {...props}
      />
      {helper && (
        <T.P color={color} mt="2">
          {helper}
        </T.P>
      )}
      {error && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </CS.Field>
  );
};

export default Textarea;
