import React, { useEffect } from 'react';

// Styles
import Button from '../../common/Button';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';

import moment from 'moment';

import { SessionDetails, SectionHeadline, DetailsDiv } from './Survey.style';

const courses = {
  C5: 'Connect 5',
  YOUNG_PEOPLE: 'Connect 5 Young People'
};
const ConfirmSurvey = props => {
  // function to create a list of names from an array...
  const renderTrainerNames = array =>
    array.map((e, i) =>
      array.length > 1 && array.length - 1 === i ? `and ${e}` : `${e} `
    );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    sessionDates,
    trainerNames,
    modules,
    sectionChange,
    researchConfirm,
    course
  } = props;
  const filteredDates = sessionDates?.filter(e => e.sessionDate !== null);
  return (
    <>
      <SectionHeadline>Gwent Connect 5 Evaluation</SectionHeadline>
      <Row>
        <Col w={[4, 12, 8]}>
          <SessionDetails>
            <DetailsDiv>
              <T.P mb="4" color="gray">
                <T.P weight={700} d="inline">
                  Date(s) of Session:{' '}
                </T.P>{' '}
                {filteredDates?.length
                  ? filteredDates.map((date, i) => (
                      <T.P d="inline">
                        {moment(date.sessionDate).format('DD/MM')}{' '}
                        {i !== filteredDates.length - 1 ? '& ' : null}
                      </T.P>
                    ))
                  : 'N/A'}
              </T.P>
            </DetailsDiv>
            <DetailsDiv>
              <T.P weight={700} d="inline">
                Course: <T.P d="inline">{courses[course]}</T.P>
              </T.P>
            </DetailsDiv>
            <DetailsDiv>
              <T.P weight={700} d="inline">
                Module(s):{' '}
                <T.P d="inline"> {modules ? modules.join(' & ') : 'N/A'}</T.P>
              </T.P>
            </DetailsDiv>
            <DetailsDiv>
              <T.P weight={700} d="inline">
                Trainers:{' '}
                <T.P d="inline" transform="capitalize">
                  {renderTrainerNames(trainerNames)}
                </T.P>
              </T.P>
            </DetailsDiv>
          </SessionDetails>
          <T.P d="inline" weight={700} mb={5}>
            This evaluation form should only take a few minutes to complete.
            Your feedback is important in helping us to evaluate and improve the
            Gwent Connect 5 programme. Many thanks for completing this anonymous
            evaluation form.
          </T.P>
          <T.P d="inline">
            <T.P d="inline" weight="700">
              Important notice
            </T.P>
            : The Gwent Connect 5 training programme has been funded by Welsh
            Government and Aneurin Bevan University Health Board (ABUHB). ABUHB
            will measure the positive impact Gwent Connect 5 training has had on
            the people living and working in Gwent. For this reason all
            participants will need to complete a pre and post course evaluation
            form. Responses will be collated by ABUHB to evaluate the training.
            ABUHB will use anonymised data collected for research purposes only.
            Individuals will never be identified by their responses but you will
            be able to see your own results.
          </T.P>
          <T.P mt="3">
            Gwent Connect 5 programme may contact you to ask you to participate
            in further research, which is optional, and to inform you of any
            major changes to the training programme.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 8]}>
          <Row inner>
            <Col w={[4, 12, 12]}>
              <T.P mt="6" mb="4">
                Are you happy for your data to be used for research purposes and
                to be updated on any major changes to the programme?
              </T.P>
            </Col>
            <Col w={[4, 6, 4]}>
              <Button
                label="Yes"
                type="secondary"
                onClick={() => {
                  sectionChange({ index: 0 });
                  researchConfirm();
                }}
              />
            </Col>
            <Col w={[4, 6, 4]}>
              <Button label="No" type="primary" to={'/'} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmSurvey;
