/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPostSurveyLink } from '../../../../helpers';
import { Divider } from 'antd';

import Swal from 'sweetalert2';
import { customSwal } from '../../../../theme';

import SurveyContent from './SurveyContent';
import { sendEmails } from '../../../../actions/groupSessionsAction';
import {
  SessionSurveysWrapper,
  SessionSurveyContainer
} from './SessionSurveys.Style';
import { getAllSurveyLinks } from '../../../../helpers';
import { surveyTypesNew } from '../../../../constants';
import * as T from '../../../common/Typography';

class SessionSurveys extends Component {
  state = {};

  handleEmailing = (surveyURL, surveyType) => {
    const { sessionDetails } = this.props;
    const { sendEmails } = this.props;

    const { participantsEmails } = sessionDetails;

    if (participantsEmails.length < 1) {
      Swal.fire({
        title: 'No participants in this session',
        text:
          'You may need to add participants to be able to send the evaluation',
        type: 'info',
        confirmButtonText: 'Ok',
        ...customSwal
      });
    }

    Swal.fire({
      title:
        'Do you want to send the evaluation link to all participants by emails?',
      text:
        "click on {'Ok'} to send the evaluation link to all the participants",
      type: 'info',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      ...customSwal
    }).then(send => {
      if (send.value) {
        sendEmails(
          {
            surveyURL,
            participantsList: participantsEmails,
            surveyType
          },
          this.props.history
        );
      }
    });
  };

  render() {
    const { sessionDetails, handleDrawerOpen } = this.props;
    const { _id, shortId, modules, course } = sessionDetails;

    const links = getAllSurveyLinks(modules, shortId, course);

    return (
      <SessionSurveysWrapper>
        <SessionSurveyContainer>
          {links.map((survey, index) => {
            return (
              <SurveyContent
                subId={index}
                preSurvey={links.length > 1 && index === 0}
                type={survey}
                surveyURL={links[index]}
                id={_id}
                handleEmailing={this.handleEmailing}
                key={index}
                handleDrawerOpen={handleDrawerOpen}
              />
            );
          })}

          <Divider>
            <T.P weight={700}>Follow-up Links</T.P>
          </Divider>

          <SurveyContent
            subId={'3Months-followup'}
            surveyURL={getPostSurveyLink({
              shortId,
              surveyType: surveyTypesNew.FOLLOW_UP_3,
              course
            })}
            id={_id}
            followUp3Months
          />
          <SurveyContent
            subId={'3Months-followup'}
            surveyURL={getPostSurveyLink({
              shortId,
              surveyType: surveyTypesNew.FOLLOW_UP_6,
              course
            })}
            id={_id}
            followUp6Months
          />
        </SessionSurveyContainer>
      </SessionSurveysWrapper>
    );
  }
}

const mapStateToProps = state => ({
  emailSuccess: state.groups.emailSuccess
});

export default connect(mapStateToProps, { sendEmails })(SessionSurveys);
