import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';
import CopyLink from '../../../common/CopyLink';
import Icon from '../../../common/Icon';

import { SurveyLinkInfo, FeedbackAction } from './SessionSurveys.Style';
import { SessionTopDetailsWrapper } from '../SessionDetails.Style';

import { EVAL_RESULTS } from '../../../../constants/navigationRoutes';

import {
  surveyTypesNew,
  modules as modulesConstants
} from '../../../../constants/index';

import 'moment-timezone';
import { customSwal } from '../../../../theme';

const getSurveyType = (modules = [], preSurvey) => {
  if (preSurvey) {
    return surveyTypesNew.PRE_COURSE;
  } else if (modules?.includes(modulesConstants.TRAINER_UPSKILL)) {
    return surveyTypesNew.POST_TRAINER_UPSKILL;
  } else if (modules?.includes(modulesConstants.TRAIN_THE_TRAINER)) {
    return surveyTypesNew.POST_TRAIN_THE_TRAINER;
  } else {
    return surveyTypesNew.POST;
  }
};

class SurveyContent extends Component {
  // Fire Info pop up
  onInfoClick = () => {
    Swal.fire({
      title: 'Info',
      text:
        'Please copy and send the evaluation link to all participants. You will see the evaluation results for your session as soon as they are being submitted',
      confirmButtonText: 'Ok',
      ...customSwal
    });
  };

  // Copy the link of the survey and fire pop up for success
  onCopyClick = subId => {
    const copyText = document.getElementById(`link${subId}`);
    let range;
    let selection;
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(copyText);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(copyText);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    try {
      document.execCommand('copy');
      Swal.fire({
        title: 'Success',
        text: 'Link copied!',
        timer: 2000,
        confirmButtonText: 'Ok',
        ...customSwal
      });
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Unable to cop the Link',
        timer: 2000,
        confirmButtonText: 'Ok',
        ...customSwal
      });
    }
  };

  render() {
    const { onInfoClick, onCopyClick } = this;

    const {
      surveyURL,
      subId,
      id,
      sessionDetails,
      handleDrawerOpen,
      preSurvey,
      followUp3Months,
      followUp6Months
    } = this.props;

    const confirmedAttendeesNumber =
      sessionDetails &&
      sessionDetails.participantsEmails?.filter(
        ({ status }) => status === 'confirmed'
      ).length;

    const surveyType = getSurveyType(sessionDetails?.modules, preSurvey);
    const responseCount = sessionDetails?.responses?.filter(
      e => e.surveyType === surveyType
    ).length;

    const followUp = followUp3Months || followUp6Months;
    let title = preSurvey ? 'Pre-Course Evaluation' : 'Post-Session Evaluation';
    if (followUp3Months) title = '3 Months Follow Up';
    if (followUp6Months) title = '6 Months Follow Up';

    return (
      <SessionTopDetailsWrapper>
        {!followUp && (
          <Row mb="5" mt="4">
            <Col w={[4, 12, 12]}>
              <T.P>
                To share the surveys for this session please share the link with
                your attendees. You can send it directly via email clicking on
                "Email surveys to attendees" or schedule emails using the
                "Schedule emails" option.
              </T.P>
            </Col>
          </Row>
        )}
        <Row mb="2">
          <Col w={[4, 12, 12]} ai="center">
            <T.P weight={700} mr="2">
              {title}
            </T.P>
            <SurveyLinkInfo onClick={onInfoClick}>
              <Icon icon="info" height="16" width="16" color="primary" />
            </SurveyLinkInfo>
          </Col>
        </Row>

        <CopyLink link={surveyURL} mb="2" />

        {!followUp && (
          <>
            <Row mb="6">
              <Col w={[4, 12, 12]} ai="center">
                <T.P>
                  Responses: <b>{responseCount}</b> out of{' '}
                  <b>{confirmedAttendeesNumber}</b> attendees
                </T.P>
              </Col>
            </Row>

            <FeedbackAction
              as="div"
              to=""
              onClick={handleDrawerOpen}
              data-key="sendSurveyLinkEmail"
              data-survey-type={preSurvey ? 'pre-course' : 'post'}
              style={{ cursor: 'pointer' }}
            >
              <T.P ml="3">Email evaluation to attendees</T.P>
              <Icon
                icon="chevron"
                direction="right"
                color="primary"
                width="16"
                height="16"
              />
            </FeedbackAction>
            <FeedbackAction
              as="div"
              to=""
              onClick={handleDrawerOpen}
              data-key="scheduleTable"
              data-survey-type={preSurvey ? 'pre-course' : 'post'}
              sessionId={id}
              style={{ cursor: 'pointer' }}
            >
              <T.P ml="3">Schedule emails to attendees</T.P>
              <Icon
                icon="chevron"
                direction="right"
                color="primary"
                width="16"
                height="16"
              />
            </FeedbackAction>
            <FeedbackAction
              to={EVAL_RESULTS.replace(':id', id).replace(
                ':surveyType',
                surveyType
              )}
            >
              <T.P ml="3">View evaluation results</T.P>
              <Icon
                icon="chevron"
                direction="right"
                color="primary"
                width="16"
                height="16"
              />
            </FeedbackAction>
          </>
        )}
      </SessionTopDetailsWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    sessionDetails: state.sessions.sessionDetails,
    loading: state.session.loading
  };
};
export default connect(mapStateToProps, {})(SurveyContent);
