import Table from '.';
import Icon from '../Icon';

const dumCols = [
  { label: 'header 1', value: 'header1' },
  { label: 'header 2', value: 'header2' },
  { label: 'header 3', value: 'header3' }
];

const dumCols2 = [
  { label: 'header 1', value: 'header1' },
  { label: 'header 2', value: 'header2' },
  { label: '', value: 'header3' }
];

const dumColsWithWidth = [
  { label: 'header 1', width: [2, 6, 6], value: 'header1' },
  { label: 'header 2', value: 'header2' },
  { label: 'header 3', value: 'header3' }
];

const data = [
  { header1: { value: 'data1' }, header2: { value: 'data2' } },
  { header1: { value: 'data3' }, header2: { value: 'data4' } }
];

const dataWithComponents = [
  {
    header1: { value: 'data1' },
    header2: { value: 'data2' },
    header3: {
      value: <Icon icon="bin" width="16" height="16" color="purple" mt="1" />,
      action: () => console.log('hello, DELETE')
    },
    link: '/clicked'
  },
  {
    header1: { value: 'data3' },
    header2: { value: 'data4' },
    header3: {
      value: <Icon icon="bin" width="16" height="16" color="purple" mt="1" />,
      action: () => console.log('hello, DELETE')
    },
    link: '/clicked'
  }
];

const TableExample = () => {
  return (
    <>
      <div style={{ margin: '20px' }}>
        <h3>Table example</h3>
        <Table data={data} columns={dumCols} />
      </div>
      <div style={{ margin: '20px' }}>
        <h3>Table example (with custom widths)</h3>
        <Table data={data} columns={dumColsWithWidth} />
      </div>
      <div style={{ margin: '20px' }}>
        <h3>Table example (no data)</h3>
        <Table data={[]} columns={dumCols} />
      </div>
      <div style={{ margin: '20px' }}>
        <h3>Table example (component values + clickable rows)</h3>
        <Table data={dataWithComponents} columns={dumCols2} />
      </div>
      <div style={{ margin: '20px' }}>
        <h3>Table example (chevron)</h3>
        <Table data={data} columns={dumCols} chevron />
      </div>
      <div style={{ margin: '20px' }}>
        <h3>Table example (view)</h3>
        <Table data={data} columns={dumCols} view />
      </div>
    </>
  );
};

export default TableExample;
