import axios from 'axios';
import { message } from 'antd';
import {
  FETCH_PARTICIPANTS_SUCCESS,
  LOADING_FALSE,
  LOADING_TRUE
} from '../constants/actionTypes';

export const fetchParticipants = ({ page }) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'fetchParticipants'
  });

  axios
    .get(`/api/users/admin/participants`, {
      params: { page }
    })
    .then(({ data }) => {
      dispatch({
        type: FETCH_PARTICIPANTS_SUCCESS,
        payload: data
      });
    })

    .catch(err => {
      dispatch({
        type: LOADING_FALSE,
        payload: 'fetchParticipants'
      });

      const error =
        err.response && err.response.data && err.response.data.error;
      message.error(error || 'Something went wrong');
    })
    .finally(() => {
      dispatch({
        type: LOADING_FALSE,
        payload: 'fetchParticipants'
      });
    });
};
