import styled from 'styled-components';

export const HeaderNumber = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: black;
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
export const Wrapper = styled.div`
  flex-direction: column;
`;
