/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { colors, spacings } from '../../../theme';
import * as T from '../Typography';
import { Row, Col } from '../Grid';
import useSearchParams from './../../../hooks/useSearchParams';

// COMMON COMPONENTS
import Modal from '../modal';
import Button from '../Button';
import { CHOOSE_RESULTS_FOR_COURSE } from '../../../constants/navigationRoutes';
// STYLING
import { Wrapper, ModalStyle } from './List.style';

// NOTE: this component expects dataList to look something like this:

// const dummyTrainerList = [
//   {
//     name: 'John Doe',
//     id: '12312312423133',
//   },
//   {
//     name: 'John Doe',
//     id: '12312312423133',
//   },
//   {
//     name: 'John Doe',
//     id: '12312312423133',
//   },
// ];

class TrainerList extends Component {
  state = {
    modalOpen: false,
    selectedTrainer: null
  };

  toggleModal = dataItem => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen, selectedTrainer: dataItem });
  };

  render() {
    const { dataList, role, deleteUser, userId, searchParams } = this.props;
    const { modalOpen, selectedTrainer } = this.state;
    const localAuthoritiesAsString = localAuthorities => {
      // turn array of authorities into a string
      if (!localAuthorities || localAuthorities.length === 0) return 'N/A';
      return localAuthorities.join(', ');
    };

    const modalContent = selectedTrainer && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P weight={700} color="darkGray" mb="2" transform="capitalize">
              {selectedTrainer.name}'s info
            </T.P>
          </Col>
        </Row>
        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Organisation:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <T.P color="gray" transform="capitalize">
              {selectedTrainer.organisation?.value || 'N/A'}
            </T.P>
          </Col>
        </Row>
        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Local authorities:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <T.P color="gray" transform="capitalize">
              {localAuthoritiesAsString(selectedTrainer.localAuthorities)}
            </T.P>
          </Col>
        </Row>
        {/* render local lead if trainer  */}
        {selectedTrainer.role === 'trainer' && (
          <Row
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`
            }}
            ai="center"
          >
            <Col w={[1, 4, 4]}>
              <T.P color="gray">Local lead:</T.P>
            </Col>
            <Col w={[3, 8, 8]}>
              <T.P color="gray" transform="capitalize">
                {selectedTrainer.localLeadName || 'N/A'}
              </T.P>
            </Col>
          </Row>
        )}
        {/* render number of trainers if local lead */}
        {selectedTrainer.role === 'localLead' && role === 'admin' && (
          <Row
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`
            }}
            ai="center"
          >
            <Col w={[1, 4, 4]}>
              <T.P color="gray">No of trainers:</T.P>
            </Col>
            <Col w={[3, 8, 8]}>
              <T.P color="gray" transform="capitalize">
                {selectedTrainer.trainerCount || 'N/A'}
              </T.P>
            </Col>
          </Row>
        )}
        <Row
          style={{
            borderTop: `1px solid ${colors.midPrimary}`,
            padding: `${spacings[3]} 0`
          }}
          ai="center"
          mb="5"
        >
          <Col w={[1, 4, 4]}>
            <T.P color="gray">Email:</T.P>
          </Col>
          <Col w={[3, 8, 8]}>
            <a href={`mailto:${selectedTrainer.email}`}>
              <T.P color="gray">{selectedTrainer.email || 'N/A'}</T.P>
            </a>
          </Col>
        </Row>

        {selectedTrainer && selectedTrainer.role === 'localLead' ? (
          <>
            <Link
              to={{
                pathname: CHOOSE_RESULTS_FOR_COURSE,
                search: searchParams.toStringWithReplace({
                  target:
                    selectedTrainer._id === userId
                      ? 'myGroup'
                      : 'localLeadGroup',
                  userId: selectedTrainer._id,
                  trainerName: selectedTrainer.name
                })
              }}
            >
              <Button
                label={`View ${selectedTrainer.name}'s Group Results`}
                type="primary"
                mb="3"
              />
            </Link>

            <Link
              to={{
                pathname: CHOOSE_RESULTS_FOR_COURSE,
                search: searchParams.toStringWithReplace({
                  target: selectedTrainer._id === userId ? 'me' : 'trainer',
                  userId: selectedTrainer._id,
                  trainerName: selectedTrainer.name
                })
              }}
            >
              <Button
                label={`View ${selectedTrainer.name}'s Results`}
                type="secondary"
              />
            </Link>
          </>
        ) : (
          <Link
            to={{
              pathname: CHOOSE_RESULTS_FOR_COURSE,
              search: searchParams.toStringWithReplace({
                target: 'trainer',
                userId: selectedTrainer._id,
                trainerName: selectedTrainer.name
              })
            }}
          >
            <Button
              label={`View ${selectedTrainer.name}'s Results`}
              type="primary"
            />
          </Link>
        )}
      </div>
    );

    return (
      <Wrapper>
        <Row
          style={{
            borderBottom:
              dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
          }}
        >
          <Col w={role !== 'admin' ? [1, 4, 4] : [2, 6, 6]}>
            <T.P weight={700} color="darkGray" mb="2">
              Name
            </T.P>
          </Col>
          <Col w={role !== 'admin' ? [1, 4, 4] : [2, 6, 6]}>
            <T.P weight={700} color="darkGray" mb="2">
              Action(s)
            </T.P>
          </Col>
        </Row>
        {dataList?.length > 0 ? (
          dataList
            // TODO: this should not return null in the response / check backend query/dummy data
            .filter(i => !!i)
            .sort(({ _id }) => (_id === userId ? -1 : 1))
            .map(dataItem => (
              <Row
                key={dataItem._id}
                style={{
                  borderTop: `1px solid ${colors.midPrimary}`,
                  padding: `${spacings[3]} 0`
                }}
                ai="center"
              >
                <Col w={role !== 'admin' ? [1, 4, 4] : [2, 6, 6]}>
                  <T.P color="gray" transform="capitalize">
                    {dataItem.name} {dataItem._id === userId && '(Me)'}
                  </T.P>
                </Col>
                <Col w={role !== 'admin' ? [1, 4, 4] : [2, 6, 6]}>
                  <Button
                    type="primary"
                    width="80px"
                    label="View"
                    onClick={() => this.toggleModal(dataItem)}
                  />
                </Col>

                {role !== 'admin' && (
                  <Col w={role !== 'admin' ? [1, 4, 4] : [2, 6, 6]}>
                    <Button
                      type="secondary"
                      onClick={() => deleteUser(dataItem._id)}
                      disabled={dataItem._id === userId}
                      label="Delete"
                      width="80px"
                    />
                  </Col>
                )}
              </Row>
            ))
        ) : (
          <Row jc="center" mt="5">
            <Col w={[4, 12, 12]} jc="center">
              <T.P weight="700" color="gray" ta="center">
                No trainers found
              </T.P>
            </Col>
          </Row>
        )}
        <Modal
          visible={modalOpen}
          setModalOpen={this.toggleModal}
          extraModalStyle={ModalStyle}
        >
          {modalContent}
        </Modal>
      </Wrapper>
    );
  }
}

const TrainerListWrapper = props => {
  const searchParams = useSearchParams();
  return <TrainerList searchParams={searchParams} {...props} />;
};

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    isMobile: state.checkBrowserWidth.isMobile
  };
};

export default connect(mapStateToProps)(TrainerListWrapper);
