import { string, number, boolean, array, date, object, ref, mixed } from 'yup';
import moment from 'moment';
import * as errMsgs from './err-msgs';
import { sessionRemoteOrFace2FaceTypes } from '../constants/sessionData';
import { coursesOptions } from '../constants';
import './custom-functions';

const URLregex = /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#.-]+)*\/?(\?[a-zA-Z0-9-_.-]+=[a-zA-Z0-9-%?&=.-]+&?)?$/;

export const requiredText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredDate = date()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredStartDate = date()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const startTime = mixed().when(['endTime', 'clonedStartTime'], {
  is: (endTime, clonedStartTime) => {
    if (endTime && !clonedStartTime) return true;
    return (
      endTime &&
      moment(endTime, 'hh:mm').isBefore(moment(clonedStartTime, 'hh:mm'))
    );
  },
  then: mixed().oneOf([false], errMsgs.END_DATE_IS_BEFORE_START_DATE),
  otherwise: mixed()
    .nullable()
    .typeError(false)
});

export const optionalText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const dates = array()
  .of(
    object().shape({
      sessionDate: requiredDate,
      startTime: startTime,
      endTime: optionalText
    })
  )
  .min(1, 'errMsgs.MIN_1_DATE')
  .max(4, 'errMsgs.MAX_4_DATES')
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredPositiveNumber = number()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .min(1, errMsgs.NEGATIVE_NUMBER)
  .required(errMsgs.DEFAULT_REQUIRED);

export const email = string()
  .email(errMsgs.INVALID_EMAIL)
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const name = string()
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const password = string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[§±!@£$%^&*()+={#}:;'"|,.?\/\\[\]<>_-])[A-Za-z\d§±!@£$%^&*()-+={#}:;'"|,.?\/\\[\]<>_-]{8,}$/gm,
    errMsgs.SHORT_PASSWORD
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const passwordConfirmation = string()
  .oneOf([ref('password'), null], errMsgs.PASSWORDS_MUST_MATCH)
  .required(errMsgs.DEFAULT_REQUIRED);

export const loginPassword = string().required(errMsgs.DEFAULT_REQUIRED);

export const postcode = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(5, errMsgs.TOO_SHORT_MIN_5)
  .max(8, errMsgs.TOO_LONG_MAX_7)
  .matches(
    /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z]))))( |)[0-9][a-z]{2}\b/gi,
    errMsgs.INVALID_POSTCODE
  );

export const agreedOnTerms = boolean()
  .oneOf([true], errMsgs.SHOULD_AGREE_ON_TERMS)
  .required(errMsgs.DEFAULT_REQUIRED);

export const confirmAttendance = boolean()
  .oneOf([true], errMsgs.SHOULD_CONFIRM_YOUNG_PEOPLE_ATTENDANCE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalDate = date()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const urlRequired = string()
  .matches(URLregex, {
    message: errMsgs.INVALID_LINK
  })
  .required(errMsgs.DEFAULT_REQUIRED);

export const link = string().matches(URLregex, {
  message: errMsgs.INVALID_LINK
});

export const optionalPhoneNumber = string().when((value, schema) => {
  if (value) {
    return schema
      .phone()
      .min(9, errMsgs.INVALID_PHONE)
      .max(12, errMsgs.INVALID_PHONE)
      .typeError(errMsgs.INVALID_PHONE);
  }
  return schema.nullable();
});

export const phoneNumber = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(9, errMsgs.INVALID_PHONE)
  .max(12, errMsgs.INVALID_PHONE)
  .when((value, schema) => {
    return schema.phone().typeError(errMsgs.INVALID_PHONE);
  });

export const optionalCheckbox = boolean()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const numberField = number()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const arrayOfString = array()
  .of(string())
  .min(1, errMsgs.EMPTY_ARRAY)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const organisationOther = string().when('showOrgOther', {
  is: true,
  then: requiredText,
  otherwise: optionalText
});

const allowedSessionValues = sessionRemoteOrFace2FaceTypes.map(
  session => session.value
);
export const sessionType = string()
  .oneOf(allowedSessionValues, errMsgs.VALID_SESSION_TYPE)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

const allowedCourses = coursesOptions.map(session => session.value);
export const coursesTypes = string()
  // .oneOf(allowedCourses, errMsgs.VALID_COURSE)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const partnerTrainer = object().shape({
  key: string().required(errMsgs.DEFAULT_REQUIRED),
  label: string().required(errMsgs.DEFAULT_REQUIRED)
});

export const address = object().when('sessionType', {
  is: 'faceToFace',
  then: object().shape({
    addressLine1: string().required(errMsgs.DEFAULT_REQUIRED),
    addressLine2: string()
      .nullable()
      .typeError(errMsgs.DEFAULT_REQUIRED),
    town: string().required(errMsgs.DEFAULT_REQUIRED),
    postcode: postcode
  }),
  otherwise: object().nullable()
});

export const onlineDetails = object().when('sessionType', {
  is: 'online',
  then: object().shape({
    platform: string().required(errMsgs.DEFAULT_REQUIRED),
    link: string()
      .when(['platform'], {
        is: platform => platform === 'zoom',
        then: string().matches(/((https?):\/\/)?(zoom)+.+/, errMsgs.ZOOM_LINK)
      })
      .when(['platform'], {
        is: platform => platform === 'teams',
        then: string().matches(
          /((https?):\/\/)?(teams.microsoft)+.+/,
          errMsgs.TEAMS_LINK
        )
      })
      .when(['platform'], {
        is: platform => platform === 'google',
        then: string().matches(
          /((https?):\/\/)?(meet.google)+.+/,
          errMsgs.MEET_LINK
        )
      })
      .required(errMsgs.DEFAULT_REQUIRED)
  }),
  otherwise: object().nullable()
});
