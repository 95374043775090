import { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { Select } from 'antd';
import IconWithTextButton from '../../common/IconWithTextButton';
import {
  BasicInput,
  DatePicker,
  Dropdown,
  TimePicker,
  Radio
} from '../../common/Inputs';

import Button from '../../common/Button';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import * as S from './style';
import { Row, Col } from '../../common/Grid';

import {
  modules as modulesConstants,
  coursesOptions,
  userRoles,
  courses
} from '../../../constants';

import { SESSION_CREATED_URL } from '../../../constants/navigationRoutes';

import { fetchAllTrainers } from '../../../actions/trainerAction';
import {
  fetchLocalLeads,
  fetchLocalLeadTrainersGroup
} from '../../../actions/users';
import {
  createSessionAction,
  storeInputData,
  clearInputData,
  resetSessionState
} from '../../../actions/sessionAction';

import validate from '../../../validation/schemas/createSession';

import FaceToFaceQs from './FaceToFaceQs';
import OnlineQs from './OnlineQs';

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i += 1) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const initFormState = ({ name = '', id = '' }) => ({
  sessionType: '',
  course: '',
  modules: [],
  capacity: null,
  partnerTrainer1: { key: id, label: name },
  partnerTrainer2: { key: '', label: '' },
  address: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: ''
  },
  online: {
    platform: '',
    link: ''
  },
  extraInfo: '',
  contactEmail: '',
  validationErrors: {},
  submitAttempt: false
});

const CreateSession = ({
  id,
  role,
  currentUser,
  name,
  localLeadTrainersGroup,
  leadsAndTrainers,
  loading,
  createdSession,
  fetchAllTrainers,
  createSessionAction,
  fetchLocalLeads,
  fetchLocalLeadTrainersGroup,
  addSessionSuccess,
  resetSessionState
}) => {
  const [formState, setFormState] = useReducer(
    stateReducer,
    initFormState({ name, id })
  );
  const history = useHistory();

  const [dates, setDates] = useState([
    { id: 0, sessionDate: null, startTime: null, endTime: null, show: true },
    { id: 1, sessionDate: null, startTime: null, endTime: null },
    { id: 2, sessionDate: null, startTime: null, endTime: null },
    { id: 3, sessionDate: null, startTime: null, endTime: null }
  ]);

  const addDate = () => {
    const nextI =
      Math.max(0, ...dates.filter(e => e.show).map((e, i) => i)) + 1;
    setDates(current => [
      ...current
        .map((obj, i) => {
          if (i === nextI) {
            return { ...obj, show: true };
          }

          return obj;
        })
        .sort((a, b) => {
          return +b.show - +a.show;
        })
    ]);
  };

  const removeDate = index => {
    setDates(current => [
      ...current
        .map((obj, i) => {
          if (i === index) {
            return {
              ...obj,
              sessionDate: null,
              startTime: null,
              endTime: null,
              show: false
            };
          }

          return obj;
        })
        .sort((a, b) => {
          return +b.show - +a.show;
        })
    ]);
  };

  const {
    course,
    modules,
    capacity,
    partnerTrainer1,
    contactEmail,
    partnerTrainer2,
    address,
    online,
    extraInfo,
    sessionType,
    submitAttempt,
    validationErrors
  } = formState;

  const onSessionTypeChange = (val, name) => {
    if (val === 'online' && sessionType === 'faceToFace') {
      // clear address
      return setFormState({
        ...formState,
        [name]: val,
        address: initFormState.address
      });
    } else if (val === 'faceToFace' && sessionType === 'online') {
      // clear online
      return setFormState({
        ...formState,
        [name]: val,
        online: initFormState.online
      });
    }
    return setFormState({ ...formState, [name]: val });
  };

  const onInputChange = (val, name) => {
    setFormState({ ...formState, [name]: val });
  };

  const onDropdownChange = (val, option, name) => {
    if (!val || !option) return setFormState({ ...formState, [name]: val });
    if (['partnerTrainer1', 'partnerTrainer2'].includes(name)) {
      const { props } = option;
      return setFormState({
        ...formState,
        [name]: { key: val, label: props?.name }
      });
    }
    return setFormState({ ...formState, [name]: val });
  };

  const onAddressChange = (val, field) => {
    const { address } = formState;
    setFormState({ ...formState, address: { ...address, [field]: val } });
  };

  const onOnlineChange = (val, field, dropdownName) => {
    const name = dropdownName === 'platform' ? dropdownName : field;
    const { online } = formState;
    setFormState({
      ...formState,
      online: { ...online, [name]: val }
    });
  };

  const validateForm = () => {
    try {
      validate({
        dates: dates.filter(e => e.show),
        sessionType,
        course,
        modules,
        capacity,
        partnerTrainer: partnerTrainer1,
        address,
        online,
        extraInfo,
        contactEmail
      });

      setFormState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setFormState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dates,
    sessionType,
    course,
    modules,
    capacity,
    partnerTrainer1,
    address,
    online,
    extraInfo,
    contactEmail
  ]);

  const onFormSubmit = e => {
    e.preventDefault();
    setFormState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleCreateSession();
    }
  };

  const handleCreateSession = () => {
    setFormState({ loading: true });
    createSessionAction({
      dates,
      remote: sessionType === 'online',
      course,
      modules,
      partnerTrainer1: partnerTrainer1?.key,
      partnerTrainer2: partnerTrainer2?.key,
      address: sessionType === 'online' ? null : address,
      meetingPlatform: online?.platform,
      meetingLink: online?.link,
      contactEmail,
      extraInfo,
      capacity
    });
  };

  const renderTrainersList = partnerTrainer => {
    if (role && role === 'localLead') {
      const nonDuplicatedTrainersAndLocalLeads = [];
      const trainersAndLocalLeads = [
        ...localLeadTrainersGroup,
        ...leadsAndTrainers
      ];
      trainersAndLocalLeads.forEach(e => {
        if (
          nonDuplicatedTrainersAndLocalLeads.find(prev => prev._id === e._id)
        ) {
          return;
        }
        nonDuplicatedTrainersAndLocalLeads.push(e);
      });

      return (
        nonDuplicatedTrainersAndLocalLeads
          .filter(({ _id }) =>
            partnerTrainer?.key ? _id !== partnerTrainer.key : true
          )
          // sort the the array to get the logged in user at the first of the array
          .sort(({ _id }) => (_id === id ? -1 : 1))
          .map(({ name, _id }) => {
            return (
              <Option
                key={_id}
                value={_id}
                name={name}
                style={{ textTransform: 'capitalize' }}
              >
                {`${name[0].toUpperCase()}${name.slice(1)}`}{' '}
                {_id === id && '(Me)'}
              </Option>
            );
          })
      );
    } else if (leadsAndTrainers) {
      return leadsAndTrainers
        .filter(({ _id }) =>
          partnerTrainer?.key ? _id !== partnerTrainer.key : true
        )
        .map(({ name, _id }) => (
          <Option
            key={_id}
            value={_id}
            name={name}
            style={{ textTransform: 'capitalize' }}
          >
            {`${name[0].toUpperCase()}${name.slice(1)}`}
          </Option>
        ));
    }
    return null;
  };

  useEffect(() => {
    if (role === 'localLead') {
      fetchLocalLeadTrainersGroup(id);
      fetchLocalLeads();
    } else {
      fetchAllTrainers();
      fetchLocalLeads();
    }

    return () => resetSessionState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addSessionSuccess && createdSession.shortId) {
      history.push(SESSION_CREATED_URL.replace(':id', createdSession.shortId));
    }
  }, [addSessionSuccess, createdSession.shortId, history]);

  const allowedModules = [
    {
      label: modulesConstants.MODULE_1,
      value: modulesConstants.MODULE_1
    },
    {
      label: modulesConstants.MODULE_2,
      value: modulesConstants.MODULE_2
    },
    {
      label: modulesConstants.MODULE_3,
      value: modulesConstants.MODULE_3
    }
  ];

  if ([userRoles.admin, userRoles.superAdmin].includes(role) && course) {
    allowedModules.push(
      course === courses.C5
        ? {
            label: modulesConstants.TRAIN_THE_TRAINER,
            value: modulesConstants.TRAIN_THE_TRAINER
          }
        : {
            label: modulesConstants.TRAINER_UPSKILL,
            value: modulesConstants.TRAINER_UPSKILL
          }
    );
  }

  useEffect(() => {
    if (
      course === courses.C5 &&
      modules.includes(modulesConstants.TRAINER_UPSKILL)
    ) {
      setFormState({
        ...formState,
        modules: modules.map(m =>
          m === modulesConstants.TRAINER_UPSKILL
            ? modulesConstants.TRAIN_THE_TRAINER
            : m
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  return (
    <Layout>
      <Col w={[4, 12, 8]}>
        <T.H1 mb="7">Create New Session</T.H1>
        <S.Wrapper onSubmit={onFormSubmit}>
          {dates
            .filter(e => e.show)
            .map((date, i) => {
              const errors = validationErrors?.[`dates[${i}]`];

              return (
                <div key={date.id}>
                  <DatePicker
                    id="DatePicker"
                    onDateChange={val => {
                      setDates(current =>
                        current.map((obj, index) => {
                          if (i === index) {
                            return { ...obj, sessionDate: val };
                          }

                          return obj;
                        })
                      );
                    }}
                    name={'Session date'}
                    size="large"
                    style={{ width: '100%' }}
                    required
                    label="Session date"
                    mb="6"
                    mt="6"
                    value={date?.sessionDate}
                    error={errors?.sessionDate}
                  />

                  <TimePicker
                    onTimeChange={val =>
                      setDates(current =>
                        current.map((obj, index) => {
                          if (i === index) {
                            return { ...obj, startTime: val };
                          }

                          return obj;
                        })
                      )
                    }
                    value={date?.startTime}
                    label="Session start time"
                    name={'Session start time'}
                    mb="6"
                    error={errors?.startTime}
                    popOver={{
                      text:
                        'If your session is running across multiple dates, select the general daily start and end time and then add further details in the extra information section below'
                    }}
                  />
                  <TimePicker
                    onTimeChange={val =>
                      setDates(current =>
                        current.map((obj, index) => {
                          if (i === index) {
                            return { ...obj, endTime: val };
                          }

                          return obj;
                        })
                      )
                    }
                    value={date?.endTime}
                    label="Session finish time"
                    name={'Session finish time'}
                    error={errors?.endTime}
                    mb="3"
                  />

                  {role === userRoles.admin && (
                    <IconWithTextButton
                      label="Remove date"
                      onClick={() => removeDate(i)}
                      icon="minus"
                      mb="4"
                      color="error"
                      disabled={dates.filter(e => e.show).length === 1}
                    />
                  )}
                </div>
              );
            })}

          {role === userRoles.admin && dates.filter(e => e.show).length < 4 && (
            <IconWithTextButton
              label="Add another date"
              onClick={addDate}
              icon="plus"
              mb="4"
              color="primary"
            />
          )}

          {/* PUT IN RADIO QUESTION HERE */}
          {/* ADDRESS AND ONLINE FIELDS HERE */}

          <Radio
            selected
            label="How are you delivering this session?"
            required
            mt="6"
            name="sessionType"
            error={validationErrors.sessionType}
            handleChange={onSessionTypeChange}
            value={sessionType}
            options={[
              { label: 'Remote / online', value: 'online' },
              { label: 'Face to Face', value: 'faceToFace' }
            ]}
          />

          {sessionType === 'faceToFace' && (
            <FaceToFaceQs
              address={address}
              extraInfo={extraInfo}
              onAddressChange={onAddressChange}
              onInputChange={onInputChange}
              error={validationErrors.address}
              extraInfoError={validationErrors.extraInfo}
            />
          )}

          {sessionType === 'online' && (
            <OnlineQs
              online={online}
              extraInfo={extraInfo}
              onOnlineChange={onOnlineChange}
              onInputChange={onInputChange}
              error={validationErrors.online}
              extraInfoError={validationErrors.extraInfo}
            />
          )}

          <Dropdown
            selected={course}
            m={{ mb: '6' }}
            label="Which course are you delivering?"
            placeholder="Select..."
            required
            options={coursesOptions}
            name="course"
            handleChange={onDropdownChange}
            error={validationErrors.course}
          />

          <Dropdown
            selected={modules}
            m={{ mb: '6' }}
            label="Which modules are you delivering in this session?"
            placeholder="Select as many as you like"
            required
            multi
            options={allowedModules}
            name="modules"
            handleChange={onDropdownChange}
            error={validationErrors.modules}
            disabled={!course}
          />

          <BasicInput
            value={capacity}
            handleChange={onInputChange}
            mb="6"
            label="Maximum capacity"
            required
            name="capacity"
            type="number"
            popOver={{
              text:
                'This is the total amount of people allowed to attend the session'
            }}
            min={1}
            error={validationErrors.capacity}
          />

          {['localLead', 'admin'].includes(role) && (
            <Dropdown
              selected={partnerTrainer1.label}
              m={{ mb: '6' }}
              label="Trainer"
              placeholder="Select"
              required
              options={renderTrainersList(partnerTrainer2)}
              customOptions
              name="partnerTrainer1"
              handleChange={onDropdownChange}
              error={validationErrors.partnerTrainer?.key}
            />
          )}

          <BasicInput
            value={contactEmail}
            handleChange={onInputChange}
            mb="6"
            label="Trainer contact email"
            required
            name="contactEmail"
            popOver={{
              text:
                'This is the email address that will be shared with those who register to attend this session'
            }}
            error={validationErrors.contactEmail}
          />

          <Dropdown
            selected={partnerTrainer2?.label}
            m={{ mb: '6' }}
            label="Second / partner trainer"
            placeholder="Select"
            options={renderTrainersList(partnerTrainer1)}
            customOptions
            name="partnerTrainer2"
            handleChange={onDropdownChange}
            allowClear
          />
          {Object.keys(validationErrors)?.length > 0 && (
            <T.P mb="4" mt="2" color="error">
              There are errors, please check above.
            </T.P>
          )}
          <Row inner>
            <Col w={[4, 8, 8]}>
              <Button
                onClick={onFormSubmit}
                type="primary"
                label="Submit"
                loading={loading}
                disabled={loading}
                ml={0}
              />
            </Col>
          </Row>
        </S.Wrapper>
      </Col>
    </Layout>
  );
};

const mapStateToProps = state => {
  const { trainers } = state.trainers;
  const localLeads = state.fetchedData.localLeadsList;
  const leadsAndTrainers = [...localLeads, ...trainers];
  return {
    id: state.auth.userId,
    role: state.auth.role,
    currentUser: state.auth,
    name: state.auth.name,
    localLeadTrainersGroup: state.fetchedData.localLeadGroup,
    leadsAndTrainers,
    loading: state.session.loading,
    addSessionSuccess: state.session.addSessionSuccess,
    createdSession: state.session
  };
};

export default connect(mapStateToProps, {
  fetchAllTrainers,
  createSessionAction,
  fetchLocalLeads,
  fetchLocalLeadTrainersGroup,
  storeInputData,
  clearInputData,
  resetSessionState
})(CreateSession);
