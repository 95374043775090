import React from 'react';

import { connect } from 'react-redux';
import FilterResults from '../FilterResults';

const BehavioralTrainerResults = ({
  defaultFilters,
  hiddenFields,
  role,
  showFilters
}) => {
  return (
    <>
      <>
        {showFilters && (
          <FilterResults
            role={role}
            defaultFilters={defaultFilters}
            hiddenFields={hiddenFields}
            exportText={{
              active: 'Export Filtered Behavioral Insights',
              inactive: 'Export Behavioral Insights'
            }}
            hideApplyFiltersButton
            target="behavioural"
            isOpenedByDefault
          />
        )}
      </>
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  filters: state.filters
});

export default connect(mapStateToProps)(BehavioralTrainerResults);
