import { useState } from 'react';

import { connect } from 'react-redux';
import * as T from './../../common/Typography';

import * as actions from '../../../actions/authAction';

import Layout from './../../Layouts';
import { Col, Row } from './../../common/Grid';

import Button from '../../common/Button';

const DecideRole = ({ userRoles, selectRole }) => {
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  const handleClick = async role => {
    setLoading(true);
    setSelectedRole(role);
    await selectRole(role);
    setLoading(false);
  };

  const colors = ['tertiary', 'secondary', 'primary'];
  const readableRoles = {
    admin: 'Admin',
    localLead: 'Local lead',
    trainer: 'Trainer',
    participant: 'Participant',
    superAdmin: 'Super admin'
  };

  return (
    <Layout>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H1
            style={{
              width: '100%'
            }}
          >
            Log in
          </T.H1>
          <T.P mt={7}>Which of your accounts would you like to use?</T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 4]}>
          {userRoles.map((role, i) => (
            <Button
              label={readableRoles[role]}
              type={colors[i % 3]}
              mt={4}
              onClick={() => handleClick(role)}
              loading={loading && role === selectedRole}
            />
          ))}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    userRoles: state.auth.userRoles
  };
};

export default connect(mapStateToProps, { selectRole: actions.selectRole })(
  DecideRole
);
