import { useEffect, useState } from 'react';
import axios from 'axios';
import download from 'downloadjs';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { message } from 'antd';
import Modal from '../../common/modal';
import Icon from '../../common/Icon';
import { TextArea } from '../../common/Inputs';

import {
  fetchParticipantSessionDetails,
  removeRegistration as removeParticipantRegistrationAction,
  resetRemoveRegistration as resetRemoveRegistrationAction
} from '../../../actions/participantActions';

import * as T from '../../common/Typography';
import * as S from './style';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';

import Layout from '../../Layouts';
import {
  PARTICIPANT_MATERIALS,
  SURVEY_URL
} from '../../../constants/navigationRoutes';

import { surveyTypesNew, modules } from '../../../constants/index';

const ParticipantSessionDetails = ({
  session = {},
  responses = [],
  fetchSessionDetails,
  loaded,
  email,
  removeRegistration,
  removeRegistrationSuccess,
  removeRegistrationError,
  removeRegistrationLoading,
  participantRemoveRegistration,
  resetRemoveRegistration
}) => {
  const [completedSession, setCompletedSession] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [removalReason, setRemovalReason] = useState('');

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    fetchSessionDetails(id);

    return () => {
      resetRemoveRegistration();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responses.length > 0) {
      const postEval = responses.filter(
        resp =>
          resp.surveyType === surveyTypesNew.POST ||
          resp.surveyType === surveyTypesNew.POST_TRAIN_THE_TRAINER ||
          resp.surveyType === surveyTypesNew.POST_TRAINER_UPSKILL
      );
      setCompletedSession(postEval.length > 0);
    }
  }, [responses]);

  const trainersNames = trainers => {
    if (!trainers) return 'N/A';
    return session.trainers.map(trainer => trainer.name).join(' & ');
  };

  const modulesAsString = session?.modules
    ? session.modules.map(module => module).join(' & ')
    : 'N/A';

  const fullAddress = address => {
    if (!address) return 'N/A';
    const { addressLine1, addressLine2, town, postcode } = address;

    return [addressLine1, addressLine2, town, postcode]
      .filter(item => !!item)
      .join(', ');
  };

  const handleClick = mod => {
    const fileName = `module-${mod}-${Date.now()}.pdf`;
    const data = {
      email,
      module: mod,
      sendEmail: true
    };
    setDownloading(true);
    axios
      .post(`/api/certificate/${session._id}`, data, {
        responseType: 'blob'
      })
      .then(res => {
        const content = res.headers['content-type'];
        download(res.data, fileName, content);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        message.error('Error! something went wrong');
      });
  };

  let survey = surveyTypesNew.POST;
  if (session?.modules?.includes(modules.TRAIN_THE_TRAINER)) {
    survey = surveyTypesNew.POST_TRAIN_THE_TRAINER;
  }
  if (session?.modules?.includes(modules.TRAINER_UPSKILL)) {
    survey = surveyTypesNew.POST_TRAINER_UPSKILL;
  }

  useEffect(() => {
    if (removeRegistrationSuccess && !removeRegistrationLoading) {
      message.success('Successfully sent removal request');
      setModalVisible(false);
    }
  }, [removeRegistrationSuccess, removeRegistrationLoading]);

  if (!loaded) return <div>Loading</div>;

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>Session Details</T.H1>
        </Col>
      </Row>
      {!completedSession && (
        <>
          <Row mt={4} mb={4}>
            <Col w={[4, 8, 8]} direction="column" ai="flex-start">
              <T.P weight={700} mb={2}>
                Certificate
              </T.P>
              <T.P>
                To complete this session and receive your certificate(s) you
                first need to complete this post-session evaluation.
              </T.P>
            </Col>
          </Row>
          <Row>
            <Col w={[4, 5, 5]}>
              <Button
                type="tertiary"
                label="Complete post-session evaluation"
                onClick={() =>
                  history.push(
                    SURVEY_URL.replace(':id', session.shortId).replace(
                      ':type',
                      survey
                    )
                  )
                }
              />
            </Col>
          </Row>
        </>
      )}
      <Row mt={8} mb={7}>
        <Col w={[4, 8, 8]}>
          <S.Wrapper>
            <T.P mb="4" color="gray">
              <S.Bold>Date(s) of Session: </S.Bold>{' '}
              {session?.dates?.length
                ? session?.dates
                    ?.filter(e => !!e.sessionDate)
                    .map(date => (
                      <T.P color="gray" weight="400">
                        {moment(date.sessionDate).format('DD/MM')} -{' '}
                        {date.startTime
                          ? `${date.startTime || 'N/A'} to ${date.endTime ||
                              'N/A'}`
                          : 'N/A \n'}
                      </T.P>
                    ))
                : 'N/A'}
            </T.P>
            {session?.startTime && (
              <T.P mb="4" color="gray">
                <S.Bold>Time: </S.Bold> {session.startTime}
                {session?.endTime && ` to ${session.endTime}`}
              </T.P>
            )}
            {session?.course && (
              <T.P mb="4" color="gray">
                <S.Bold>Course: </S.Bold>{' '}
                {session.course
                  .replace('C5', 'Connect 5')
                  .replace('YOUNG_PEOPLE', 'Connect 5 Young People')}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Modules:</S.Bold> {modulesAsString}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Location:</S.Bold>{' '}
              {session.remote ? 'Online' : fullAddress(session.address)}
            </T.P>
            <T.P mb="4" color="gray" transform="capitalize">
              <S.Bold>Trainers:</S.Bold> {trainersNames(session.trainers)}
            </T.P>{' '}
            {session?.remote && (
              <T.P mb="4" color="gray" style={{ wordBreak: 'break-all' }}>
                <S.Bold style={{ display: 'inline' }}>Meeting link:</S.Bold>
                {session?.meetingLink ? (
                  <T.Link external href={session?.meetingLink} color="primary">
                    {' '}
                    {session?.meetingLink}
                  </T.Link>
                ) : (
                  ' N/A'
                )}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Email: </S.Bold>
              {session?.contactEmail ? (
                <T.Link
                  external
                  href={`mailto:${session?.contactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  {session?.contactEmail}
                </T.Link>
              ) : (
                ' N/A'
              )}
            </T.P>
            <T.P color="gray">
              <S.Bold style={{ display: 'block' }}>
                {' '}
                Any other joining information:
              </S.Bold>
              {session?.extraInfo || 'N/A'}
            </T.P>
          </S.Wrapper>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <Button
            label="Materials"
            onClick={() =>
              history.push(
                PARTICIPANT_MATERIALS.replace(':course', session.course)
              )
            }
            type="primary"
            mb={3}
          />
          {completedSession &&
            session.modules.map(module => (
              <Button
                label={`${
                  module === modules.TRAIN_THE_TRAINER ||
                  module === modules.TRAINER_UPSKILL
                    ? ''
                    : 'Module '
                }${module} Certificate`}
                onClick={() => handleClick(module)}
                type="secondary"
                mb={3}
                disabled={downloading}
              />
            ))}

          {removeRegistrationError && (
            <T.P color="error">{removeRegistrationError}</T.P>
          )}
          <S.RemoveButton onClick={() => setModalVisible(true)} type="link">
            <Icon icon="cross" width="14" height="14" mr="1" color="gray" />
            <T.Link
              color="gray"
              style={{
                display: 'flex'
              }}
            >
              Request to remove your registration
            </T.Link>
          </S.RemoveButton>

          <Modal
            visible={modalVisible}
            setModalOpen={setModalVisible}
            loading={removeRegistrationLoading}
          >
            <Row mb={4}>
              <Col w={[4, 12, 12]}>
                <T.P weight={700} mb="2">
                  Are you sure you want to request to remove your registration?
                </T.P>
                <T.P>
                  If your trainer approves your request, you will be removed
                  from the session and you will no longer be able to access the
                  session materials.
                </T.P>
              </Col>
              <Col w={[4, 12, 12]}>
                <TextArea
                  label="Please provide a reason for your request"
                  onChange={e => setRemovalReason(e.target.value)}
                  value={removalReason}
                  rows={4}
                  mb={4}
                  mt={4}
                />
              </Col>
            </Row>

            <Row>
              <Col w={[4, 12, 6]}>
                <Button
                  type="primary"
                  onClick={() => setModalVisible(false)}
                  label="Back"
                />
              </Col>

              <Col w={[4, 12, 6]}>
                <Button
                  type="secondary"
                  onClick={() => removeRegistration(session._id, removalReason)}
                  label="Confirm"
                  loading={removeRegistrationLoading}
                  disabled={!removalReason}
                />
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  session: state.participantSession?.participantSessionDetails?.sessionDetails,
  responses:
    state.participantSession?.participantSessionDetails?.participantResponses,
  loaded: state.participantSession.loaded,
  email: state.auth.email,
  removeRegistrationSuccess: state.participantRemoveRegistration.success,
  removeRegistrationError: state.participantRemoveRegistration.error,
  removeRegistrationLoading: state.participantRemoveRegistration.loading,
  participantRemoveRegistration: state.participantRemoveRegistration
});

const mapActionsToProps = {
  fetchSessionDetails: fetchParticipantSessionDetails,
  removeRegistration: removeParticipantRegistrationAction,
  resetRemoveRegistration: resetRemoveRegistrationAction
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantSessionDetails);
