import React, { useState, useEffect } from 'react';
import Layout from '../../Layouts';
import { Pagination } from 'antd';
import { fetchParticipants } from '../../../actions/fetchParticipants';
import ParticipantList from '../../common/List/ParticipantList';
import { connect } from 'react-redux';
import { HeaderNumber, PaginationWrapper, Wrapper } from './style';
import * as T from '../../common/Typography';
import ExportButton from '../../common/ExportButton';

const Participants = ({
  participantCount,
  fetchParticipants,
  loading,
  participantList
}) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchParticipants({ page });
  }, [fetchParticipants, page]);

  return (
    <Layout>
      <Wrapper>
        <T.H1 mb={5}>Participants</T.H1>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '100%'
            }}
          >
            <T.P weight={700} color="black" mt="5">
              Total Participants:
            </T.P>
            <HeaderNumber>{participantCount}</HeaderNumber>
          </div>
          <ExportButton
            type="tertiary"
            height="50px"
            text="Export Participants"
            exportType="participants"
            width="200px"
          />
        </div>
        <ParticipantList dataList={participantList} />
        <PaginationWrapper>
          <Pagination
            simple
            total={participantCount}
            onChange={setPage}
            disabled={loading}
            defaultCurrent={1}
            current={page}
            pageSize={50}
          />
        </PaginationWrapper>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  loading: state.loading.fetchParticipants,
  participantCount: state.participants.participantCount,
  participantList: state.participants.participantList
});

export default connect(mapStateToProps, {
  fetchParticipants
})(Participants);
