import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { userRoles } from '../../../constants';

import { MY_ACCOUNT_URL } from '../../../constants/navigationRoutes';
import Modal from './../../common/modal';

const UpdateAccountPrompt = ({ userId, name, role }) => {
  const history = useHistory();
  const location = useLocation();
  const [modalVisible, setModalVisible] = useState(
    () =>
      userId &&
      !name &&
      role === userRoles.participant &&
      location.pathname !== MY_ACCOUNT_URL
  );

  useEffect(() => {
    if (
      userId &&
      !name &&
      role === userRoles.participant &&
      location.pathname !== MY_ACCOUNT_URL &&
      !modalVisible
    ) {
      setModalVisible(true);
    }
  }, [modalVisible, location.pathname, name, role, userId]);

  return (
    <Modal
      type="confirm"
      visible={modalVisible}
      setModalOpen={setModalVisible}
      title="Details Missing"
      parentFunc={() => history.push(MY_ACCOUNT_URL)}
      withoutCancel
      confirmText="Update my account"
      text="We have noticed there is a name missing in your account details, which will affect your use of this app. Please click below to update your account details."
    />
  );
};

const mapStateToProps = state => ({
  name: state.auth.name,
  role: state.auth.role,
  userId: state.auth.userId
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(UpdateAccountPrompt);
