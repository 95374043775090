// media queries
// so far we only decided to use 1 breakpoint, 768px (tablet)
export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '680px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const breakpoints = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

export const breakpointsMax = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};

export const colors = {
  primary: '#31228E',
  midPrimary: '#EEEBF0',
  lightPrimary: '#F5F5F7',

  secondary: '#006370',
  lightSecondary: '#F0F4F5',

  error: '#B70D00',
  darkGray: '#26252F',
  gray: '#5A5961',
  gray9: '#3C404B',

  offWhite: '#FCFCFC',
  white: '#FFFFFF'
};

export const spacings = {
  0: '0px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '24px',
  6: '32px',
  '40': '40px',
  7: '48px',
  8: '64px',
  9: '80px',
  10: '96px',
  11: '120px'
};

export const layoutConstants = {
  headerHeight: spacings[0],
  headerHeightM: spacings[7],

  topMargin: spacings[10],
  leftMargin: '6.6%', // = 96px on 1440px
  bottomMargin: spacings[11],
  rightMargin: '6.6%', // = 96px on 1440px
  // Mobile
  topMarginM: spacings[8],
  leftMarginM: '6.6%', // = 96px on 1440px
  bottomMarginM: spacings[8],
  rightMarginM: '6.6%' // = 96px on 1440px
};

export const gridConstants = {
  columns: { mobile: 4, tablet: 12, desktop: 12 },
  gridGutter: { mobile: 8, tablet: 16, desktop: 24 }
};

// shadows
export const shadows = {
  primary: '0px 1px 4px rgba(0, 0, 0, 0.05)',
  secondary: '0px 3px 6px rgba(0, 0, 0, 0.07)'
};

// borders
export const borders = {
  error: `1px solid ${colors.error}`,
  inputBox: `1px solid ${colors.gray}`,
  button: `1px solid ${colors.gray}`,
  header: `1px solid ${colors.offWhite}`,
  toggle: `1px solid ${colors.black}`,
  heading: `1px solid ${colors.yellow}`,
  inputs: `1px solid ${colors.midPrimary};`,
  radius: '4px',
  radiusLarge: '12px'
};

// sweet alert customisation
export const customSwal = {
  background: colors.midPrimary,
  confirmButtonColor: colors.darkGray,
  denyButtonColor: colors.secondary,
  cancelButtonColor: colors.secondary,
  showClass: {
    popup: '', // disable popup animation
    icon: '' // disable icon animation
  },
  hideClass: {
    popup: '' // disable popup fade-out animation
  },
  customClass: {
    container: 'custom_swal_container',
    title: 'custom_swal_title',
    popup: 'custom_swal_popup',
    htmlContainer: 'custom_swal_title',
    confirmButton: 'custom_swal_button',
    cancelButton: 'custom_swal_button',
    denyButton: 'custom_swal_button',
    actions: 'custom_swal_actions'
  }
};

export default {
  size,
  breakpoints,
  breakpointsMax,
  colors,
  spacings,
  shadows,
  borders,
  layoutConstants,
  gridConstants,
  customSwal
};
