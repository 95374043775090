import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors, shadows, breakpointsMax } from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TopSection = styled.div`
  margin-bottom: 4rem;
  width: 100%;
`;

export const StatsWrapper = styled.div`
  background-color: ${colors.lightPrimary};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  box-shadow: ${shadows.primary};
  margin-bottom: 1rem;
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
  max-width: 500px;
  border-radius: 12px;
  @media ${breakpointsMax.mobileL} {
    padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[0]}`};
  }
`;

export const StatItem = styled(Link)`
  text-decoration: none;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  justify-content: center;

  color: ${colors.gray};
  padding: 2rem min(8vw, 32px);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    background-color: ${colors.offWhite};
  }

  @media ${breakpointsMax.mobileL} {
    padding: 0.75rem min(8vw, 32px);
    &:hover {
      transform: scale(1);
      background-color: transparent;
    }
  }
`;

export const SpinWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;
