import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import {
  fetchTrainerSessions,
  fetchLocalLeadSessions
} from '../../../actions/groupSessionsAction';

import { Row, Col } from '../../common/Grid';
import * as T from '../../common/Typography';
import PublicSessionList from '../../common/List/PublicSessionList';
import USER_TYPES from '../../../constants/userTypes';

import Layout from '../../Layouts';
import moment from 'moment';
const useQuery = () => new URLSearchParams(useLocation().search);

const TrainerSessions = ({
  sessions,
  fetchTrainerSessions,
  fetchLocalLeadSessions,
  isMobile,
  role,
  history
}) => {
  const { id } = useParams();
  const query = useQuery();
  const group = query.get('group');
  const [availableSessions, setAvailableSessions] = useState([]);

  useEffect(() => {
    if (group === 'true') {
      fetchLocalLeadSessions({ id, history });
    } else {
      fetchTrainerSessions({ id, history });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (sessions?.length > 0) {
      const spaces = sessions
        .filter(
          session =>
            session.capacity > session.participantsEmails.length &&
            moment(
              session.dates?.sort(
                (a, b) => new Date(b.sessionDate) - new Date(a.sessionDate)
              )[0]?.sessionDate
            ).isSameOrAfter(moment())
        )
        .sort((a, b) => {
          // sort by date
          return moment(
            a.dates?.sort(
              (a, b) => new Date(b.sessionDate) - new Date(a.sessionDate)
            )[0]?.sessionDate
          ).isSameOrAfter(
            moment(
              b.dates?.sort(
                (a, b) => new Date(b.sessionDate) - new Date(a.sessionDate)
              )[0]?.sessionDate
            )
          )
            ? 1
            : -1;
        });
      setAvailableSessions(spaces);
    }
  }, [sessions]);

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]} direction="column" ai="flex-start">
          <T.H1 mb={5}>Available sessions</T.H1>
          <T.P>
            Please click to register on any session you wish to attend. Remember
            you must complete the modules in order, so please do not attend a
            session teaching module 2 or 3 before you’ve done module 1.
          </T.P>
        </Col>
      </Row>
      <Row>
        {availableSessions?.length > 0 ? (
          <PublicSessionList
            dataList={availableSessions}
            linkText={
              !isMobile &&
              (role === USER_TYPES.trainer ||
              role === USER_TYPES.localLead ||
              role === USER_TYPES.admin
                ? 'View'
                : 'View & register')
            }
          />
        ) : (
          <Col w={[4, 8, 8]}>
            <T.P weight={700}>
              No sessions currently available. Check back soon.
            </T.P>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    sessions: state.sessions.sessions,
    isMobile: state.checkBrowserWidth.isMobile,
    role: state.auth.role
  };
};

const mapActionsToProps = {
  fetchTrainerSessions,
  fetchLocalLeadSessions
};

export default connect(mapStateToProps, mapActionsToProps)(TrainerSessions);
