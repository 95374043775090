import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import SessionList from '../../common/List/SessionList';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Layout from '../../Layouts';
import {
  PARTICIPANT_SESSIONS_LIST,
  TRAINER_TTT_SIGN_UP,
  CHOOSE_RESULTS_FOR_MATERIALS
} from '../../../constants/navigationRoutes';
import { upgradeTttSteps } from '../../../constants';

const ParticipantDashboard = ({
  sessions,
  fetchSessions,
  nextUpgradeToTttStep
}) => {
  const history = useHistory();

  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldFinishTttSignup =
    nextUpgradeToTttStep?.step === upgradeTttSteps.signupAsTtt;
  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H1>Welcome back</T.H1>
        </Col>
      </Row>
      {shouldFinishTttSignup && (
        <Row mb={7}>
          <Col w={[0.4, 0.4, 0.4]}>
            <Icon icon="warning" />
          </Col>
          <Col w={[3.6, 7, 7]}>
            <T.P mb={3}>
              You have completed your training to become a trainer, but have not
              finished signing up to get your certification.
            </T.P>
            <T.Link
              color="secondary"
              underline
              to={{
                pathname: TRAINER_TTT_SIGN_UP,
                state: { course: nextUpgradeToTttStep?.course }
              }}
            >
              Click here to sign up
            </T.Link>
          </Col>
        </Row>
      )}
      <Row mb={7}>
        <Col w={[4, 8, 8]} dir="column" ai="flex-start">
          <T.P weight={700} mb={3}>
            My upcoming sessions
          </T.P>
          <SessionList
            dataList={sessions?.filter(ses =>
              moment(
                ses.dates?.sort(
                  (a, b) => new Date(b.sessionDate) - new Date(a.sessionDate)
                )[0]?.sessionDate
              ).isSameOrAfter()
            )}
            isParticipant
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 4]}>
          <T.P weight={700} mb={4}>
            My course progress
          </T.P>
          <Button
            label="Sessions"
            onClick={() => history.push(PARTICIPANT_SESSIONS_LIST)}
            type="tertiary"
            mb={3}
          />
          <Button
            label="Materials"
            onClick={() => history.push(CHOOSE_RESULTS_FOR_MATERIALS)}
            type="primary"
            mb={3}
          />
          {/* <Button
            label="Insights"
            onClick={() => history.push(USER_INSIGHTS)}
            type="secondary"
            mb={3}
          /> */}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail,
  nextUpgradeToTttStep: state.auth.nextUpgradeToTttStep
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantDashboard);
