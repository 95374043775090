import React from 'react';
import moment from 'moment';
import { Icon as AntdIcon } from 'antd';
import Spin from '../Spin';

import { colors, spacings } from '../../../theme';
import * as T from '../Typography';
import Icon from '../Icon';

import { Wrapper, StyledLink, WarningWrapper } from './List.style';

import { Row, Col } from '../Grid';

import {
  SESSION_DETAILS_URL,
  PARTICIPANT_SESSION_DETAILS
} from '../../../constants/navigationRoutes';

// NOTE: this component expects dataList to look something like this:

const SessionList = ({ dataList, isParticipant, linkText }) => {
  const baseURL = isParticipant
    ? PARTICIPANT_SESSION_DETAILS
    : SESSION_DETAILS_URL;

  return (
    <Wrapper>
      <Row
        style={{
          borderBottom:
            dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
        }}
      >
        <Col w={[1, 4, 4]}>
          <T.P weight={700} color="darkGray" mb="2">
            Date(s)
          </T.P>
        </Col>
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Module(s)
          </T.P>
        </Col>
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Course
          </T.P>
        </Col>
        <Col w={[1, 2, 2]}>
          <T.P weight={700} color="darkGray" mb="2">
            Details
          </T.P>
        </Col>
      </Row>
      {!dataList || !Array.isArray(dataList) ? (
        <div style={{ width: '100%', height: '400px' }}>
          <Spin />
        </div>
      ) : (
        <>
          {dataList?.length > 0 ? (
            dataList.map(dataItem => {
              const datesArr = dataItem?.dates?.filter(e => !!e.sessionDate);
              return (
                <Row
                  key={dataItem.id}
                  href={dataItem.link || undefined}
                  to={dataItem.link || baseURL.replace(':id', dataItem._id)}
                  target={dataItem.blank ? '_blank' : '_self'}
                  style={{
                    borderTop: `1px solid ${colors.midPrimary}`,
                    padding: `${spacings[3]} 0`
                  }}
                >
                  {' '}
                  <WarningWrapper>
                    {!!dataItem.participantsDidNotAttendPrevMods?.length && (
                      <Icon icon="warning" />
                    )}
                  </WarningWrapper>
                  {datesArr?.length && (
                    <Col w={[1, 4, 4]}>
                      {datesArr?.map((date, i) => {
                        if (i === datesArr?.length - 1) {
                          return (
                            <T.P color="gray">
                              {moment(date.sessionDate).format('DD/MM/YYYY')}
                            </T.P>
                          );
                        } else {
                          return (
                            <>
                              <T.P color="gray">
                                {moment(date.sessionDate).format('DD/MM')}
                              </T.P>
                              <T.P color="secondary" weight="900">
                                ,&nbsp;
                              </T.P>
                            </>
                          );
                        }
                      })}
                    </Col>
                  )}
                  <Col w={[1, 3, 3]}>
                    <T.P color="gray">
                      {dataItem?.modules
                        .sort()
                        .join(', ')
                        .replace(/module /g, '')
                        .replace('Train the Trainer', 'Trainer') || 'N/A'}
                    </T.P>
                  </Col>
                  <Col w={[1, 3, 3]}>
                    <T.P color="gray">
                      {' '}
                      {dataItem?.course
                        .replace('C5', 'Connect 5')
                        .replace('YOUNG_PEOPLE', 'Young People') || 'N/A'}
                    </T.P>
                  </Col>
                  <Col w={[1, 2, 2]}>
                    <StyledLink
                      as={dataItem.asLink ? 'a' : undefined}
                      href={dataItem.link || undefined}
                      to={dataItem.link || baseURL.replace(':id', dataItem._id)}
                      target={dataItem.blank ? '_blank' : '_self'}
                    >
                      {dataItem.linkText || linkText || (
                        <AntdIcon type="right" />
                      )}
                    </StyledLink>
                  </Col>
                </Row>
              );
            })
          ) : (
            <Row jc="center" mt="5">
              <Col w={[4, 12, 12]} jc="center">
                <T.P weight="700" color="gray" ta="center">
                  No data found
                </T.P>
              </Col>
            </Row>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default SessionList;
