import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, borders } from '../../../theme';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  background-color: ${colors.lightPrimary};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[6]};
  margin-bottom: 5.5rem;
  max-width: 600px;
  border-radius: 12px;
  width: 100%;
`;
