const Minus = ({ width, height, color, ...props }) => (
  <svg
    width={width || '10'}
    height={height || '2'}
    viewBox="0 0 10 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="1"
      y1="1"
      x2="9"
      y2="1"
      stroke={color || '#B70D00'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Minus;
