import React, { useEffect } from 'react';
import Table from './../Table';
import { message } from 'antd';
import * as T from '../Typography';

import FilterResults from '../FilterResults';

const sessionsColumns = [
  {
    label: 'Modules',
    value: 'module',
    align: 'left'
  },
  {
    label: 'Sessions',
    value: 'sessions',
    align: 'center'
  },
  {
    label: 'Participants',
    value: 'confirmedParticipants',
    align: 'center',
    toolTip:
      'Total amount of times people have attended a session delivering this module'
  }
];

const surveysColumns = [
  {
    label: 'Type',
    value: 'title',
    align: 'left'
  },
  {
    label: '# of Responses',
    value: 'responses',
    align: 'center'
  },
  {
    label: 'Response Rate',
    value: 'responseRate',
    align: 'center',
    toolTip:
      'Percentage % of unique participants required to fill in an evaluation form for this module'
  }
];

const Reach = ({
  data,
  role,
  handleFilteredData,
  defaultFilters,
  hiddenFields
}) => {
  // check if any response rates are over 100% and if so give the user a message
  const over100 =
    data.newSurveys &&
    data.newSurveys.filter(survey => {
      const responseRate = survey.responseRate.split('%')[0];
      return responseRate > 100;
    });

  useEffect(() => {
    if (over100?.length > 0) {
      message.warning(
        <>
          <h3 style={{ fontSize: '1rem' }}>
            You have surveys with a response rate over 100%.
          </h3>
          <p>
            This is because you have had more responses than confirmed
            attendees. To fix this please update your sessions to have the
            correct number of people who attended
          </p>
        </>,
        2
      );
    }
  }, [over100?.length]);

  return (
    <div>
      <FilterResults
        role={role}
        handleFilteredData={handleFilteredData}
        defaultFilters={defaultFilters}
        hiddenFields={[...hiddenFields]}
        exportText={{
          active: 'Export Filtered Responses',
          inactive: 'Export All Responses'
        }}
        isOpened={true}
      />
      <T.H3 mt="6" mb="4">
        Modules delivered
      </T.H3>
      <Table columns={sessionsColumns} data={data.sessions} />
      <T.H3 mt="6" mb="4">
        Evaluation results
      </T.H3>
      <Table columns={surveysColumns} data={data.newSurveys} />
    </div>
  );
};

export default Reach;
