import { fields, createSchema, validate as _validate } from '..';

const { arrayOfString, organisationOther } = fields;

const trainerSchema = createSchema({
  localAuthorities: arrayOfString,
  organisationOther
});

const validate = data => {
  return _validate(trainerSchema, data);
};

export default validate;
