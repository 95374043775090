import React from 'react';
import moment from 'moment';
import { Icon } from 'antd';
import Spin from '../Spin';

import { REGISTER_URL } from '../../../constants/navigationRoutes';
import { colors, spacings } from '../../../theme';
import * as T from '../Typography';

import { modules } from './../../../constants';
import { Wrapper, StyledLink } from './List.style';

import { Row, Col } from '../Grid';

const courses = {
  C5: 'Connect 5',
  YOUNG_PEOPLE: 'Connect 5 Young People'
};

// NOTE: this component expects dataList to look something like this:

const SessionList = ({ dataList, linkText }) => {
  if (!dataList || !Array.isArray(dataList)) {
    return <Spin />;
  }
  let startTimes = [];
  let endTimes = [];
  let datesArr = [];
  const _dataList = dataList.filter(
    d => !d.modules.includes(modules.TRAINER_UPSKILL)
  );

  return (
    <Wrapper mw="800px">
      <Row
        style={{
          borderBottom:
            dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
        }}
      >
        {' '}
        <Col w={[1, 2, 2]}>
          <T.P weight={700} color="darkGray" mb="2">
            Modules
          </T.P>
        </Col>
        <Col w={[1.5, 2, 2]}>
          <T.P weight={700} color="darkGray" mb="2">
            Date(s)
          </T.P>
        </Col>
        <Col w={[1, 2, 2]}>
          <T.P weight={700} color="darkGray" mb="2">
            Time(s)
          </T.P>
        </Col>
        <Col w={[0, 2, 2]}>
          <T.P weight={700} color="darkGray" mb="2">
            Course
          </T.P>
        </Col>
        <Col w={[0, 2, 2]}>
          <T.P weight={700} color="darkGray" mb="2">
            Location
          </T.P>
        </Col>
        <Col w={[0.5, 2, 2]}></Col>
      </Row>

      {_dataList?.length > 0 ? (
        _dataList.map(dataItem => {
          startTimes = dataItem?.dates
            ?.map(e => e?.startTime)
            ?.filter(e => e !== null);
          endTimes = dataItem?.dates
            ?.map(e => e?.endTime)
            ?.filter(e => e !== null);
          datesArr = dataItem?.dates?.filter(e => e.sessionDate !== null);
          return (
            <Row
              key={dataItem.id}
              href={dataItem.link || undefined}
              to={
                dataItem.link || REGISTER_URL.replace(':id', dataItem.shortId)
              }
              target={dataItem.blank ? '_blank' : '_self'}
              style={{
                borderTop: `1px solid ${colors.midPrimary}`,
                padding: `${spacings[3]} 0`
              }}
            >
              <Col w={[1, 2, 2]}>
                <T.P color="gray">
                  {dataItem?.modules
                    .sort()
                    .join(', ')
                    .replace(/module /g, '')
                    .replace('Train the Trainer', 'Trainer') || 'N/A'}
                </T.P>
              </Col>
              {dataItem?.dates?.length && (
                <Col w={[1.5, 2, 2]}>
                  {datesArr.map((date, i) => {
                    if (i === datesArr.length - 1) {
                      return (
                        <T.P color="gray">
                          {moment(date?.sessionDate).format('DD/MM/YYYY')}
                        </T.P>
                      );
                    } else {
                      return (
                        <>
                          <T.P color="gray">
                            {moment(date?.sessionDate).format('DD/MM')}
                          </T.P>
                          <T.P color="secondary" weight="900">
                            &nbsp;,&nbsp;
                          </T.P>
                        </>
                      );
                    }
                  })}
                </Col>
              )}
              <Col w={[1, 2, 2]}>
                <T.P color="gray">
                  {!startTimes?.length
                    ? 'N/A'
                    : startTimes.length === 1 ||
                      (startTimes.every(val => val === startTimes[0]) &&
                        endTimes.every(val => val === endTimes[0]))
                    ? `${startTimes[0]} - ${endTimes[0]}`
                    : 'Times vary'}
                </T.P>
              </Col>

              <Col w={[0, 2, 2]}>
                <T.P color="gray">{courses[dataItem?.course]}</T.P>
              </Col>
              <Col w={[0, 2, 2]}>
                <T.P color="gray">
                  {dataItem?.remote
                    ? 'Online'
                    : dataItem?.address?.town ||
                      dataItem?.address?.postcode ||
                      'N/A'}
                </T.P>
              </Col>
              <Col w={[0.5, 2, 2]}>
                <StyledLink
                  as={dataItem.asLink ? 'a' : undefined}
                  href={dataItem.link || undefined}
                  to={
                    dataItem.link ||
                    REGISTER_URL.replace(':id', dataItem.shortId)
                  }
                  target={dataItem.blank ? '_blank' : '_self'}
                >
                  {dataItem.linkText || linkText || <Icon type="right" />}
                </StyledLink>
              </Col>
            </Row>
          );
        })
      ) : (
        <Row jc="center" mt="5">
          <Col w={[4, 12, 12]} jc="center">
            <T.P weight="700" color="gray" ta="center">
              No data found
            </T.P>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default SessionList;
