import Button from './../../common/Button';
import styled from 'styled-components';

const Div = styled.div`
  margin: 10px;
`;

// eslint-disable-next-line no-console
const handleClick = () => console.log('clicked');

const ButtonExample = () => (
  <>
    <Div>
      <p>Primary</p>
      <Button
        onClick={handleClick}
        type="primary"
        label="Log in"
        width="100px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="primary"
        label="Log in"
        disabled
        width="100px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="primary"
        label="Log in"
        loading
        width="100px"
        m={4}
      />
    </Div>
    <Div>
      <p>Secondary</p>
      <Button
        onClick={handleClick}
        type="secondary"
        label="Log in"
        width="100px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="secondary"
        label="Log in"
        disabled
        width="100px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="secondary"
        label="Log in"
        loading
        width="100px"
        m={4}
      />
    </Div>
    <Div>
      <p>Tertiary</p>
      <Button
        onClick={handleClick}
        type="tertiary"
        label="Log in"
        width="100px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="tertiary"
        label="Log in"
        disabled
        width="100px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="tertiary"
        label="Log in"
        loading
        width="100px"
        m={4}
      />
    </Div>
    <Div>
      <p>filter</p>
      <Button
        onClick={handleClick}
        type="filter"
        label="Filter results"
        width="155px"
        icon="chevron"
        color="primary"
        bgColor="midPrimary"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="filter"
        label="Clear filters"
        width="155px"
        icon="cross"
        color="secondary"
        bgColor="midSecondary"
        m={4}
      />
    </Div>
  </>
);
export default ButtonExample;
