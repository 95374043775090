import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { connect } from 'react-redux';
import { customSwal } from '../../../theme';

import SpinWrapper from './../../common/Spin';
import { message, Empty } from 'antd';

// ROUTES
import { ADD_TRAINER_URL } from '../../../constants/navigationRoutes';

// COMMON COMPONENTS
import TrainerList from '../../common/List/TrainerList';
import Button from '../../common/Button';
import Toggle from '../../common/Toggle';
import * as T from '../../common/Typography';
import Layout from '../../Layouts';

// STYLING
import { Wrapper, HeaderSection, HeaderNumber } from './TrainerListPage.style';

class TrainerListPage extends Component {
  state = {
    trainerCount: 0,
    localLeadCount: 0,
    trainers: null,
    localLeads: null,
    loaded: false,
    toggle: 'left',
    showDeleteModal: false
  };

  componentDidMount() {
    const { role } = this.props;
    if (role === 'admin') {
      this.adminFetchData();
    } else {
      this.localLeadFetchData();
    }
  }

  localLeadFetchData = () => {
    axios
      .get('/api/users/my-trainers')
      .then(response => {
        this.setState({
          trainerCount: response.data.trainerCount,
          trainers: response.data.trainerList,
          loaded: true
        });
      })
      .catch(err => {
        message.error(`Error!, ${err?.response?.data?.error}`);
      });
  };

  adminFetchData = () => {
    axios
      .get('/api/users/admin/trainers-and-leads')
      .then(response => {
        this.setState({
          trainerCount: response.data.trainerCount,
          trainers: response.data.trainerList,
          localLeadCount: response.data.localLeadCount,
          localLeads: response.data.localLeadList,
          loaded: true
        });
      })
      .catch(err => message.error(`Error!, ${err?.response?.data?.error}`));
  };

  clickToggle = direction => {
    this.setState({ toggle: direction });
  };

  deleteUser = async trainerId => {
    const { userId: localLeadId } = this.props;
    try {
      await axios.delete(`/api/local-lead/${localLeadId}/trainer/${trainerId}`);
      this.localLeadFetchData();
    } catch (err) {
      message.error(`Error!, Internal server Error`);
    }
  };

  showDeleteConfirm = trainerId => {
    Swal.fire({
      title: 'Are you sure you want to delete this trainer?',
      text: "You won't be able to revert this!",
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      ...customSwal
    }).then(willDelete => {
      if (willDelete.value) {
        this.deleteUser(trainerId);
      }
    });
  };

  render() {
    const {
      trainerCount,
      localLeadCount,
      trainers,
      localLeads,
      loaded,
      toggle
    } = this.state;

    const { role } = this.props;

    const count = toggle === 'left' ? trainerCount : localLeadCount;

    if (!loaded) {
      return <SpinWrapper />;
    }
    return (
      <Layout>
        <Wrapper>
          <T.H1 mb={5}>Trainers</T.H1>
          <HeaderSection>
            <T.P weight={700} color="gray">
              {toggle === 'left' ? 'Total Trainers:' : 'Total Local Leads:'}
            </T.P>
            <HeaderNumber>{count}</HeaderNumber>
            {role === 'admin' && (
              <Toggle
                leftText="trainers"
                rightText="local leads"
                selected={toggle}
                onClick={this.clickToggle}
              />
            )}
            {role === 'localLead' && (
              <Link to={ADD_TRAINER_URL}>
                <Button label="Add Trainer" type="tertiary" width="146px" />
              </Link>
            )}
          </HeaderSection>
          <div style={{ maxWidth: '650px', paddingTop: '30px' }}>
            {count ? (
              <TrainerList
                dataList={toggle === 'left' ? trainers : localLeads}
                role={role}
                deleteUser={this.showDeleteConfirm}
              />
            ) : (
              <Empty
                description={
                  toggle === 'left' ? 'No Trainers' : 'No Local Leads'
                }
              />
            )}
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return { userId: state.auth.userId };
};

export default connect(mapStateToProps)(TrainerListPage);
