const organisations = [
  {
    groupLabel: 'HOUSING ORGANISATIONS',
    options: [
      {
        label: 'Bron Afon',
        value: 'Housing organisation - Bron Afon'
      },
      {
        label: 'Llamau',
        value: 'Housing organisation - Llamau'
      },
      {
        label: 'Melin Homes',
        value: 'Housing organisation - Melin Homes'
      },
      {
        label: 'Pobl',
        value: 'Housing organisation - Pobl'
      },
      {
        label: 'United Welsh',
        value: 'Housing organisation - United Welsh'
      },
      {
        label: 'Monmouthshire Housing Association',
        value: 'Housing organisation - Monmouthshire Housing Association'
      },
      {
        label: 'Newport City Homes',
        value: 'Housing organisation - Newport City Homes'
      },
      {
        label: 'Caerphilly County Borough Council Housing',
        value:
          'Housing organisation - Caerphilly County Borough Council Housing'
      },
      {
        label: 'Tai Calon',
        value: 'Housing organisation - Tai Calon'
      },
      {
        label: 'Other, please specify',
        value: 'Housing organisation - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'OTHER STATUTORY ORGANISATIONS',
    options: [
      {
        label: 'Gwent Police',
        value: 'Statutory organisation - Gwent Police'
      },

      {
        label: 'South East Wales Fire and Rescue Service',
        value:
          'Statutory organisation - South East Wales Fire and Rescue Service'
      },
      {
        label: 'Department of Work and Pensions',
        value: 'Statutory organisation - Department of Work and Pensions'
      },
      {
        label: 'Other, please specify',
        value: 'Statutory organisation - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'Local Authority',
    options: [
      {
        label: 'Blaenau Gwent County Borough Council',
        value: 'Local Authority - Blaenau Gwent County Borough Council'
      },

      {
        label: 'Caerphilly County Borough Council',
        value: 'Local Authority - Caerphilly County Borough Council'
      },
      {
        label: 'Monmouthshire County Council',
        value: 'Local Authority - Monmouthshire County Council'
      },
      {
        label: 'Newport City Council',
        value: 'Local Authority - Newport City Council'
      },
      {
        label: 'Torfaen County Borough Council',
        value: 'Local Authority - Torfaen County Borough Council'
      },
      {
        label: 'Other, please specify',
        value: 'Local Authority - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'Health Services',
    options: [
      {
        label: 'Aneurin Bevan University Health Board',
        value: 'Health Services - Aneurin Bevan University Health Board'
      },

      {
        label: 'Primary care (non-Health Board), e.g. GP, pharmacy',
        value:
          'Health Services - Primary care (non-Health Board), e.g. GP, pharmacy'
      },
      {
        label: 'Other, please specify',
        value: 'Health Services - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Social Care Services',
    options: [
      {
        label: 'Nursing or residential home',
        value: 'Social Care Services - Nursing or residential home'
      },

      {
        label: 'Domiciliary Care',
        value: 'Social Care Services - Domiciliary Care'
      },
      {
        label: 'Other, please specify',
        value: 'Social Care Services - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Coleg Gwent',
    options: [
      {
        label: 'University of South Wales',
        value: 'Coleg Gwent - University of South Wales'
      },

      {
        label: 'School',
        value: 'Coleg Gwent - School'
      },
      {
        label: 'Other, please specify',
        value: 'Coleg Gwent - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Sports and Leisure, non-Council',
    options: [
      {
        label: 'Sport club',
        value: 'Sports and Leisure, non-Council - Sport club'
      },

      {
        label: 'Aneurin Leisure Trust',
        value: 'Sports and Leisure, non-Council - Aneurin Leisure Trust'
      },

      {
        label: 'Newport Live',
        value: 'Sports and Leisure, non-Council - Newport Live'
      },
      {
        label: 'Other, please specify',
        value: 'Sports and Leisure, non-Council - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'Drug and Alcohol Services',
    options: [
      {
        label: 'Gwent Drug and Alcohol Services',
        value: 'Drug and Alcohol Services - Gwent Drug and Alcohol Services'
      },

      {
        label: 'Barod',
        value: 'Drug and Alcohol Services - Barod'
      },
      {
        label: 'Other, please specify',
        value: 'Drug and Alcohol Services - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Third Sector Organisations',
    options: [
      {
        label: 'Diverse Cymru',
        value: 'Third Sector Organisations - Diverse Cymru'
      },

      {
        label: 'Carers Trust South East Wales',
        value: 'Third Sector Organisations - Carers Trust South East Wales'
      },
      {
        label: 'Mental Health organisation',
        value: 'Third Sector Organisations - Mental Health organisation'
      },
      {
        label: 'Other, please specify',
        value: 'Third Sector Organisations - Other, please specify'
      }
    ]
  },

  {
    groupLabel: 'Community Group',
    options: [
      {
        label: 'Please specify',
        value: 'Community Group - please specify'
      }
    ]
  },

  {
    groupLabel: 'Government Sponsored Body',
    options: [
      {
        label: 'Natural Resources Wales',
        value: 'Government Sponsored Body - Natural Resources Wales'
      },
      {
        label: 'Other, please specify',
        value: 'Government Sponsored Body - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'Private Sector',
    options: [
      {
        label: 'British Gas',
        value: 'Private Sector - British Gas'
      },
      {
        label: 'ONS',
        value: 'Private Sector - ONS'
      },
      {
        label: 'Admiral',
        value: 'Private Sector - Admiral'
      },
      {
        label: 'Other, please specify',
        value: 'Private Sector - Other, please specify'
      }
    ]
  },
  {
    groupLabel: 'None of the above, please specify',
    options: [
      {
        label: 'None of the above, please specify',
        value: 'None of the above, please specify'
      }
    ]
  }
];

export default organisations;
