import { useEffect, useReducer, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import { TextArea } from '../../common/Inputs';
import Button from '../../common/Button';
import InfoCard from '../../common/InfoCard';
import { getSessionDetails } from '../../../actions/sessionAction';
import { confirmRegistration } from '../../../actions/confirmRegistration';
import useSearchParams from './../../../hooks/useSearchParams';

import SuccessRegistration from './SuccessRegistration';
import { convertAddressObjToString } from './../../../helpers';
import * as S from './style';
import { loginUser } from '../../../actions/authAction';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { userRoles } from '../../../constants';
import * as R from '../../../constants/navigationRoutes';
import USER_TYPES from '../../../constants/userTypes';
import CircleDiagram from '../../common/CircleDiagram';

const initialState = {
  email: '',
  password: '',
  name: '',
  validationErrors: {},
  confirmAttendance: false,
  postSurveyLink: ''
};
const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const coursesNames = {
  C5: 'Connect 5',
  YOUNG_PEOPLE: 'Connect 5 Young People'
};
const SessionRegistration = ({
  getSessionDetails,
  confirmRegistrationAction,
  session,
  confirmSuccess,
  alreadyRegistered,
  participantLoginForSurvey,
  loginError,
  userId,
  role
}) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const [submitAttempt, setSubmitAttempt] = useState(0);
  const { password, message, validationErrors, loading } = state;

  const { id: shortId } = useParams();
  const history = useHistory();
  const searchParams = useSearchParams();

  searchParams.set('source', 'registration');
  searchParams.set('loginRole', userRoles.participant);
  searchParams.set('shortId', shortId);

  useEffect(() => {
    const sessionRegistration = true;
    getSessionDetails({ shortId, sessionRegistration, history });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trainersNames = session?.trainers
    ? session.trainers.map(trainer => trainer.name).join(' & ')
    : 'N/A';

  const modules = session?.modules
    ? session.modules.map(module => module).join(' & ')
    : 'N/A';

  const fullAddress = convertAddressObjToString(session.address);
  const course = session?.course;

  const validateForm = useCallback(() => {
    try {
      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  }, []);

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
  }, [password, message, submitAttempt, validateForm]);

  const confirmRegistration = async () => {
    setState({ loading: true });

    await confirmRegistrationAction({
      sessionId: session._id,
      message
    });

    setState({ loading: false });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitAttempt(n => n + 1);
    const isValid = validateForm();
    if (isValid) {
      confirmRegistration();
    }
  };

  const spacesAvailable = capacity => {
    const participants =
      session?.participantsEmails?.filter(
        ({ status }) => status === 'confirmed'
      )?.length || 0;
    const available = capacity > participants;
    return available;
  };

  if (confirmSuccess || session?.sessionParticipant?.status === 'confirmed') {
    return (
      <SuccessRegistration
        session={session}
        alreadyRegistered={alreadyRegistered}
        history={history}
      />
    );
  }

  return (
    <Layout>
      <Row>
        <Col w={[4, 12, 8]}>
          <S.Title>
            <T.H1>{coursesNames[course]} Session Registration</T.H1>
            <CircleDiagram
              totalScore={session.capacity}
              currentScore={
                session.capacity - session.participantsEmails?.length
              }
              progressScore={
                session.capacity - session.participantsEmails?.length
              }
            />
          </S.Title>
        </Col>
      </Row>
      {session.capacity && (
        <Row mt="40">
          <Col w={[4, 12, 8]}>
            <InfoCard
              text={
                role && role !== USER_TYPES.participant
                  ? `You can't register for a session with your ${role.replace(
                      'localLead',
                      'local lead'
                    )} account.`
                  : 'Your personal information is safe with us. All materials are confidential and in line with the Data Protection Act (2018). We only keep your data for as long as we need it which will be at least the duration of your course or accessing our services.'
              }
            />
          </Col>
        </Row>
      )}
      <Row mt={7}>
        <Col w={[4, 12, 8]}>
          <S.Wrapper>
            <S.Bold>Date(s) of Session:</S.Bold>{' '}
            {session?.dates?.length
              ? session?.dates
                  ?.filter(e => !!e.sessionDate)
                  .map(date => (
                    <T.P color="gray" weight="400">
                      {moment(date.sessionDate).format('DD/MM')} -{' '}
                      {date.startTime
                        ? `${date.startTime || 'N/A'} to ${date.endTime ||
                            'N/A'}`
                        : 'N/A       \n'}
                    </T.P>
                  ))
              : 'N/A'}
            <T.P mb="4" color="gray" mt="4">
              <S.Bold>Location:</S.Bold>{' '}
              {session.remote ? 'Online' : fullAddress}
            </T.P>
            <T.P mb="4" color="gray" transform="capitalize">
              <S.Bold>Trainers:</S.Bold> {trainersNames}
            </T.P>
            <T.P mb="4" color="gray" transform="capitalize">
              <S.Bold>Course:</S.Bold> {coursesNames[course]}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Modules:</S.Bold> {modules || 'N/A'}
            </T.P>
            <T.P color="gray">
              <S.Bold>Contact email:</S.Bold>{' '}
              {session.contactEmail ? (
                <T.Link
                  external
                  href={`mailto:${session.contactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  {session.contactEmail}
                </T.Link>
              ) : (
                ' N/A'
              )}
            </T.P>
          </S.Wrapper>
        </Col>
      </Row>
      <Row mt="7">
        <Col w={[4, 12, 8]}>
          <T.P mb={2} weight={900} size="22px">
            IMPORTANT MESSAGE
          </T.P>
          <T.P weight={400}>
            This training is funded at no cost to you. However, funding criteria
            requires us to demonstrate the impact of the training. We therefore
            ask all participants for five minutes of their time, either prior to
            or during the training, to complete evaluation forms, so that we can
            comply with the funding terms.
          </T.P>
          <T.P weight={400} mt="1">
            Thank you for your support.
          </T.P>
        </Col>
      </Row>

      {spacesAvailable(session.capacity) ? (
        <>
          {!userId ? (
            <>
              <Row mt="7">
                <Col w={[4, 12, 8]}>
                  <T.P mb={4} weight={400} color="gray">
                    To register you must have a free Gwent Connect 5 training
                    account. This account will let you attend Gwent Connect 5
                    courses, receive your certificates and track your progress.
                  </T.P>
                </Col>
              </Row>
              <Row mt="4">
                <Col w={[4, 6, 4]}>
                  <Button
                    to={{
                      pathname: R.LOGIN_URL,
                      search: searchParams.toString()
                    }}
                    type="primary"
                    label="Log into my account"
                    loading={loading}
                  />
                </Col>
              </Row>
              <Row mt="3">
                <Col w={[4, 6, 4]}>
                  <Button
                    to={{
                      pathname: R.PARTICIPANT_SIGN_UP_URL,
                      search: searchParams.toString()
                    }}
                    type="secondary"
                    label="Create a new account"
                    loading={loading}
                  />
                </Col>
              </Row>
            </>
          ) : (
            role &&
            role === USER_TYPES.participant && (
              <>
                <Row mt="7">
                  <Col w={[4, 12, 6]}>
                    <TextArea
                      value={message}
                      handleChange={value => setState({ message: value })}
                      label="Do you have any special requirements or anything else we should know ahead of the workshop?"
                      placeholder="Requirements/message"
                      mb={3}
                      error={validationErrors.message}
                      labelWeight="700"
                      rows="4"
                    />
                  </Col>
                </Row>
                <Row mt="6">
                  <Col w={[4, 6, 4]}>
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      label="Register"
                      loading={loading}
                    />
                  </Col>
                </Row>
              </>
            )
          )}
        </>
      ) : (
        <>
          <Row mt="5">
            <Col w={[4, 12, 8]}>
              <T.P weight={700} mt={5}>
                Sorry, this session is now full. Please register for a different
                session. For more information, please use the contact email
                above.
              </T.P>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  userId: state.auth.userId,
  auth: state.auth,
  userEmail: state.auth.email,
  session: state.session,
  confirmRegistration: state.confirmRegistration,
  confirmSuccess: state.confirmRegistration.confirmSuccess,
  alreadyRegistered: state.confirmRegistration.alreadyRegistered,
  httpError: state.auth.error,
  role: state.auth.role
});

const mapActionsToProps = {
  getSessionDetails,
  confirmRegistrationAction: confirmRegistration,
  loginUser,
  fetchParticipantSessions
};

export default connect(mapStateToProps, mapActionsToProps)(SessionRegistration);
