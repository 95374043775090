import { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { customSwal } from '../../../theme';
import { message } from 'antd';

import * as T from '../../common/Typography';

import {
  updateMyAccount,
  checkAuth as checkAuthAction
} from '../../../actions/authAction';

import Layout from '../../Layouts';
import Button from '../../common/Button';

import { BasicInput, Dropdown } from '../../common/Inputs';

import { updateMyAccount as validate } from '../../../validation/schemas';
import { deleteAccountAction } from '../../../actions/deleteAccountAction';
import {
  userRoles,
  localAuthoritiesBasic as localAuthoritiesOptions,
  organisations as organisationsOptions
} from '../../../constants';

import { Form } from './MyAccount.style';

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const isOrgExistInOptions = (org, category) => {
  if (!org || !category) {
    return {};
  }
  let isExist = false;
  let selectedValue = org;
  organisationsOptions.forEach(({ options }) => {
    options.forEach(({ label, value }) => {
      if (value === org) {
        isExist = true;
      }
    });
  });

  if (!isExist) {
    const selectedCategory = organisationsOptions.find(({ groupLabel }) => {
      return groupLabel === category;
    });
    selectedValue = selectedCategory.options.find(({ label }) =>
      label.includes('specify')
    )?.value;
  }

  return { isExist, selectedValue };
};

const initialState = ({
  userEmail,
  userName,
  userOrganisation = {},
  userLocalAuthorities = []
}) => {
  const { isExist, selectedValue } = isOrgExistInOptions(
    userOrganisation.value,
    userOrganisation.category
  );
  return {
    email: userEmail,
    name: userName,
    localAuthorities: userLocalAuthorities,
    organisation: {
      value: selectedValue,
      category: userOrganisation.category
    },
    organisationOther: (!isExist && userOrganisation.value) || '',
    submitAttempt: false,
    validationErrors: {},
    showOrgOther: !isExist,
    updated: false,
    getLoading: true
  };
};

const cleanEmail = email => email.toLowerCase().trim();

const MyAccount = ({
  userEmail,
  userName,
  loading,
  updateMyAccount,
  httpError,
  deleteAccountAction: deleteAccountActionCreator,
  deleteAccountLoading,
  userId,
  userOrganisation = {},
  userLocalAuthorities = [],
  role,
  checkAuthAction
}) => {
  const [state, setState] = useReducer(
    stateReducer,
    initialState({
      userEmail,
      userName,
      userOrganisation,
      userLocalAuthorities
    })
  );
  const {
    email,
    name,
    localAuthorities,
    organisation,
    organisationOther,
    submitAttempt,
    validationErrors,
    showOrgOther,
    getLoading
  } = state;

  const history = useHistory();
  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        role,
        name,
        organisation,
        localAuthorities,
        showOrgOther,
        organisationOther
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleSubmit = e => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleUpdate();
    }
  };

  const handleUpdate = async e => {
    setState({ loading: true });

    await updateMyAccount({
      email: cleanEmail(email),
      name,
      organisation: {
        ...organisation,
        value: showOrgOther ? organisationOther : organisation.value
      },
      localAuthorities
    });
    message.success(
      'Done!, Your account details have been updated successfully'
    );
  };

  const deleteAccount = () => {
    Swal.fire({
      title: 'Are you sure that you want to delete your account?',
      text:
        'By doing this you will not be able to log into any of your Connect 5 accounts. This cannot be undone.',
      type: 'info',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      ...customSwal
    }).then(willDelete => {
      if (willDelete.value) {
        return deleteAccountActionCreator(userId, history);
      }
    });
  };

  useEffect(() => {
    if (!organisation.value) return;
    if (organisation.value === userOrganisation.value) {
      return;
    }
    setState({ showOrgOther: organisation.value.includes('specify') });
  }, [organisation.value, userOrganisation.value]);

  useEffect(() => {
    const getInfo = async () => {
      await checkAuthAction();
      setState({ getLoading: true });
    };
    getInfo();
  }, [checkAuthAction]);

  useEffect(() => {
    setState(
      initialState({
        userEmail,
        userName,
        userOrganisation,
        userLocalAuthorities
      })
    );
  }, [userEmail, userName, userOrganisation, userLocalAuthorities]);
  return (
    <Layout>
      <T.H1 mb="7">My Account Details</T.H1>
      <Form>
        <BasicInput
          value={name}
          handleChange={value => setState({ name: value })}
          label="Name"
          placeholder="Type your name here"
          mb={6}
          error={validationErrors.name}
        />

        <BasicInput
          value={email}
          handleChange={value => setState({ email: value })}
          label="Email"
          placeholder="Type your email here"
          mb={6}
          error={
            validationErrors.email ||
            (httpError?.response?.status === 409 &&
              httpError?.response?.data?.error)
          }
        />
        {role !== userRoles.participant && (
          <>
            <Dropdown
              selected={organisation.value}
              m={{ mb: 6 }}
              placeholder="Where do you work"
              label="Where do you work"
              groupedOptions
              options={organisationsOptions}
              handleChange={(value, option, b) =>
                setState({
                  organisation: {
                    value,
                    category: option?.key?.split('_')[0]
                  }
                })
              }
            />
            {showOrgOther && (
              <BasicInput
                value={organisationOther}
                autoFocus
                handleChange={value => setState({ organisationOther: value })}
                placeholder="Type organisation here..."
                mb={6}
                error={
                  validationErrors.organisation ||
                  validationErrors.organisationOther
                }
              />
            )}
            <Dropdown
              m={{ mb: 6 }}
              label="Where you typically deliver Gwent Connect 5 training"
              placeholder="Select as many as you like or add new"
              options={localAuthoritiesOptions.map(i => ({
                label: i,
                value: i
              }))}
              addNew
              multi
              selected={localAuthorities}
              handleChange={values => setState({ localAuthorities: values })}
              error={validationErrors.localAuthorities}
            />
          </>
        )}

        <Button
          onClick={handleSubmit}
          type="primary"
          label="Save changes"
          loading={loading}
          mt={5}
        />
        <T.Link onClick={deleteAccount || getLoading} mt={8} color="gray">
          Delete account
        </T.Link>
      </Form>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  userId: state.auth.userId,
  userOrganisation: state.auth?.organisation,
  userLocalAuthorities: state.auth.localAuthorities,
  userEmail: state.auth.email,
  userName: state.auth.name,
  role: state.auth.role,
  loading: state.loading.updateMyAccount
});

export default connect(mapStateToProps, {
  updateMyAccount,
  deleteAccountAction,
  checkAuthAction
})(MyAccount);
