import React from 'react';
import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

const IconWithTextButton = ({
  label,
  onClick,
  disabled,
  icon,
  color,
  ...props
}) => {
  return (
    <>
      <S.StyledButton
        aria-label={label}
        disabled={disabled}
        onClick={onClick}
        type="button"
        {...props}
      >
        {' '}
        <Icon icon={icon} mr="2" color={color} />
        <T.P weight="600" color={color}>
          {label}
        </T.P>
      </S.StyledButton>
    </>
  );
};

export default IconWithTextButton;
