import styled, { css } from 'styled-components';
import { colors, borders } from '../../../theme';
import setMargins from '../../../helpers/setMargin';

const sharedStyles = css`
  ${setMargins}
  position: relative;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: 0;
  color: ${colors.gray};
  cursor: ${({ loading }) => (loading ? 'not-allowed' : 'pointer')};
  font-weight: 700;
  font-size: 1rem;
  transition: all linear 0.1s;
  width: 100%;
  min-height: 54px;

  &:active::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: ${colors.transGray};
    box-shadow: none;
  }
`;

const disabledStyles = css`
  opacity: 0.5;
  /* background-color: ${colors.lightPrimary}; */
  color: ${colors.gray};
  cursor: not-allowed;

  :hover {
    opacity: 0.5;
    /* background-color: ${colors.lightPrimary}; */
    color: ${colors.gray};
  }
`;

export const squareStyles = css`
  height: ${props => props.height || '2rem'};
  width: ${props => props.width || '100%'};
`;

export const roundStyles = css`
  height: ${props => props.height || '2.5rem'};
  width: ${props => props.width || '100%'};
  border-radius: 6px;

  &::after {
    border-radius: 6px;
  }
`;

export const primaryStyles = css`
  background-color: ${colors.secondary};
  color: ${colors.white};

  :hover {
    background-color: ${({ loading }) => !loading && colors.lightSecondary};
    color: ${({ loading }) => !loading && colors.secondary};
  }
`;

export const secondaryStyles = css`
  background-color: ${colors.darkGray};
  color: ${colors.white};

  :hover {
    background-color: ${({ loading }) => !loading && colors.midPrimary};
    color: ${({ loading }) => !loading && colors.darkGray};
  }
`;

export const tertiaryStyles = css`
  background-color: ${colors.primary};
  color: ${colors.white};

  :hover {
    background-color: ${({ loading }) => !loading && colors.midPrimary};
    color: ${({ loading }) => !loading && colors.primary};
  }
`;

export const outlineStyles = css`
  background-color: ${colors.white};
  color: ${colors.gray};
  border-radius: 6px;
  border: ${borders.button};

  &::after {
    border-radius: 6px;
  }
`;

export const filterStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => colors[bgColor]};
  color: ${({ color }) => colors[color]};
  border: 1px solid ${({ color }) => colors[color]};
  :hover {
    background-color: ${({ bgColor }) => colors[bgColor]};
    color: ${({ loading, color }) => !loading && colors[color]};
  }
  div {
    transition: transform 0.2s ease-out;
    transform: ${({ isOpen, color }) =>
      isOpen && color === 'primary' ? 'rotate(-180deg)' : 'rotate(0)'};
  }
`;

export const StyledButton = styled.button`
  ${sharedStyles};
  ${props => props.type === 'primary' && roundStyles}
  ${props => props.type === 'primary' && primaryStyles}
  ${props => props.type === 'secondary' && roundStyles}
  ${props => props.type === 'secondary' && secondaryStyles}
  ${props => props.type === 'tertiary' && roundStyles}
  ${props => props.type === 'tertiary' && tertiaryStyles}
  ${props => props.type === 'outline' && roundStyles}
  ${props => props.type === 'outline' && outlineStyles}
  ${props => props.type === 'filter' && filterStyles}
  ${props => props.type === 'filter' && roundStyles}

  ${props => props.disabled && disabledStyles}
`;
