import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../Layouts';
import moment from 'moment';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';
import * as S from './style';
import { SURVEY_URL } from '../../../constants/navigationRoutes';
import {
  surveyTypesNew,
  modules as modulesData,
  courses
} from '../../../constants/index';

const SuccessRegistration = ({ session, alreadyRegistered }) => {
  const {
    trainers,
    meetingLink,
    contactEmail,
    extraInfo,
    remote,
    address,
    meetingPlatform,
    modules,
    shortId,
    course
  } = session;

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const trainersNames = trainers
    ? trainers.map(trainer => trainer.name).join(' & ')
    : 'N/A';

  const handleClick = () => {
    let type = modules.includes(modulesData.MODULE_1)
      ? surveyTypesNew.PRE_COURSE
      : surveyTypesNew.POST;

    history.push(SURVEY_URL.replace(':type', type).replace(':id', shortId));
  };

  const createAddress = () => {
    if (address) {
      const { addressLine1, addressLine2, postcode, town } = address;
      const fullAddress = [addressLine1, addressLine2, town, postcode]
        .filter(item => !!item)
        .join(', ');

      return fullAddress;
    }
    return 'N/A';
  };
  const courseName =
    course === courses.C5 ? 'Connect 5' : 'Connect 5 Young People';
  return (
    <Layout>
      <T.H1>
        {alreadyRegistered ? 'Already' : 'Successfully'} registered
        {alreadyRegistered && '!'}
      </T.H1>
      <Row mt="7">
        <Col w={[4, 8, 8]}>
          <T.P color="gray">
            Thank you for registering for this session. Please find all the
            relevant information for your session below.
          </T.P>
        </Col>
      </Row>
      {modules?.includes(modulesData.MODULE_1) ? (
        <>
          <Row mt="5">
            <Col w={[4, 8, 8]}>
              <T.P mb="3" color="gray">
                Before you attend we require all participants to answer in a
                short 2-minute pre-course evaluation. This will help us provide
                you with insight on how the session has helped you. Once
                completed you will then be able to access all the materials you
                need for the session.
              </T.P>
            </Col>
          </Row>
          <Row mt="5">
            <Col w={[4, 5, 5]}>
              <Button
                onClick={handleClick}
                type="primary"
                label="Complete pre-course evaluation now"
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row mt="7">
          <Col w={[4, 8, 8]}>
            <T.P weight="700" mb="3" color="darkGray">
              What now?
            </T.P>
            <T.P color="gray">
              You have course materials, including a form to complete, ahead of
              your session.
            </T.P>
          </Col>
        </Row>
      )}
      <Row mt={8}>
        <Col w={[4, 8, 8]}>
          <S.Wrapper>
            <S.Bold>Date(s) of Session:</S.Bold>{' '}
            {session?.dates?.length
              ? session?.dates
                  ?.filter(e => !!e.sessionDate)
                  .map(date => (
                    <T.P color="gray" weight="400">
                      {moment(date.sessionDate).format('DD/MM')} -{' '}
                      {date.startTime
                        ? `${date.startTime || 'N/A'} to ${date.endTime ||
                            'N/A'}`
                        : 'N/A       \n'}
                    </T.P>
                  ))
              : 'N/A'}
            <T.P mb="4" color="gray">
              <S.Bold>Time of Session:</S.Bold> {session?.startTime || 'TBC'}{' '}
              {session?.endTime && `to ${session?.endTime}`}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Module(s):</S.Bold>{' '}
              {modules
                ? modules.join(' & ').replace('Train the trainer', 'Trainer')
                : 'N/A'}
            </T.P>
            <T.P mb="4" color="gray">
              <S.Bold>Location:</S.Bold>{' '}
              {remote ? meetingPlatform : createAddress()}
            </T.P>
            <T.P mb="4" color="gray" transform="capitalize">
              <S.Bold>Trainers:</S.Bold> {trainersNames}
            </T.P>{' '}
            {remote && (
              <T.P mb="4" color="gray" style={{ wordBreak: 'break-all' }}>
                <S.Bold style={{ display: 'inline' }}>Meeting link:</S.Bold>
                {meetingLink ? (
                  <T.Link external href={meetingLink} color="primary">
                    {' '}
                    {meetingLink}
                  </T.Link>
                ) : (
                  ' N/A'
                )}
              </T.P>
            )}
            <T.P mb="4" color="gray">
              <S.Bold>Email: </S.Bold>
              {contactEmail ? (
                <T.Link
                  external
                  href={`mailto:${contactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  {contactEmail}
                </T.Link>
              ) : (
                ' N/A'
              )}
            </T.P>
            <T.P color="gray">
              <S.Bold style={{ display: 'block' }}>
                {' '}
                Any other joining information:
              </S.Bold>
              {extraInfo || 'N/A'}
            </T.P>
          </S.Wrapper>
        </Col>
      </Row>
    </Layout>
  );
};

export default SuccessRegistration;
