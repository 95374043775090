import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import theme from '../../../theme';

export const Modal = styled(AntModal)`
  .ant-modal-footer,
  .ant-modal-header,
  .ant-modal-close {
    display: none;
  }

  max-width: 520px;

  .ant-modal-content {
    position: relative;
    border: none;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.lightPrimary};
  }

  .ant-modal-body {
    z-index: 999;
    display: flex;
    flex-direction: column;
  }

  ${theme.breakpointsMax.mobileL} {
    max-width: 350px;
  }

  ${theme.breakpointsMax.mobileS} {
    max-width: 300px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
