import React from 'react';

import EditEmail from '../../../common/EditEmail';
import sessionsDateFormat, {
  sessionsTimeFormat
} from '../../../../helpers/formatSessionDate';
const SendSurveyLink = ({
  sessionDetails,
  handleAddEmailsClick,
  name,
  isSchedule,
  surveyType,
  drawerKey,
  onClose
}) => {
  return (
    <EditEmail
      participantsEmails={sessionDetails.participantsEmails}
      type="surveyLink"
      trainer={name}
      sessionDate={sessionsDateFormat(sessionDetails.dates)}
      sessionType={sessionDetails.type}
      address={sessionDetails.address}
      trainers={sessionDetails.trainers}
      startTime={sessionsTimeFormat(sessionDetails.dates)}
      endTime={sessionDetails.endTime}
      shortId={sessionDetails.shortId}
      sessionId={sessionDetails._id}
      course={sessionDetails.course}
      handleAddEmailsClick={handleAddEmailsClick}
      isSchedule={isSchedule}
      surveyType={surveyType}
      drawerKey={drawerKey}
      backCallback={onClose}
      modules={sessionDetails.modules}
    />
  );
};

export default SendSurveyLink;
