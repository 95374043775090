import moment from 'moment';

const sessionsDateFormat = dates => {
  return dates
    ?.filter(e => e.sessionDate !== null)
    ?.map(e => moment(e.sessionDate).format('YYYY-MM-DD'))
    ?.join(', ');
};

export const sessionsTimeFormat = dates => {
  return dates
    ?.filter(e => e.startTime !== null || e.endTime !== null)
    ?.map(e => `${e.startTime || 'N/A'} to ${e.endTime || 'N/A'}`)
    ?.join(', ');
};

export default sessionsDateFormat;
