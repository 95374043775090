import React from 'react';
import Layout from '../../Layouts';
import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Icon from '../../common/Icon';
import { externalLinks } from '../../../constants';
import { IconWrapper } from './TrainerAdvice.Style';

const TrainerAdvice = () => {
  return (
    <Layout>
      <Row mb="4">
        <Col w={[4, 8, 8]} display="block">
          <T.H1 mb="6" width="100%">
            Trainer Advice
          </T.H1>
        </Col>
      </Row>
      <Row mb="4">
        <Col w={[4, 8, 8]} display="block">
          <T.P weight={700} mr="1" color="black">
            Using this tool
          </T.P>
        </Col>
      </Row>

      <Row mb="6">
        <Col w={[4, 8, 8]}>
          <T.P mr="1">
            On this platform you will be able to set up training sessions,
            invite participants, collect feedback and see your results. To make
            the most of it and use the tool properly, watch these videos that
            will explain how to use this tool.
          </T.P>
        </Col>
      </Row>
      <Row mb="6">
        <Col w={[4, 8, 8]}>
          <T.Link
            external
            underline={false}
            to={externalLinks.EXPLAIN_TOOL_VIDEO}
          >
            <IconWrapper>
              <Icon icon="open" mr="4" color="primary" /> View videos
            </IconWrapper>
          </T.Link>
        </Col>
      </Row>

      <Row mb="4">
        <Col w={[4, 8, 8]} display="block">
          <T.P weight={700} mr="1" color="black">
            Contact
          </T.P>
        </Col>
      </Row>

      <Row mb="6">
        <Col w={[4, 8, 8]}>
          <T.P mr="1" color="darkGray">
            Having a problem using the tool?{' '}
            <T.Link
              href={`${externalLinks.FEEDBACK_FORM}`}
              external
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              underline={false}
            >
              Fill in this form{' '}
            </T.Link>
            and we will look into it for you as soon as possible.
          </T.P>
          <T.P mt="5" mr="1" color="darkGray">
            Need to talk to someone? Contact us on{' '}
            <T.Link
              href={`mailto:${externalLinks.WALES_NHS_EMAIL}`}
              external
              target="_blank"
              rel="noopener"
              color="primary"
              underline={false}
            >
              sarah.hughes24@wales.nhs.uk
            </T.Link>{' '}
          </T.P>
        </Col>
      </Row>
    </Layout>
  );
};

export default TrainerAdvice;
