import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import Icon from '../Icon';
import * as T from '../Typography';

export const AntdBtn = styled.button`
  background: none;
  border: none;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  max-width: 420px;
`;

const TextContent = styled.div`
  max-width: 95%;
  padding-left: ${({ theme }) => theme.spacings[3]};
  padding-bottom: ${({ theme }) => theme.spacings[2]};
`;

const InfoPopUp = ({
  title,
  text,
  customContent,
  w,
  h,
  icon,
  placement,
  color,
  ...props
}) => {
  const popOverContent = (
    <Wrapper>
      <Icon icon="info" width="24px" height="24px" color="white" mt="2" />
      <TextContent>
        {title && (
          <T.H3 color="white" mb="1">
            {title}
          </T.H3>
        )}
        {text && <T.P color="white">{text}</T.P>}
      </TextContent>
    </Wrapper>
  );

  return (
    <>
      <Popover content={customContent || popOverContent} placement={placement}>
        <AntdBtn type="button">
          <Icon
            icon={icon || 'question'}
            width={w || '25px'}
            height={h || '25px'}
            color={color || 'secondary'}
            {...props}
          />
        </AntdBtn>
      </Popover>
    </>
  );
};

export default InfoPopUp;
