import axios from 'axios';
import { message as AntdMessage } from 'antd';

import {
  CONFIRM_REGISTRATION_SUCCESS,
  CONFIRM_REGISTRATION_ERROR,
  LOADING_TRUE,
  LOADING_FALSE
} from '../constants/actionTypes';

export const confirmRegistration = ({
  email,
  sessionId,
  message
}) => dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'confirmRegistrationLoading'
  });
  axios
    .patch(`/api/sessions/${sessionId}/confirm-email`, {
      email,
      message,
      status: 'confirmed'
    })
    .then(({ data }) => {
      dispatch({
        payload: {
          confirmedEmail: data.confirmedEmail,
          alreadyRegistered: data.alreadyRegistered
        },
        type: CONFIRM_REGISTRATION_SUCCESS
      });
      dispatch({
        type: LOADING_FALSE,
        payload: 'confirmRegistrationLoading'
      });
    })
    .catch(err => {
      dispatch({
        type: CONFIRM_REGISTRATION_ERROR
      });
      dispatch({
        type: LOADING_FALSE,
        payload: 'confirmRegistrationLoading'
      });
      AntdMessage.error(`Error!, ${err?.response?.data?.error}`);
    });
};
