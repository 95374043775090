import axios from 'axios';
import swal from 'sweetalert2';
import { customSwal } from '../theme';
import { checkAuth } from './authAction';
import {
  FETCH_SURVEY_DATA,
  FETCH_SURVEY_DATA_FAIL,
  SURVEY_SUBMISSION_SUCCESS,
  SURVEY_SUBMISSION_FAIL,
  GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS,
  GET_PARTICIPANT_SURVEY_RESPONSES_FAIL,
  RESET_SURVEY
} from '../constants/actionTypes';

import { returnErrors } from './errorAction';

export const fetchSurveyData = ({ id, type }) => dispatch => {
  axios
    .get(`/api/survey/${id}/${type}`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_SURVEY_DATA,
        payload: data
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_SURVEY_DATA_FAIL,
        payload: err?.response?.data?.error
      });
    });
};

export const submitSurvey = ({
  formSubmission,
  sessionId,
  history,
  redirectToAfterSuccess
}) => dispatch => {
  axios
    .post(`/api/survey/submit/`, formSubmission)
    .then(({ data }) => {
      dispatch({
        type: SURVEY_SUBMISSION_SUCCESS,
        payload: data
      });
      dispatch(checkAuth());
      return swal
        .fire({
          title: 'Done!',
          text: 'Thanks for submitting your feedback!',
          ...customSwal
        })
        .then(() => {
          history.push({
            pathname: redirectToAfterSuccess,
            state: { sessionId, course: data.course }
          });
        })
        .catch(err => {
          dispatch(
            returnErrors(
              err?.response?.data,
              err?.response?.status,
              'error submitting survey'
            )
          );
        });
    })
    .catch(err => {
      dispatch({
        type: SURVEY_SUBMISSION_FAIL,
        payload: err?.response?.data || { httpError: err?.message }
      });
      return swal.fire({
        title:
          err?.response?.data?.error ||
          'There was an error submitting your survey. Please check that you have answered all required questions and entered your correct PIN',
        ...customSwal
      });
    });
};

export const getParticipantByEmail = ({
  sessionId,
  surveyType,
  userTimeZone
}) => async dispatch => {
  try {
    const { data } = await axios.get(`/api/participant`, {
      params: { sessionId, surveyType, userTimeZone }
    });
    dispatch({ type: GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_PARTICIPANT_SURVEY_RESPONSES_FAIL,
      payload: err.response.data
    });
  }
};

export const resetSurvey = () => async dispatch => {
  dispatch({ type: RESET_SURVEY });
};
