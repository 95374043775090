import { Icon } from 'antd';

import CopyLinkComponent from '../../../common/CopyLink';
import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';

import { REGISTER_URL } from '../../../../constants/navigationRoutes';

import {
  SessionTopDetailsWrapper,
  Row as StyledRow
} from '../SessionDetails.Style';
const InviteAndPromote = props => {
  const { sessionDetails, handleDrawerOpen } = props;
  const { shortId } = sessionDetails;

  return (
    <SessionTopDetailsWrapper>
      <Row mb="4" mt="4">
        <Col w={[4, 8, 10]}>
          <T.P>
            Here you can invite people to your session. Either copy the
            registration link to share it manually or send out and schedule
            automatic emails to be sent on your behalf.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.P weight={700} mt="4" mb="2">
            Registration Link
          </T.P>
        </Col>
      </Row>
      <CopyLinkComponent
        link={`${
          process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
        }${window.location.host}${REGISTER_URL.replace(':id', shortId)}`}
        mb="6"
      />

      <Row mb="4">
        <StyledRow onClick={handleDrawerOpen} data-key="send-invitation">
          <T.P weight={700}>Send Email Invitation</T.P>
          <Icon type="right" />
        </StyledRow>
      </Row>
      <Row mb="4">
        <StyledRow onClick={handleDrawerOpen} data-key="view-invitees">
          <T.P weight={700}>Manage invitees</T.P>
          <Icon type="right" />
        </StyledRow>
      </Row>
      <Row mb="4">
        <StyledRow onClick={handleDrawerOpen} data-key="view-emails">
          <T.P weight={700}>View emails you have sent</T.P>
          <Icon type="right" />
        </StyledRow>
      </Row>
    </SessionTopDetailsWrapper>
  );
};
export default InviteAndPromote;
